.absolute {
    left: 0;
    position: absolute;
    top: 0;
}

.full-dimensions {
    height: 100%;
    width: 100%;
}

#main-wrapper {
    #main {
        &.brand-mag {
            .absolute;
            .full-dimensions;
            padding-bottom: 0;

            #footer {
                display: none;
            }
        }
    }
}

#brand-mag {
    .full-dimensions;
    background-color: @black;
    position: relative;

    #magazine {
        .full-dimensions; 
        overflow: hidden;
        position: relative;

        .loading-spinner {
            .absolute;
        }
    }

    #pages {
        display: none;
        visibility: hidden;

        @media only screen and (min-width: @screen-sm) {
            .absolute;
            .full-dimensions;
            display: block;
            opacity: 1;
            position: relative; 
            visibility: visible;
        }
    }

    .mag-page {
        .absolute;
        .full-dimensions;
        background-color: @black;

        .row { 
            float: left; 
            height: 50%; 
            margin: 0;
            overflow: hidden; 
            position: relative; 
            width: 100%; 

            &.row-50 { 
                height: 50%;
            }
            
            &.row-100 { 
                height: 100%;
            }

            @media only screen and (min-width: @screen-sm) {
                &:hover {
                    .mag-image {
                        opacity: 1;
                    }
                }
            }
        }

        .mag-image { 
            .absolute;
            .full-dimensions;
            background-position: center; 
            background-size: cover;
            opacity: .8;
            position: relative;

            &:after {
                .content;
                .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
                .transition(all, 0.3s);
            }
        }

        .mag-content {
            bottom: 50px;
            color: @light-gray;
            font-family: @font-family-sans-serif;
            padding: 15px;
            position: absolute;
             
            @media only screen and (min-width: @screen-sm) {
                padding: 0 50px;
            }

            h2 {
                color: @white;
                font-family: @font-family-sans-serif;
                font-size: @font-size-h3;
                font-weight: @lighter;
                line-height: 2.2rem;

                @media only screen and (min-width: @screen-sm) {
                    font-size: @font-size-h2;
                }

                @media only screen and (min-width: @screen-md) {
                    font-size: @font-size-h1;
                    line-height: 3.44rem;
                }
            }

            p {
                font-family: @font-family-serif;
                margin: 0 0 5px;

                @media only screen and (min-width: @screen-sm) {
                    font-size: @font-size-text;
                }
            }

            .btn {
                color: @middle-gray;

                .text {
                    display: none;

                    @media only screen and (min-width: @screen-sm) {
                        display: inline;
                    }
                }

                &.btn-bordered {
                    color: @pink;
                    margin-top: 32px;
                    
                    @media only screen and (min-width: @screen-sm) {
                        margin-top: 48px;
                    }

                    &:hover {
                        .text {
                            color: @pink;
                        }
                    }

                    .text {
                        color: @white;
                        opacity: 1;
                        letter-spacing: 3px;
                    }
                }

                &.btn-share {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    padding-left: 0;
                    position: relative;
                    z-index: 4;

                    &:hover {
                        color: @white;
                    }

                    .social-share {
                        background-color: @white;
                        left: -45px;
                        position: absolute;
                        top: -70px;
                    }
                }

                &.btn-preferred {
                    &:hover {
                        color: @pink;
                    }

                    &.saved {
                        span {
                            color: @pink;
                        }
                    }
                }
            }

            &.intro { 
                .full-dimensions;
                bottom: auto;
                color: @light-gray;
                display: table;
                padding: 10px; 
                top: 0px;

                @media only screen and (min-width: @screen-sm) {
                    padding: 30px; 
                }

                .intro-wrap {
                    padding: 5% 0;

                    h2, h3 {
                        color: @white;
                        font-family: @font-family-cursive;
                        margin: 0;
                    }

                    h3 {
                        font-size: @font-size-large;
                        font-weight: @lighter;
                        position: relative;
                        text-transform: uppercase;
                    }

                    p {
                        color: @dark-gray;
                        font-family: @font-family-serif;
                        font-size: @font-size-medium;
                        line-height: 1.5rem;
                        margin: 20px 0 0;
                        width: 100%;

                        @media only screen and (min-width: @screen-sm) {
                            font-size: 2.5rem; //  ~40px
                            line-height: 2.7rem;
                        }

                        @media only screen and (min-width: @screen-md) {
                            font-size: 3rem; //  ~48px
                            line-height: 3.25rem;
                        }
                    }

                    .logo {
                        margin: 5px 0;

                        @media only screen and (min-width: @screen-sm) {
                            width: 230px;
                        }
                    }

                    .share-mobile-wrapper {
                        display: inline-block;

                        .share-mobile-container {
                            bottom: 225px;
                        }
                    }

                    .informations {
                        bottom: 10%;
                        font-size: @font-size-h5;
                        position: absolute;
                                
                        @media only screen and (min-width: @screen-sm) {
                            bottom: 10%;
                            font-size: @font-size-text;
                            line-height: 1.44rem;
                        }

                        @media only screen and (max-width: @screen-sm) {
                            .btn {
                                .text {
                                    display: inline;
                                }
                            }
                        }

                        .btn-share {
                            padding: 6px 12px 6px 0;

                            &:after {
                                display: block;
                                content: ' ';
                                background-color: @gray;
                                height: 1px;
                                margin: 15px 0 15px;
                            }

                            .social-share {
                                left: 0;
                            }
                        }
                    }

                    .statistics {
                        .reset-list;
                        margin-top: 35px;

                        @media only screen and (min-width: @screen-sm) {
                           margin-top: 0;
                        }
                    }
                }
            }

            .share-mobile-wrapper {
                display: inline-block;

                .share-mobile-container {
                    bottom: 110px;
                }
            }

            .controls {
                padding: 0;
                position: static;
            }
        }
    }

    .mobile-slider {
        height: 100%;
        margin: 0 auto;
        max-width: 100%;
        padding: 0;
        position: relative;

        ul.slides {
            .absolute;
            .full-dimensions;
            border: 0 !important;
            list-style: none;
            overflow: hidden;
            padding: 0 !important;

            li.slide-animation {
                .full-dimensions;
                position: relative;
            }

            li.ng-hide {
                position: absolute !important;
                top: 0;
            }
        }
    }

    .pagination-wrapper {
        .arrows-wrapper {
            position: static;

            .btn-neutral {
                background-color: @black;
                border: 1px solid @white;
                box-shadow: none;
                margin-top: -20px;
                padding: 0;
                position: absolute;
                text-align: center;
                top: 50%;
                z-index: 3;

                &.prev {
                    left:1px;
                }

                &.next {
                    right:1px;
                }

                &:hover {
                    color: @white;
                }

                @media only screen and (min-width: @screen-sm) {
                   margin-top: -25px;
                }
            }
        }

        .slider-pagination {
            bottom: 5px;
            position: absolute;
            z-index: 99;
        }
    }
}