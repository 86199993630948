.nav, 
.nav-tabs,
.nav-tabs.nav-justified {
	> li {
		border-top: 1px solid @gray;
		border-bottom: 1px solid @gray;

		> a,
        > div,
        > .tab {
			border: none;
			border-radius: 0;
			height: 60px;
			font-weight: @bold;
			line-height: 60px;
			padding: 0;
            width: 100%;
            text-align: center;

			&:focus,
			&:hover,
			&.active {
				background-color: transparent;
				color: @black;
				cursor: pointer;
			}

			&:focus,
			&.active {
				&:before {
				    .triangle-content;
				    border-left: 10px solid transparent;
				    border-right: 10px solid transparent;
				    border-top: 10px solid @light-gray;
				    left: 50%;
				    margin-left: -5px;
				    position: absolute;
					top: 59px;
					z-index: 2;
				}

				&:after {
				    .triangle-content;
				    border-left: 10px solid transparent;
				    border-right: 10px solid transparent;
				    border-top: 10px solid @gray;
				    left: 50%;
				   	margin-left: -5px;
				    position: absolute;
				}
			}
		}
	} 
}

.nav-tabs.nav-profile {
	width: 100%;
	border: none;
	text-align: center;
	margin-top: 30px;
	
	> li {
		border: none;
		float: none;
		display: inline-block;
		margin: 0 10px;
		text-transform: uppercase;
		font-size: 11px;
		
		> a,
		> div,
		> .tab {
			text-align: center;
			margin-bottom: 0;
			
			height: 25px;
			line-height: 25px;
			
			&:focus,
			&:hover,
			&.active {
				background-color: transparent;
				color: @black;
				cursor: pointer;
				
				border-bottom: 2px solid @black;
			}

			&:focus,
			&.active {
				&:before {
					content: none;
				}

				&:after {
					content: none;
				}
			}
		}
	}
}
