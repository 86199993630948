#side-menu {
    background-color: @light-gray;
    border-right: 1px solid @gray;
    height: 100%;
    left: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 220px;
    position: fixed;
    top: 55px;
    z-index: 16;
    .translate(-100%);
    .transition(all, 0.6s);

    &.international{
        top: 90px;

        @media only screen and (min-width: @screen-sm) {
            top: 112px;
        }
    }

    @media only screen and (min-width: @screen-sm) {
        top: 77px;
    }
    
    body.menu-open & {
        .translate(0);
    }

    #accedi-registrati {
        border-bottom : 1px solid @gray;
        position      : relative;
        height: 60px;
        padding: 20px 0 0 20px;

        a{
            color: #9e9e9f;
            font-size: 14px;

            &.registration{
                padding-left: 15px;
            }

            &:hover{
                color: black;
            }
        }

    }
    #search {
        border-bottom: 1px solid @gray;
        position: relative;

        #inpt-search {
            background      : @light-gray url('https://static.glamour.it/static/img/icons/search.svg') no-repeat 20px 20px;
            background-size : 18px 18px;
            border          : none;
            height          : 60px;
            line-height     : 60px;
            outline         : none;
            padding         : 0 0 0 50px;

            &:focus {
                background : @pink url('https://static.glamour.it/static/img/icons/search-o.svg') no-repeat 20px 20px;
                background-size : 18px 18px;
                color      : @white;
            }
        }

        .icon-search {
            font-size: 20px;
            left: 20px;
            position: absolute;
            top: 20px;

            &:hover {
                cursor: pointer;
            }
        }

        input::-webkit-input-placeholder {
            color: @middle-gray;
            font-family: @font-family-serif;
            font-size: @font-size-small;
            font-style: italic;
            padding: 0 0 0 0;
        }

        input:focus::-webkit-input-placeholder {
            color: @white;
        }
    }
    
    #navigation {
        overflow-x: hidden;
        overflow-y: scroll;
    }

    nav {
        border-bottom: 1px solid @gray;
        margin: 10px 0 0 0;
        padding: 0 0 20px 0;

        &:last-of-type {
            border-bottom: none;
            padding: 0 0 5px 0;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            color:@black;
            font-weight: @bold;
            list-style-type: none;
            line-height: 37px;
            max-height: 37px;
            overflow: hidden;
            padding: 0 0 0 20px;
            position: relative;
            .transition(all, 0.7s);

            &.nav-item-inline {
                display: inline-block;
                padding: 0;
            }

            &.active {
                font-weight: @bold;
                max-height: 999px;
            }

            &:hover {
                cursor: pointer;
                color: @black;
            }

            & ul {
                border: none;
                margin: 0;
                padding: 0;
            }

            & li {
                line-height: 1rem;
                padding: 10px 0;
                font-weight: @lighter;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
            }

            &.pink-gs{
                a{
                    color: @pink-gs;
                }
                
            }

            .arrow-wrapper {
                height: 37px;
                line-height: 37px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                width: 37px;
            }

            .icon-arrow-down,
            .icon-arrow-up  {
                color: @gray;
                font-size: 10px;
                font-weight: @bolder;
            }

            &:hover .icon-arrow-down,
                &.active .icon-arrow-up {
                color: @black;
            }
        }

        &#secondary-navigation {
            .icon {
                font-size: @font-size-medium;
                position: absolute;
                top: 8px;
                left: 15px;
            }

            a {
                padding-left: 25px;
            }
        }

        &#social-navigation {
            li {
                padding: 0;
            }

            a {
                color: @gray;
                display: inline;
                font-size: 22px;
                padding: 0 7px;
                text-decoration: none;

                &:hover {
                    color: @black;
                }
            }
        }
    }
}