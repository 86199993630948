#video-slider {
    position: relative;

    #main-video {
        height: auto;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    #main-video-wrapper {
        height: auto;
        margin: 0 auto;
        max-width: 1280px;
        overflow: hidden;
        position: relative;
        width: 100%;

        object,
        iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            @media only screen and (min-width: @screen-sm) {
                height: 576px;
            }

            @media only screen and (min-width: @screen-md) {
                height: 720px;
            }
        }
    }

    #video-cover {
        @media only screen and (min-width: @screen-sm) {
            height: 576px;
        }

        @media only screen and (min-width: @screen-md) {
            height: 720px;
        }
    }

    .visual {
        background-color: rgba(0, 0, 0, 0.1);
        color: @white;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .visual-wrap {
        .vertical-align;
        height: 100%;
        position: relative;
        width: 100%;

        header {
            display: inline-block;
        }

        h2 {
            color: @white;
            font-size: @font-size-small;
            opacity: .5;
            padding: 0;
            text-transform: uppercase;
        }

        h1 {
            color: @white;
            font-family: @font-family-cursive;
            font-size: 80px;
            font-weight: @lighter;
            margin: 0;
        }

        h3 {
            color: @white;
            font-size: 34px;
            font-weight: 100;
            margin: 0;
            padding: 0;
        }

        .line {
            background-color: @white;
            height: 1px;
            margin: 50px auto 15px;
            width: 180px;
        }
    }

    #video-thumbnails {
        background-color: @white;
        border-bottom: 1px solid @gray;
        border-top: 1px solid @gray;
        overflow: hidden;
        padding: 40px 0 10px;
        position: relative;
        width: 100%;

        .btn {
            background-color: @light-gray;
            box-shadow: none;
            margin-top: -30px;
            position: absolute;
            top: 50%;
            z-index: 5;

            &.prev {
                left: 0;
            }

            &.next {
                right: 0;
            }
        }
    }

    #thumbnails-scrollview {
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 1280px;

        ul {
            .transition(all, .05s);
            left: 0;
            min-height: 185px;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 3930px;

            li {
                display: block;
                float: left;
                margin-right: 32px;
                position: relative;
            }
            .reset-list;
        }

    }

    #video-next,
    #video-prev {
        .transition(all, 0.35s);
        background-color: @white;
        border: 1px solid @gray;

        height: 50px;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 50%;
        width: 50px;
        z-index: 5;

        .video-thumbnail {
            bottom: 0;
            cursor: pointer;
            height: 145px;
            overflow: hidden;
            position: absolute;
            right: 0;
            width: 420px;

            .infos {
                .time {
                    background-color: transparent;
                    bottom: 10px;
                    color: @gray;
                    position: absolute;
                }

                .icon-play-empty {
                    display: none;
                }
            }

            footer {
                .transition(all, 0.35s);
                float: left;
                height: 145px;
                padding: 12px;
                position: relative;
                width: 190px;

                h3 {
                    padding: 5px 0 0;
                    text-align: left;
                }

                p {
                    padding: 2px 0;
                    text-align: left;
                }
            }
        }

        .thumbnail-cover {
            float: left;
            height: 100%;
            overflow: visible;
            width: 200px;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .btn {
            &.prev,
            &.next {
                .transition(all, 0.35s);
                background-color: @white;
                border: none;
                bottom: 0;
                box-shadow: none;
                color: @black;
                opacity: .5;
                position: absolute;
                right: 0;
            }
        }

        &:hover {
            height: 145px;
            width: 420px;

            .play {
                display: none;
            }

            .infos { 
                background-color: @white;
                bottom: 0;

                h3 {
                    text-decoration: none;
                }

                .btn {
                    &.prev,
                    &.next {
                        opacity: 1;
                    }
                }
            }
        }
    }

    #video-prev {
        margin-top: 22px;

        &:hover {
            margin-top: -81px;
        }
    }

    #video-next {
        margin-top: -22px;

        &:hover {
            margin-top: -123px;
        }
    }
}