.stripes {
    @height: 135px;
    @desktop-height: 215px;

    position: relative;
    width: 100%;
    .transition(all, 0.6s);

    .stripes-header {
        text-align: center;
        .transition(padding, 0.6s);

        @media only screen and (min-width: @screen-sm) {
            text-align: left;
        }

        h2 {
            font-family: @font-family-cursive;
            font-size: @font-size-text;
            font-weight: @default;
            margin-bottom: 5px;
            position: relative;
            text-transform: uppercase;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-large;
            }

            &:after {
                content: ' ';
                display: block;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 15px solid @light-gray;
                bottom: -20px;
                height: 0;
                left: 50%;
                margin-left: -20px;
                position: absolute;
                z-index: 2;
                width: 0;

                @media only screen and (min-width: @screen-sm) {
                    left: 0;
                    margin-left: 0;
                }
            }
        }    
    }

    .stripes-wrapper {
        overflow: hidden;
        position: relative;
        width: 100%;

        &.carousel-wrapper {
            height: @height!important;

            @media only screen and (min-width: @screen-sm) {
                height: @desktop-height!important;
            }

            .stripe {
                position: absolute;
                top: 0;
                .opacity(0.8);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .slider-pagination {
        position: absolute;
        top: 135px;

        @media only screen and (min-width: @screen-sm) {
            top: 230px;
        }
    }

    .stripe-with-content {
        margin-bottom: 15px;

        @media only screen and (min-width: @screen-sm) {
            margin-bottom: 45px;
        }
    }

    .stripe {
        background-position: center center;
        background-size: cover;
        height: @height;
        margin: 2px auto;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media only screen and (min-width: @screen-sm) {
            height: @desktop-height;
        }

        .middle-container {
            overflow: visible;
            padding: 0;
        }

        &:hover {
            .stripe-figure img{
                opacity: 1;
            }
        }

        .stripe-background {
            background-position: center center;
            background-size: cover;
            display: block;
            color: @white;
            font-size: @font-size-h3;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            &:after {
                .content;
                .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
            }

            @media only screen and (min-width: @screen-sm) {
                &:hover:after {
                    .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
                }
            }

            .middle-container {
                padding: 0 10px;
            }
        }

        .stripe-figure {
            float: left;
            height: @height;
            position: relative;
            text-align: center;
            width: 105px;
            z-index: 3;
            .vertical-align;

            @media only screen and (min-width: @screen-sm) {
                height: @desktop-height;
                width: 215px;
                margin: 0 0 0 40px;
            }

            &.with-letter {
                width: 145px;

                @media only screen and (min-width: @screen-sm) {
                    width: 235px;
                }
            }

            &.brand {
                margin: 0 40px 0 0;

                img {
                    bottom: 20px;
                    left: 5px;
                    opacity: 1;
                    width: 100px;
                    position: absolute;

                    @media only screen and (min-width: @screen-sm) {
                        left: 0;
                        width: 145px;
                    }
                }
            }

            img {
                opacity: .6;
            }

            .sticker {
                display: inline-block;
                vertical-align: middle;
            }

            .stripe-type {
                bottom: 0;
                position: absolute;
                right: 0;
                z-index: 3;
            }
 
            .rounded {
                vertical-align: middle;
            }

            .topic-letter {
                color: @white;
                font-family: @font-family-sans-serif;
                font-size:   @font-size-large;
                font-weight: @lighter;
                padding: 0 10px;
                vertical-align: middle;

                @media only screen and (min-width: @screen-sm) {
                    font-size:   @font-size-h1;
                    padding: 0 15px;
                }
            }
        }

        .stripe-text {
            height: @height; 
            overflow: hidden;
            padding: 0;
            position: relative;
            z-index: 3;

            @media only screen and (min-width: @screen-sm) {
                height: @desktop-height;
                padding: 0 0 0 40px;
            }

            header {
                position: relative;
                top: 25px;

                @media only screen and (min-width: @screen-sm) {
                    top: 35px;
                }

                &.vertical-align-content {
                    position: static;
                }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: @white;
                font-weight: @lighter;
                margin: 0;
            }

            h2 {
                font-size: @font-size-medium;
                
                @media only screen and (min-width: @screen-sm) {
                   font-size: @font-size-h2;
                } 
            }

            h6 {
                font-family: @font-family-serif;
                margin: 0 0 3px;

                @media only screen and (min-width: @screen-sm) {
                    margin: 0 0 5px;
                }
            }

            p {
                color: @white;
                margin: 5px 0 0;
            }

            a {
                color: @white;
            }

            .rounded {
                display: block;
                margin: 10px auto;
            }
        }
    }

    .stripe-content {
        clear: both;
        margin-bottom: 70px;
        padding: 20px;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        @media only screen and (min-width: @screen-md) {
            padding: 20px 0 20px;
        }

        p {
            font-family: @font-family-serif;
            font-weight: @lighter;

            @media only screen and (min-width: @screen-md) {
                font-size:   @font-size-large;
            }
        }
    }
}