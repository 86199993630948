@import "components/articles.less";
@import "components/authors.less";
@import "components/big-header.less";
@import "components/bb-thumbnail.less";
@import "components/breadcrumb.less";
@import "components/btn.less";
@import "components/call-to-action.less";
@import "components/carousel.less";
@import "components/clock.less";
@import "components/comments.less";
@import "components/controls.less";
@import "components/divider.less";
@import "components/gallery.less";
@import "components/article-related.less";
@import "components/label.less";
@import "components/loading-spinner.less";
@import "components/jquery.nouislider.less";
@import "components/load-more.less";
@import "components/list-element.less";
@import "components/magazine-subscription.less";
@import "components/media.less";
@import "components/message.less";
@import "components/people.less";
@import "components/popover.less";
@import "components/phonebook-scroll.less";
@import "components/question-answer.less";
@import "components/product-link.less";
@import "components/registration.less";
@import "components/rounded.less";
@import "components/select-box.less";
@import "components/slider.less";
@import "components/social-share.less";
@import "components/stickers.less";
@import "components/store-locator.less";
@import "components/stripes.less";
@import "components/tabs.less";
@import "components/topics.less";
@import "components/switch.less";
@import "components/video-slider.less";
@import "components/video-thumbnail.less";
@import "components/related-user.less";
@import "components/navigation-strip.less";
@import "components/ccaas.less";

.middle-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
    .transition(all, 0.6s);

    @media only screen and (min-width: @screen-md) {
        max-width: 925px;
        padding-right: 0;
        padding-left: 0;
    }
}
