#profile-list-badges {

    #profile-list-badges-glamour {
        border-bottom: 1px solid @gray;

        .badges {

            .rounded {
                border-radius: 72px;
                height: 72px;
                width: 72px;
            }

            li {
                display: inline-block;
                vertical-align: top;

                &.type-9 {
                    position: relative;

                    .badge-action {
                        position: absolute;
                        top: 10px;
                        left: 80px;
                        width: 200px;
                        text-align: left;

                        li {
                            margin-bottom: 5px;
                            width: 100%;
                        }

                        span {
                            font-size: @font-size-h6;
                            color: @middle-gray;
                            font-weight: @bold;

                            &.text {
                                margin-left: 5px;
                                text-transform: uppercase;
                                cursor: pointer;

                                &:hover {
                                    color: @black;
                                }
                            }
                        }


                    }
                }
            }
        }
    }

    #profile-list-badges-brand {
        border-bottom: 1px solid @gray;

        .badges {

            .rounded {
                border-radius: 72px;
                height: 72px;
                width: 72px;
            }

            li {
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    .profile-list-header {
        position: static;
        text-align: center;
        margin: 0 0 35px;

        h2 {
            font-size: @font-size-h2;
            font-family: @font-family-cursive;
            font-weight: @light;
            margin-bottom: 0;
        }

        h6 {
            font-size: @font-size-h4;
            color: @dark-white;
        }
    }

    .divider {
        h5 {
            font-size: @font-size-h5;
            color: @middle-gray;
            font-weight: @bold;
        }
    }

    .badge-disclaimer {
        p {
            font-size: @font-size-base;
            color: @black;

            a {
                font-weight: @bold;
                text-decoration: underline;

                &:hover {
                    color: @black;
                }
            }
        }
    }

    ul {
        &.badges {
            width: 550px;
            margin: 0 auto 30px auto;
            text-align: center;

            > li {
                width: 80px;
                margin-left: 50px;
                margin-right: 50px;
                text-align: center;

                figcaption {
                    h6 {
                        font-size: @font-size-extra-small;
                        color: @black;
                        font-weight: @bold;
                    }
                }

                @media only screen and (max-width: 560px) {
                    margin-left: 20px;
                    margin-right: 20px;
                }

                @media only screen and (max-width: 400px) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            @media only screen and (max-width: 560px) {
                width: 368px;
            }

            @media only screen and (max-width: 400px) {
                width: 250px;
            }
        }
    }
}

#badge-modal {
    display: none;
    height: 3000%;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;

    .opacity-bg {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.75) !important;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100% !important;
        z-index: -1;
    }

    .close {
        opacity: 1;
        position: absolute;
        right: 11px;
        top: 4px;
    }

    .badge-modal-content {
        margin: 4% auto;
        max-width: 420px !important;
        overflow: hidden !important;
        background-color: @white;
        position: relative;

        h2 {
            font-family: @font-family-cursive;
            font-size: @font-size-h2;
            font-weight: @default;
            margin: 26px 0 5px !important;
            padding: 0 !important;
            text-align: center;
            width: 100%;
            color: @light-black;
            line-height: @line-height-h2;
        }

        h3 {
            color: @middle-gray;
            font-family: @font-family-cursive;
            font-size: @font-size-medium;
            font-weight: @default;
            margin: 0 0 0.25em;
            text-align: center;
            width: 100%;
            line-height: @line-height-h3;
        }

        .btn-conferma {
            border: 2px solid @pink;
            color: @pink !important;
            background: @white;
            display: block;
            font-size: @font-size-small;
            font-weight: @bold;
            height: auto !important;
            letter-spacing: 2px;
            line-height: @line-height-button;
            padding: 10px 20px !important;
            position: relative;
            text-transform: uppercase;
            margin: 0 auto 40px auto;
            width: 180px;
        }

        .modal-badge {
            width: 140px;
            margin: 48px auto 63px auto;

            .rounded-big-badge {
                width: 140px;
                height: 140px;
                border-radius: 70px;
            }
        }
    }
}
