.store-locator {
    background-color: @white;
    color: black;
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    position: relative;

    .icon-placeholder {
        font-size: 22px;
        position: relative;
        top: 5px;
    }

    .icon-shop {
        font-size: 22px;
        position: relative;
        top: 5px;
    }

    .icon-arrow-right {
        font-size: 0.625rem;
        position: absolute;
        right: 10px;
        top: 22px;
    }
}