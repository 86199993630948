.event-countdown{
	padding: 22px 0;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow:hidden;

	.content-wrapper{
		width: 760px;
		margin: 0 auto;
		font-size: 17px;
		color: @black;
		text-align: center;

		.count{
			color: @pink-gs;
		}
		.action{
			color: @pink-gs;
			text-decoration: underline;
		}

		.event-logo{
			padding: 0 20px;
			margin-top: -4px;
		}

		@media (max-width: @screen-xs-max) {
			&.slides{
				display: block;
				width: 300%;
				height: inherit;
				-webkit-animation:slide 4s infinite;
	     	-moz-animation:slide 4s infinite;
	     	animation:slide 4s infinite;

				.slide{
					width:33.3%;
			    height:100%;
			    float:left;
				}
			}
		}
	}
}

@-webkit-keyframes slide{
    0%,100% {
        margin-left:0%;
    }
    55% {
        margin-left:0%;
    }
    60% {
        margin-left:-100%;
    }
    95% {
        margin-left:-100%;
    }
    99.9999999% {
        margin-left:-200%;
    }
}
@-moz-keyframes slide{
    0%,100% {
        margin-left:0%;
    }
    45% {
        margin-left:0%;
    }
    55% {
        margin-left:-100%;
    }
    85% {
        margin-left:-100%;
    }
    99.9999999% {
        margin-left:-200%;
    }
}
@keyframes slide{
    0%,100% {
        margin-left:0%;
    }
    45% {
        margin-left:0%;
    }
    55% {
        margin-left:-100%;
    }
    85% {
        margin-left:-100%;
    }
    99.9999999% {
        margin-left:-200%;
    }
}