.product-link {
    background-color: rgba(255, 255, 255, 0.7);
    bottom: 0;
    display: block;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 360px;
    z-index: 1;

    &:hover {
        .link {
            .icon {
                transform: scale(2);
            }
        }
    }

    .text,
    .link {
        color: @black;
        font-family: @font-family-sans-serif;
        font-size: @font-size-small;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
    }

    .text {
        font-weight: @lighter;
        width: 60%;
    }

    .link {
        background-color: @white;
        font-weight: @bolder;
        width: 40%;

        .icon {
            display: inline-block;
            font-size: 0.65rem;
            .transition(all, 0.6s);
        }
    }
}