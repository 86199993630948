.related-user{
	display: inline-block;
	position: relative;
	margin: 0px 0px 0px 10px;

	.related-user-icon{
		position: absolute;
		bottom: 0px;
		right: -5px;
		background-color: #FFF;
		border-radius: 100%;
	}
}