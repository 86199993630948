.carousel {
    @height: 480px;
    @desktop-height: 705px;
    @item-height: 275px;
    @item-desktop-height: 515px;
    .transition(all, 0.6s);

    position: relative;
    width: 100%;

    .carousel-wrapper {
        height: @height;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media only screen and (min-width: @screen-sm) {
            height: @desktop-height;
        }
    }

    .carousel-item {
        display: block;
        height: @height;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @media only screen and (min-width: @screen-sm) {
            height: @desktop-height;
        }

        .carousel-figure {
            background-color: @black;
            height: @item-height;
            overflow: hidden;
            position: relative;

            @media only screen and (min-width: @screen-sm) {
                height: @item-desktop-height;
            }

            &:after {
                .content;
                .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
            }

            .carousel-image {
                background-size: cover;
                height: 100%;
                left: 0;
                opacity: 1;
                position: absolute;
                top: 0;
                width: 100%;
            }

            a {
                color: @white;
            }
        }

        .article-group {
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;

            @media only screen and (min-width: @screen-sm) {
                margin-top: 20px;

                .content {
                    p {
                        height: 80px;
                    }
                }
            }
        }

        header {
            bottom: 35px;
            left: 0;
            margin: 0;
            position: absolute;
            text-align: center;
            width: 100%;
            z-index: 4;

            @media only screen and (min-width: @screen-sm) {
                bottom: 55px;
                text-align: left;
            }

            @media only screen and (min-width: @screen-md) {
                left: 50%;
                margin-left: -462.5px;
                text-align: left;
                width: 925px;
            }
        }

        h1 {
            color: @white;
            font-weight: @lighter;
            margin: 0;

            @media only screen and (min-width: @screen-sm) {
                max-width: 91.66666667%;
            }
        }

        .sticker {
            margin: 0 0 5px;

            @media only screen and (min-width: @screen-sm) {
                margin: 0;
                position: absolute;
                right: 0;
                top: -15px;
            }
        }
    }

    .slider-pagination {
        position: absolute;
        top: 245px;
        z-index: 3;

        @media only screen and (min-width: @screen-sm) {
            height: 40px;
            overflow: hidden;
            top: 475px;
        }
    }
}