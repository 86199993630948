.pagination-wrapper {
    .arrows-wrapper {
        position: relative;

        .btn{
            background: rgba(255, 255, 255, 0.5);
            color: #000;

            @media only screen and (min-width : @screen-sm) {
                color: #000;
            }
        }

    }

    .slider-pagination {
        .reset-list;
        height: 35px;
        width: 100%;
        
        li {
            color: rgba(255, 255, 255, 0.5);
            display: inline-block;
            height: 35px;
            line-height: 1.875rem;
            
            &.ng-hide {
                display: inline-block!important;
                visibility: hidden;
            }

            &.page {
                font-size: 26px;
                padding: 0 3px;
                vertical-align: text-top;

                a.selected,
                a:hover {
                    color: @white; 
                }

                a span {
                    display: none;
                }
            }

            &.prev,
            &.next {
                font-size: 0.55rem;
                vertical-align: top;
            }

            &.prev {
                padding: 0 10px 0 0;
            }

            &.next {
                padding: 0 0 0 10px;
            }

            &.active,
            &:hover {
                color: @white;
                cursor: pointer;           
            }
        }
    }
}