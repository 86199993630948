#call-to-action {
    background-color: @flesh;
    bottom: 0;
    display: block;
    height: 100px;
    position: fixed;
    width: 100%;
    z-index: 16;
    .translate(0, 100%);
    .transition(all, 0.6s, cubic-bezier(0, 1.1, 1, 1.3));

    @media only screen and (min-width: @screen-sm) {
        height:175px;
    }

    &.open {
        .translate(0, 0);

        #animated-registrazione {
            display: block;
        }

        #animated-identikit {
            display: block;
        }
    }
    
    figure[ng-click]{
      cursor: pointer;
    }
    
    #animated-registrazione {
        display: none;
        max-width: 70px;
        position: absolute;
        top: -20px;
        cursor: pointer;

        @media only screen and (min-width: @screen-sm) {
            max-width: inherit;
            //top: -30px;
            top: 0;
        }

        > img {
            max-width: 100%;
        }
    }

    #animated-identikit {
        display: none;
        max-width: 70px;
        position: absolute;
        top: -30px;
        left: -5px;
        cursor: pointer;

        @media only screen and (min-width: @screen-sm) {
            max-width: inherit;
            left: 0;
        }

        > img {
            max-width: 100%;
        }
    }

    .middle-container {
        padding-top: 15px;
        position: relative;

        @media only screen and (min-width: @screen-sm) {
            padding-top: 25px;
        }

        h4 {
            font-family: @font-family-cursive;
            font-size: 1rem;
            font-weight: @default;
            margin-top: 0;
            cursor: pointer;

            @media only screen and (min-width: @screen-sm) {
                //font-size: @font-size-h3;
                font-size: 1.57rem;
                margin-left: 80px;

                &.animated-identikit {
                    margin-left: 80px;
                }
            }
        }

        .btn-register {
            margin-top: 0;

            @media only screen and (min-width: @screen-sm) {
                margin-top: 10px;
                margin-left: 80px;

                &.animated-identikit {
                    margin-left: 80px;
                }
            }
        }

        .btn-close {
            background-color: transparent;
            padding: 0;
            position: absolute;
            right: 5px;
            top: 0;

            @media only screen and (min-width: @screen-sm) {
                right: 0;
                top: 10px;
            }

            .icon {
                font-size: 1rem;
                vertical-align: top;

                @media only screen and (min-width: @screen-sm) {
                    font-size: 2rem;
                }
            }
        }
    }

    &.cta-eventi{
        height: 100%;
        width: 100%;
        .translate(0, 0);
        background-color: fade(@black, 70%);
        display: none;
        .opacity(0);

        .middle-container{
            max-width: 770px;
            padding: 0 0 30px;
            
            background-color: @white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            h4 {
                text-transform: uppercase;
                padding-top: 30px;
                padding-bottom: 30px;
                margin: 0;
                color: @pink-gs;
                font-size: 23px;
            }
            .btn-register{
                border-color: @pink-gs;
                color: @pink-gs;
                margin: 0;
                font-size: 14px;
            }
            .btn-close {
                background-color: @pink-gs;
                top: 0; right: 0;
                padding: 8px 10px;
            }

            @media only screen and (max-width: @screen-sm-max) {
                width: 300px;
            }
        }
        &.open{
            display: block;
            .opacity(1);
        }

    }
}


/*
* Challenge
*/
.social-fullwidth{
  text-align: center;
  margin-bottom: 60px;
  .social-share{
    display: inline-block !important;
    a{
      display: inline-block;
    }
    .social-share-item{
      padding: 0 10px;
      display: inline-block;
      border-right: 1px solid @gray;
      .social-share-count{
        display: none;
      }
    }
  }
  .btn.btn-preferred {
    padding: 6px 12px;
    .text{
      display: none;
    }
  }
}
.btn-white{
  background-color: @white;
  color: @black;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 6px 12px;
  font-size: 14px;
  &.full{
    text-align: center;
    display: block;
    width: 100%;
  }
}

.btn-white-myglamour{
    text-align: center;
    margin-top:-20px;
    margin-bottom:30px;
    &.full{
        text-align: center;
        display: block;
        width: 100%;
    }

    a {
        background-color: @pink;
        color: @white;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 10px 28px;
        font-size: 16px;
        border: 1px solid transparent;
        display: inline-block;
            touch-action: manipulation;
    }
  
    span {
        display: inline-block;
        color: @black;
        padding: 2px 0px;
        touch-action: manipulation;
        text-transform: uppercase;
        font-size:18px;
        &.mark {
            color: @black;
            background-color: #F9F9F9;
            padding: 0 0 6px 0;
            &:after{
                  content:'\2713';
                  display:inline-block;
                  color: @black;
                  font-size: 30px;
                  padding:0 0 0 6px;
                  line-height: 25px;
            }

        }
    }
}


.btn-pink-myglamour {
    @media(min-width: @screen-sm-min){
        position: absolute;
        top: 24px;
        right: 34px;
    }    
    a{
        background-color: #e56d79;
        color: #FFF;
        text-transform: uppercase;
        font-size: 24px;
        padding: 12px 40px;
        display: inline-block;
    }
       
    span{
        background-color: #E5E5E5;
        color: @pink;
        font-size: 19px;
        padding: 14px 0px;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        &.mark:after {
            content:'\2713';
            display:inline-block;
            color: @pink;
            font-size: 30px;
            padding:0 0 0 6px;
            line-height: 25px;
        }   
    }       
}

.btn-empty{
  background-color: #F1B3B9;
  color: @black;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 6px 12px;
  font-size: 14px;
  font-style: italic;
  &.full{
    text-align: center;
    display: block;
    width: 100%;
  }
}

.article-challenge{
  min-height: 1200px;
  min-height: 100vh;
  
  .leaf-body{
    padding-left: 0;
    padding-right: 0;
    
    p{
        a {
            color: @pink-gs !important;
            &.link{
                color: @white !important;
                text-decoration: underline;
            }
        }
    }
  }
  
  .sponsor{
    text-align: center;
    .small{
      color: @black;
      font-size: 14px;
      font-weight: bold;
    }
    img{
      width: auto;
    }
  }
  .sub-title{
    font-size: 30px;
    text-align: center;
    font-family: @font-family-serif;
    margin-bottom: 20px;
    line-height: 40px;
    @media(min-width: @screen-sm-min){
      font-size: 50px;
      line-height: 60px;
    }
  }
  .lead{
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    color: @black;
    margin-bottom: 50px;
    padding: 0 26px;
  }
}
.article-challenge-header{
  margin-bottom: 60px;
  padding: 0 !important;
  
  .col-title{
    background: @pink;
    padding: 90px 84px;
    .title{
      color: @white;
      font-size: 45px;
      margin: 0 0 30px !important;
    }
  }
  .col-image{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .btn-white{
    color: @pink;
    padding: 6px 20px;
    font-weight: 600;
  }
  .row{
    margin-bottom: 60px;
  }
  
  @media(max-width: @screen-xs-max){
    margin-bottom: 20px;
    .row{
      margin-bottom: 40px;
    }
    .col-title, .col-image{
      display: block;
    }
    .col-title{
      padding: 30px 34px;
      .title{
        font-size: 35px;
        line-height: 2.80rem;
      }
    }
    .col-image{
      height: 260px;
    }
  }
}

.block-list-hexagon{
  margin-bottom: 80px;
  .clearfix();
  
  .block-item-title{
    font-family: @font-family-sans-serif;
    color: @black;
    text-align: center;
    font-size: 26px;
    font-weight: @bold;
  }
  
  .block-item{
    @size: 220px;
    position: relative;
    width: 100%;
    height: (@size + 100px);
    float: left;
    @media(min-width: @screen-sm-min){
      width: 33.333%;
      &.block-item-0, &.block-item-5{
        margin-left: 16%;
      }
    }
    .block-item-img{
      display: block;
      margin: 0 auto;
      position: relative;
      width: @size;
      height: @size;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 8px solid @pink;
      border-radius: 50%;
      .block-item-index{
        position: absolute;
        bottom: 0;
        left: 0; right: 0;
        text-align: center;
        color: @black;
        font-size: 45px;
        font-family: @font-family-serif;
      }
    }
    .pop-over{
      @wd: 320px;
      display: none;
      width: 320px;
      padding: 20px;
      background-color: @pink;
      position: absolute;
      z-index: 1;
      top: (@size - 8px);
      left: 50%;
      margin-left: (-@wd / 2);
      .block-item-title{
        color: @white;
        margin-bottom: 20px;
      }
      .pop-over-text{
        color: @white;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 60px;
        font-weight: @light;
      }
      .pop-over-img{
        margin-bottom: 18px;
      }
      &:after {
        @arrowSize: 12px;
        position: absolute;
        top: (-@arrowSize);
        left: 50%;
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-left: @arrowSize solid transparent;
        border-right: @arrowSize solid transparent;
        border-bottom: @arrowSize solid @pink;
        margin-left: (-@arrowSize);
      }
      .visible-touch{
        display: none;
      }
      .btn-close{
        position: absolute;
        width: 40px;
        line-height: 32px;
        text-align: center;
        top: 0;
        right: 0;
        font-size: 34px;
        color: @white;
      }
    }
    .touch &{
      .visible-touch{
        display: block;
      }
      &.active{
        .pop-over{
          display: block;
        }
      }
    }
    .no-touch &{
      &:hover{
        .pop-over{
          display: block;
        }
      }
    }
  }
}

.challenge-bar{
  position: relative;
  background: #e5e5e5;
  padding: 24px 34px;
  margin-bottom: 80px;
  text-align: center;
  .bar-title{
    color: @black;
    font-size: 28px;
    margin: 0 0 20px;
  }
  .btn-pink{
    background-color: @pink;
    color: @white;
    text-transform: uppercase;
    font-size: 24px;
    padding: 12px 40px;
    a {
        color: @white;
    }
  }
  @media(min-width: @screen-sm-min){
    margin-bottom: 200px;
    text-align: left;
    .bar-title{
      line-height: 60px !important;
      margin: 0;
    }
    .btn-pink{
      position: absolute;
      top: 24px;
      right: 34px;
    }
  }
}

.article-challenge{
    .block-list-hexagon{
        p.pop-over-text {
            text-align:center;
            margin-bottom:30px !important;
        }
    }
}

@green-challenge: #909845;
@green-challenge-btn: #C5CA8E;
@green-challenge-btn-text: #000000;
.verde.article-challenge{
    .article-challenge-header .col-title {background: @green-challenge;}
    
    .block-list-hexagon .block-item .block-item-img {border: 8px solid @green-challenge;}
    .block-list-hexagon .block-item .pop-over {background-color: @green-challenge;}

    .btn-empty {background-color: @green-challenge-btn;color: @green-challenge-btn-text;}
    .block-list-hexagon .block-item .pop-over:after {border-bottom: 12px solid @green-challenge;}
    
    .btn-white {background-color: #FFF;color: #000;}
    a.btn-white:hover, a.btn-white:focus {color: rgba(0, 0, 0, 0.7);}
    
    @media (min-width: 768px){
        .block-list-hexagon .block-item.block-item-0, .block-list-hexagon .block-item.block-item-5, .block-list-hexagon .block-item.block-item-3 {margin-left: 0 !important;}        
    }
}

.item-4.article-challenge{
    @media (min-width: 768px){
        .block-list-hexagon .block-item.block-item-2 {margin-left: 16%;}     
    }
}

.item-5.article-challenge{
    @media (min-width: 768px){
        .block-list-hexagon .block-item.block-item-0 {margin-left: 0 !important;}
        .block-list-hexagon .block-item.block-item-3 {margin-left: 16%;}       
    }
}

.item-6.article-challenge{
    @media (min-width: 768px){
        .block-list-hexagon .block-item.block-item-0, .block-list-hexagon .block-item.block-item-5, .block-list-hexagon .block-item.block-item-3 {margin-left: 0 !important;}
    }
}