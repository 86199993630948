.social-share {
    color: @gray;
    .reset-list;

    a {
        color: @gray;
        display: block;
    }

    &.inline {
        border: 1px solid @gray;
        height: 70px;
        line-height: 70px;
        /*width: 240px;*/

        @media only screen and (max-width: @screen-sm) {
            line-height: 25px;
        }
    }

    &.inline .social-share-item {
        display: inline-block;
        position: relative;
        width: 45px;

        &:first-child:before {
            border: none;
            content: '';
        }

        &:before {
            border-left: 1px solid @gray;
            content: ' ';
            display: inline-block;
            height: 50px;
            left: 0;
            position: absolute;
            top: 10px;
        }
    }

    &.no-border {
        border: none;

        .social-share-item {
            &:before {
                content: none;
            }
        }
    }

    .social-share-item {
        font-size: 1.44rem; // ~ 24px
        text-align: center;
        vertical-align: top;

        @media only screen and (max-width: @screen-sm) {
            strong {
                color: @black;
                font-size: @font-size-extra-small;
            }
        }

        &:hover {
            color: @black;
            cursor: pointer;
        }

        &:hover .icon-facebook {
            color: @facebook;
            cursor: pointer;
        }

        &:hover .icon-twitter {
            color: @twitter;
            cursor: pointer;
        }

        &:hover .icon-googleplus {
            color: @googleplus;
            cursor: pointer;
        }

        &:hover .icon-pinterest {
            color: @pinterest;
        }

        &:hover .icon-instagram {
            color: @instagram;
        }

        &:hover .icon-youtube {
            color: @youtube;
        }

        &:hover .icon-email {
            color: @black;
        }
    }
}

// Pop up mobile.
.share-mobile-wrapper {
    &.expanded {
        .share-overlay {
            display: block;
        }

        .share-mobile-container {
            display: block;
        }
    }

    .share-overlay {
        background-color: rgba(0, 0, 0, 0.6);
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 4;
        -webkit-transform: translateZ(0);
    }

    .share-mobile-container {
        background-color: @white;
        border: 1px solid @gray;
        box-shadow: 1px 1px 5px @gray;
        bottom: 90px;
        display: none;
        height: auto;
        margin: 0 20px;
        min-height: 190px;
        position: absolute;
        padding: 5px;
        width: ~"calc(100% - 40px)";

        @media only screen and (min-width: @screen-sm) {
            & {
                min-height: 275px;
                padding: 20px 50px;
            }
        }

        &.expanded {
            display: block;
        }

        h3 {
            margin-bottom: 0;
        }

        ul.social-share {
            border: none;
            line-height: initial;
            position: relative; 
            top: 50px;
            width: auto;
            .reset-list;
        }

        .social-share-item {
            &:before {
                left: 0;
                top: -10px;
            }

            .icon {
                font-size: @font-size-h3;
            }
        }
    }
}