@glamourColor: #e56d79;

.ccaas {
    position   : absolute;
    bottom     : 0;
    left       : 0;
    right      : 0;
    z-index    : 7;
    padding    : 0;
    margin     : 0 auto;
    display    : block;
    text-align : left;

    .ccaasContent {
        display    : none;
        position   : absolute;
        bottom     : 50px;
        width      : 100%;
        height     : 110px;
        overflow   : hidden;
        padding    : 10px;
        background : rgba(255, 255, 255, 0.9);
        border-top : 1px solid #e5e5e5;

        @media only screen and (min-width : @screen-tablet) {
            padding : 20px 20px 20px 40px;
        }

        &.open {
            display : block;
            height  : 176px;
        }

        > * {
            display        : table-cell;
            float          : none !important;
            vertical-align : top;
            position       : relative;
        }

        p {
            font-size   : 15px;
            line-height : 1
        }

        .imageSection {
            padding : 0;
            width   : 130px;
            height  : 100px;
            @media only screen and (min-width : @screen-tablet) {
                width  : 150px;
                height : 120px;
            }

            img {
                max-width : 100% !important; max-height : 100% !important;
                width     : auto !important; height : auto !important;
            }
        }

        .ccaasTitle {
            font-family : 'Open Sans', Helvetica, sans-serif;
            font-size   : 18px;
            line-height : 1;
            font-weight : 300;
            color       : black;
            margin      : 0 0 10px 0;
            padding     : 0;

            @media only screen and (min-width : @screen-tablet) {
                font-size : 20px;
            }
        }

        .textSection {
            padding-left  : 0;
            padding-right : 10px;
            width         : 70%;

            @media only screen and (min-width : @screen-tablet) {
                padding-right : 20px;
                width         : auto;
            }

        }

        .ccaasAbstract {
            font-size   : 14px !important;
            line-height : 1.5 !important;
            max-height  : 105px;
            overflow    : hidden;
            margin      : 0 0 0 32px;
            color       : #9e9e9f !important;
            font-weight : normal;
            overflow-y  : auto;

            @media only screen and (min-width : @screen-tablet) {
                max-height : 100px;
                margin     : 0 0 20px 0;
            }

        }

    }

    .ccaasContentBundle {
        position      : absolute;
        width         : calc(100% + 2px);
        height        : 172px;
        background    : white;
        padding       : 0 10px;
        bottom        : -230px;
        display       : none;
        z-index       : 8;
        border-bottom : 1px solid #e5e5e5;
        border-top    : 1px solid #e5e5e5;


        &.collection{
            bottom: -150px;
            height: 150px;
        }

        @media only screen and (min-width : @screen-tablet) {
            height       : 182px;
            bottom       : -182px;
            padding      : 0 40px;
            border-left  : 1px solid #e5e5e5;
            border-right : 1px solid #e5e5e5;
            margin-left  : -1px;
            margin-right : -1px;

            &.collection {
                bottom: -150px;
            }
        }

        &.fullscreen {
            bottom        : 110px;
            border-top    : 1px solid #e5e5e5;
            border-bottom : 0;

            @media only screen and (min-width : @screen-tablet) {
                bottom  : 0;
                border  : 0;
                width   : 50%;
                display : block;
                background: transparent;

                &.collection{
                    height        : 172px;
                    background    : white;
                    border-bottom : 1px solid #e5e5e5;
                }
            }

            @media only screen and (min-width : @screen-desktop) {
                width   : 58%;
            }

            &.collection{
                position: absolute;
                bottom: 110px;
                border-bottom: 0;
                width:100%;

                @media only screen and (min-width : @screen-desktop) {
                    position: absolute;
                    bottom: 84px;
                    width: 100%;
                    background: white;
                    border-top: 1px solid #e5e5e5;
                    border-bottom: 1px solid #e5e5e5;
                }

            }
        }


        &.open {
            display : block;
        }
        .scroll-wrapper {
            .transition(all, 0.2s, ease-out);
            height : 100%;
        }

        .content-bundle-wrapper {
            width       : 100%;
            height      : 100%;
            //white-space : nowrap;
            overflow    : hidden;

            .scroll-wrapper {

                article {
                    width      : 125px;
                    height     : 170px;
                    padding    : 0;
                    float      : left;
                    display    : block;
                    position   : relative;
                    text-align : center;
                    margin     : 0 15px 0 0;

                    &.selected{
                        border-bottom: 10px solid @glamourColor;
                    }

                    @media only screen and (min-width : @screen-tablet) {
                        margin     : 0;
                    }

                    figure {
                        width  : 125px;
                        height : 125px;
                        margin : 10px auto 10px auto;

                        &.collection{
                            margin : 10px auto;
                        }
                    }

                    img {
                        max-height : 100%;
                        max-width  : 100%;
                        width      : 100%;
                        height     : 100%;
                    }

                    span {
                        font-family : 'Open Sans', Helvetica, sans-serif;
                        font-weight : 300;
                        font-size   : 11px;
                        display     : block;
                        //overflow    : hidden;
                        width       : 100px;
                        margin      : 0 auto;
                        line-height : 12px;
                        color       : #9e9e9f;

                        @media only screen and (min-width : @screen-tablet) {
                            width: auto;
                            line-height: normal;
                        }
                    }


                    @media only screen and (min-width : @screen-tablet) {
                        figure.fullscreen {
                            width  : 135px;
                            height : 135px;
                            margin : 5px auto;

                            &.collection {
                                margin : 23px auto;
                            }
                        }
                    }


                    &.collection{
                        height :150px;

                        &.fullscreen {
                            @media only screen and (min-width : @screen-desktop) {
                                height : 170px;
                                width: 135px;

                                figure.collection{
                                    margin: 16px auto;
                                }
                            }
                        }

                    }
                }
            }
        }

        .goRight,
        .goLeft {
            border     : 0;
            color      : @glamourColor !important;
            width      : 22px;
            height     : 22px;
            position   : absolute;
            top        : 50px;
            left       : 10px;
            text-align : center;
            z-index    : 3;
            display    : none;

            @media only screen and (min-width : @screen-tablet) {
                top     : 75px;
                display : block;
            }
        }
        .goRight {
            right : 10px;
            left  : inherit;
        }

        &.collection{
            .textSection{
                position   : absolute;
                top        : 0;
                left       : 0;
                right      : 0;
                bottom     : 0;
                background : rgba(255, 255, 255, 0.8);
                padding    : 10px;
                z-index    : 4;

                .ccaasTitle{
                    font-family : 'Open Sans', Helvetica, sans-serif;
                    font-size   : 18px;
                    line-height : 1;
                    font-weight : 300;
                    color       : black;
                    margin      : 0 0 10px 0;
                    padding     : 0;

                    @media only screen and (min-width : @screen-tablet) {
                        font-size:20px;
                    }
                }

                .ccaasAbstract{
                    font-size   : 14px !important;
                    line-height : 1.5 !important;
                    max-height  : 105px;
                    overflow    : hidden;
                    color       : #9e9e9f !important;
                    margin      : 0;
                    font-weight : normal;
                    overflow-y  : auto;

                    @media only screen and (min-width : @screen-tablet) {
                        max-height: 100px;
                        margin: 0 0 20px 0;
                    }

                }
            }
        }
    }

    .ccaasAction {
        display    : block;
        position   : absolute;
        bottom     : 0;
        width      : 100%;
        height     : 50px;
        background : rgba(255, 255, 255, 0.9);

        &.collection{
            bottom: -259px;
        }

        @media only screen and (min-width : @screen-tablet) {
            height     : 70px;
            border-top : 1px solid #e5e5e5;

            &.open {
                background : #fff;

                &.bundle{
                    border-bottom : 1px solid #e5e5e5;
                }
            }

            &.collection{
                width      : calc(100% + 2px);
                margin     : 0 -1px;
                border     : 1px solid #e5e5e5;
                background : none;
                bottom     : -219px;
            }
        }

        &.fullscreen {
            height     : 50px;
            background : #f6f6f6;

            @media only screen and (min-width : @screen-tablet) {
                height : 132px;
            }

            &.bundle {
                @media only screen and (min-width : @screen-tablet) {
                    height : 200px;
                }
            }

            &.collection{
                bottom: 0;

                @media only screen and (min-width : @screen-tablet) {
                    height     : 50px;
                }

                @media only screen and (min-width : @screen-desktop) {
                    height     : 85px;
                }
            }
        }

        .ccaasTitle{
            &.fullscreen{
                font-family   : 'Open Sans', Helvetica, sans-serif;
                font-size     : 25px;
                line-height   : 1;
                font-weight   : 300;
                padding       : 0;
                position      : absolute;
                left          : auto;
                top           : 30px;
                right         : 10px;
                text-align    : right;
                color         : @glamourColor;

                &.collection{
                    font-family   : 'Open Sans', Helvetica, sans-serif;
                    font-size     : 25px;
                    line-height   : 1;
                    font-weight   : 300;
                    padding-left  : 20px;
                    padding-right : 40px;
                    position      : absolute;
                    text-align    : left;
                    left          : 0;
                    top           : -550px;
                    display       : block;
                    color         : black;
                }
            }
        }

        .ccaasAbstract{
            &.fullscreen.collection{
                font-family : 'Open Sans', Helvetica, sans-serif;
                font-size   : 16px;
                line-height : 1.3;
                font-weight : 300;
                text-align  : left;
                padding-left  : 0;
                padding-right : 40px;
                position    : absolute;
                left        : 20px;
                top         : -500px;
                right       : auto;
                width       : 380px;
            }
        }

        .ccaasActionButton {
            border      : 0;
            padding     : 10px;
            font-size   : 18px;
            line-height : 20px;
            font-family : 'Open Sans', Helvetica, sans-serif;
            font-weight : 300;
            color       : black;
            position    : absolute;
            bottom      : 50px;
            left        : 0;
            right       : 0;
            background  : rgba(255, 255, 255, 0.9);
            width       : 100%;
            height      : 60px;
            text-align  : left;
            border-top  : 1px solid #e5e5e5;
            white-space : nowrap;
            text-overflow: ellipsis;

            &.fullscreen {
                background : #fafafa;
            }

            &.bundle {
                //top : 0;
                bottom      : -60px;
                color: @glamourColor;
                border-bottom  : 1px solid #e5e5e5;

                @media only screen and (min-width : @screen-tablet) {
                    border-bottom: none;
                }

                &.buttonClose{
                    background: white;

                    &.fullscreen{
                        bottom: 50px;
                    }
                }

                &.fullscreen{
                    bottom: 50px;
                }
            }

            @media only screen and (min-width : @screen-tablet) {
                position    : relative;
                width       : auto;
                font-size   : 20px;
                line-height : 1;
                height      : 42px;
                top         : 13px;
                bottom      : initial;
                background  : transparent;
                border-top  : none;

                &.bundle {
                    bottom  : initial;
                }

            }


            &.collection.fullscreen{
                @media only screen and (min-width : @screen-tablet) {
                    position : absolute;
                    bottom   : 50px;
                    left     : 0;
                    right    : 0;
                    width    : 100%;
                    top      : inherit;
                    height   : 60px;
                }
            }

            .dida {
                float : left;

                &.buttonClose {
                    font-size      : 13px;
                    letter-spacing : 1px;
                    line-height    : 20px;
                    text-transform : uppercase;
                }
            }
        }

        .leftArea {
            width    : calc(100% - 400px);
            position : relative;
            height   : 132px;
            float    : left;

            .imageSection {
                float      : left;
                margin     : 16px 20px;
                padding    : 0;
                text-align : center;
                width      : 100px;
                height     : 100px;

                img {
                    max-width : 100% !important;
                    max-height : 100% !important;
                    width     : auto !important;
                    height : auto !important;
                }

            }

            a {
                display : block;
                margin  : 16px 0;

                .ccaasTitle {
                    font-family : 'Open Sans', Helvetica, sans-serif;
                    font-weight : 300;
                    font-size   : 22px;
                    line-height : 1;
                    color       : black;
                    margin      : 0 0 10px 0;
                    padding     : 0;
                }

                .ccaasAbstract {
                    font-size   : 14px !important;
                    line-height : 20px !important;
                    max-height  : 45px;
                    overflow-y  : auto;
                    margin      : 0;
                    color       : #9e9e9f !important;
                    font-weight : normal;

                    @media only screen and (min-width : @screen-desktop) {
                        max-height  : 66px;
                    }
                }
            }

        }

        .rightArea {
            position : relative;
            width    : 100%;

            @media only screen and (min-width : @screen-tablet) {
                position : absolute;
                top      : 13px;
                right    : 10px;
                width    : 420px;

                &.fullscreen {
                    top  : 45px;
                    width: 370px;

                    &.bundle{
                        top  : 78px;
                        width: 370px;
                    }

                    &.collection{
                        position: absolute;
                        bottom: 10px;
                        right: 20px;
                        width: calc(100% - 20px);
                        left: 0;
                        top: auto;
                    }
                }


            }

            @media only screen and (min-width : @screen-desktop) {
                position : absolute;
                top      : 13px;
                right    : 10px;
                width    : 420px;

                &.fullscreen {
                    top : 45px;
                    width    : 450px;

                    &.bundle{
                        top : 78px;
                        width    : 450px;
                    }
                }


            }

            &.collection.fullscreen{
                @media only screen and (min-width : @screen-tablet) {
                    position : relative;
                    width    : 100%;
                    bottom: inherit;
                }
                @media only screen and (min-width : @screen-desktop) {
                    position : absolute;
                    bottom: 18px;
                    right: 15px;
                    left: 0;
                    top: auto;
                    width: calc(100% - 20px);
                }
            }

            .ccaasPrice {
                font-size    : 18px;
                line-height  : 1;
                font-family  : 'Open Sans', Helvetica, sans-serif;
                display      : block;
                font-weight  : 300;
                color        : white;
                width        : 50%;
                height       : 50px;
                border-right : 1px solid white;
                margin       : 0;
                position     : absolute;
                padding      : 12px 10px 0 10px;
                background   : @glamourColor;

                @media only screen and (min-width : @screen-tablet) {
                    font-size    : 24px;
                    color        : black;
                    border-right : none;
                    width        : 210px;
                    height       : 42px;
                    position     : relative;
                    padding-top  : 10px;
                    float        : left;
                    background   : transparent;
                    text-align   : right;

                    &.fullscreen {
                        font-size   : 30px;
                        width: 150px;
                    }
                }

                @media only screen and (min-width : @screen-desktop) {
                    &.fullscreen {
                        width: 250px;
                    }
                }

                &.bundle,
                &.collection{
                    font-size :18px;
                    line-height: 24px;

                    @media only screen and (min-width : @screen-tablet) {
                        font-size :20px;
                    }

                    &.fullscreen{
                        @media only screen and (min-width : @screen-tablet) {
                            font-size :20px;
                        }

                        @media only screen and (min-width : @screen-tablet) {
                            font-size : 28px;
                        }
                    }
                }

                &.collection.fullscreen{
                    font-size    : 18px;
                    line-height  : 1;
                    font-family  : 'Open Sans', Helvetica, sans-serif;
                    display      : block;
                    font-weight  : 300;
                    color        : white;
                    width        : 50%;
                    height       : 50px;
                    border-right : 1px solid white;
                    margin       : 0;
                    position     : absolute;
                    padding      : 12px 10px 0 10px;
                    background   : @glamourColor;
                    float: none;
                    text-align : left;


                    @media only screen and (min-width : @screen-desktop) {
                        color        : black;
                        border-right : none;
                        height       : 42px;
                        position     : relative;
                        padding-top  : 10px;
                        float        : left;
                        background   : transparent;
                        text-align   : left;
                        font-size    : 24px;
                        width        : 450px;
                    }

                }

                .oldprice{
                    color: #d0d0d0;
                    text-decoration: line-through;
                }

            }

            .piuInfo {
                font-family    : 'Open Sans', Helvetica, sans-serif;
                font-weight    : 300;
                text-align     : left;
                font-size      : 13px;
                letter-spacing : 1px;
                line-height    : 20px;
                text-transform : uppercase;
                color          : white;
                display        : block;
                width          : 40%;
                height         : 50px;
                border         : 0;
                border-right   : 1px solid white;
                margin         : 0;
                position       : absolute;
                padding        : 0 10px;
                background     : @glamourColor;
                top            : 0;

                @media only screen and (min-width : @screen-tablet) {
                    font-size    : 13px;
                    color        : black;
                    border-right : none;
                    width        : 150px;
                    height       : 42px;
                    position     : relative;
                    padding-top  : 10px;
                    margin-left  : 20px;
                    float        : left;
                    background   : transparent;

                    &.fullscreen {
                        font-size   : 14px;
                        padding-top : 5px;
                    }
                }

            }

            .ccaasActionBuy {
                font-family    : 'Open Sans', Helvetica, sans-serif;
                color          : white;
                background     : @glamourColor;
                font-weight    : 700;
                border         : 0;
                font-size      : 13px;
                text-transform : uppercase;
                letter-spacing : 1px;
                line-height    : 1;
                width          : 50%;
                height         : 50px;
                display        : block;
                margin         : 0;
                position       : absolute;
                left           : 50%;

                span {
                    font-size   : 10px;
                    margin-left : 10px;
                }

                @media only screen and (min-width : @screen-tablet) {
                    position : relative;
                    left     : inherit;
                    width    : auto;
                    height   : 42px;
                    padding  : 5px 15px;
                    float    : right;
                    letter-spacing : 2px;
                }


                &.collection.fullscreen{
                    font-family    : 'Open Sans', Helvetica, sans-serif;
                    color          : white;
                    background     : @glamourColor;
                    font-weight    : 700;
                    border         : 0;
                    font-size      : 13px;
                    text-transform : uppercase;
                    letter-spacing : 1px;
                    line-height    : 1;
                    width          : 50%;
                    height         : 50px;
                    display        : block;
                    margin         : 0;
                    position       : absolute;
                    left           : 50%;


                    @media only screen and (min-width : @screen-desktop) {
                        position : relative;
                        left     : inherit;
                        width    : auto;
                        height   : 42px;
                        padding  : 5px 15px;
                        float    : right;
                        letter-spacing : 2px;
                    }

                }

                &:hover {
                    color : black;
                }
            }
        }

    }

    span.icon-arrow-up,
    span.icon-arrow-down {
        border-radius : 10px;
        border        : 1px solid @glamourColor;

        font-size     : 10px;
        padding       : 4px;
        background    : white;
        float         : left;
        margin-right  : 10px;

        &.fullscreen {
            font-size     : 12px;
            border-radius : 12px;

            @media only screen and (min-width : @screen-tablet) {
                font-size     : 10px;
                border-radius : 10px;

            }
        }
    }
    span.icon-arrow-down {
        border     : 1px solid black;
        background : black;
        color      : white;
        padding    : 5px 4px 3px 4px;

        &.bundle {
            border        : 1px solid @glamourColor;
            background    : white;
            color          : black;
            &.fullscreen{
                border     : 1px solid black;
                background : black;
                color      : white;
            }
        }
    }
    span.icon-arrow-up{
        &.bundle {
            border     : 1px solid black;
            background : black;
            color      : white;
            &.fullscreen{
                border        : 1px solid @glamourColor;
                background    : white;
                color          : black;
            }
        }
    }
}

ccaas-image,
ccaas-bundle,
ccaas-collection{
    display  : block;
    width    : 100%;
    position : relative;

    img {
        margin : 0 auto;
    }
}

.gallery-item{
    ccaas-image,
    ccaas-bundle,
    ccaas-collection{
        position:absolute;
        bottom: 0;
        left :0;
        background: white;
    }
}


.carousel-inner.extraPadding{
    padding-top:30px;
    padding-bottom: 220px;
}