.divider {
    margin: 35px auto 0;
    position: relative;

    @media only screen and (min-width: @screen-sm) {
        padding-bottom: 5px;
    }

    &:hover {
        cursor: pointer;
    }

    &.default-cursor {
        cursor: default;
    }

    h5 {
        font-size: @font-size-extra-small;
        font-weight: @bold;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-h5;
        }

        .icon-nib {
            color: @gray;
            display: block;
            font-size: 28px;
        }
    }
}

.divider_arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    bottom: -10px;
    left: 50%;
    border-color: transparent;
    border-style: solid;
    border-width: 20px 30px 0 30px;
    margin-left: -30px;
    border-top-color: @middle-light-gray;
}

.clear {
    clear: both;
}