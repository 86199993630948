.btn {
    border-radius: 0;
    box-shadow: none;
    .appearence(none);

    &:active,
    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        cursor: pointer;
    }

    &.btn-neutral {
        background-color: @white;
        border: 1px solid @gray;
        box-shadow: 1px 1px 5px @gray;
        font-size: @font-size-base;

        @media only screen and (min-width: @screen-sm) {
            &:hover {
                color: @black;
            }
        }

        //@media only screen and (max-width: @screen-sm) {
        //    &:hover {
        //        color: @gray;
        //    }
        //}
    }

    &.btn-bordered {
        background-color: transparent;
        border: 2px solid;
        font-size: @font-size-extra-small;
        font-weight: @bold;
        letter-spacing: 2px;
        padding: 6px 15px;
        text-transform: uppercase;

        @media only screen and (min-width: @screen-sm) {
            &.btn-xs {
                font-size: 0.50rem;
                padding: 4px 8px;
                border-width: 1px;
            }
            
            &.btn-sm {
                font-size: @font-size-extra-small;
                padding: 4px 8px;
            }
            
            &.btn-md {
                font-size: @font-size-small;
                padding: 6px 15px;
            }

            &.btn-lg {
                font-size: @font-size-base;
                padding: 9px 28px;
            }
            
            &.btn-extra-padding {
                padding: 20px;
            }
        }

        .text {
            opacity: .6;
        }
    }

    &.btn-preferred {
        font-family: @font-family-serif;
        padding: 0px;

        @media only screen and (min-width: @screen-sm) {
            padding: 6px 12px;
        }

        &.saved {
            color: @black;
        }

        .icon-star {
            display: inline-block;
            padding: 0;
            .rotate2d(0);
            .transition(all, 0.6s);
        }

        &.saved .icon-star {
            //.rotate2d(-360deg);
            //.transform-origin(55%, 55%);
            -webkit-transition: all 0s ease-in;
            -moz-transition: all 0s ease-in;
            -o-transition: all 0s ease-in;
            transition: all 0s ease-in;
        }

        @media only screen and (min-width: @screen-sm) {
            &:active .icon-star,
            &:hover .icon-star {
                //.rotate2d(-360deg);
                //.transform-origin(55%, 55%);
                animation-duration: 0.3s;
                animation-iteration-count: 1;
                animation-name: enlarger;
                animation-timing-function: linear;
                color: @black;
            }
        }
    }
    
    &.btn-gray-pink {
        border-color: @gray;
        color: @gray;
        
        &.selected { 
            border-color: @pink;
            color: @pink;
        }
        
        @media only screen and (min-width: @screen-sm) {
            &:hover {
                border-color: @pink;
                color: @pink;
            }
        }
    }

    &.btn-pink {
        border-color: @pink;
        color: @pink;
    }

    &.btn-share {
        font-family: @font-family-serif;
    }

    &.btn-square {
        height: 40px;
        line-height: 40px;
        padding: 0;
        width: 40px;

        @media only screen and (min-width: @screen-sm) {
            height: 50px;
            line-height: 50px;
            width: 50px;
        }
    }

    .icon ~ .text {
        padding: 0 0 0 2px;
    }
    
    /** Variants */
    &.btn-align-center {
        margin-right: auto;
        margin-left: auto;
    }
    
    &.btn-social-fixed-width {
        @media only screen and (min-width: @screen-sm) {
            width: 220px;
        }
    }
}

@keyframes enlarger {
    50% {
        transform: scale(1.4);
        color: @black;
    }
}
