#clock { 
    background-color: @black;
    border-right: 1px solid @bb-gray;
    color: @white;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 5px 0;
    width: 35%;
    position: relative; 
    text-align: center; 

    @media only screen and (min-width: @screen-sm) {
        display: inline-block;
        height: 195px;
        padding: 0;
        width: 240px;
    }

    #clock-wrapper {
        height: 100%;
        position: relative;
    }

    svg {
        height: 100%;
        left: 15px;
        position: relative; 
        width: 100%;  
    }

    #title {
        color: @pink; 
        font-family: @font-family-fantasy;
        font-size: @font-size-small;
        position: relative; 
        .rotate(0deg);

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-large;
            left: -50px;
            position: absolute;
            top: 40%;
            z-index: 1;
            .rotate(-90deg);
        }
    }

    #msg {
        font-size: 0.5rem;
        font-weight: @bold;
        margin: 2px 0;
        position: relative;
        text-transform: uppercase;
        color: @white;

        @media only screen and (min-width: @screen-sm) {
            font-size: 0.625rem;
            height: 100%;
            left: 15px; 
            line-height: 140px;
            position: absolute; 
            top: 0;
            width: 100%;
        }
    }

    #count {
        font-size: @font-size-base;
        line-height: 1rem;
        position: relative;
        color: @white;
        
        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-h3;
            height: 100%;
            left: 15px; 
            line-height: 200px; 
            position: absolute; 
            top: 0;
            width: 100%; 
            z-index: 2; 
        }
    }

    .icon {
        color: @dark-gray;

        @media only screen and (min-width: @screen-sm) {
            left: 50%;
            font-size: 1.69rem;
            margin-top: 32px;
            position: absolute;
            top: 50%;
        }
    }
}