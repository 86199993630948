.q-a {
    margin: 15px 0 25px;

    @media only screen and (min-width: @screen-sm) {
        margin: 15px 0 45px;
    }

    .question,
    .answer {
        border-bottom: none;
        margin: 0;
        padding: 0;
    }

    .question {
        margin-bottom: 25px;

        @media only screen and (min-width: @screen-sm) {
            margin-bottom: 55px;
        }

        p {
            font-family: @font-family-sans-serif;
            font-weight: @bold;
            margin: 0;

            @media only screen and (min-width: @screen-sm) {
                font-size:   @font-size-medium;
            }
        }
    }

    .answer {
        .media-body {
            &:after {
                border-top: 1px solid @black;
                content: ' ';
                display: block;
                margin-top: 15px;
                width: 65px;
            }
        }
    }

    .media-image {
        width: 65px;
        padding-right: 0;
        vertical-align: top;

        @media only screen and (min-width: @screen-sm) {
            width: 110px;
        }
    }

    .letter {
        font-size: @font-size-extra-large;
        line-height: 4.25rem;

        @media only screen and (min-width: @screen-sm) {
            font-size: 5.25rem;
            font-weight: @lighter;
            line-height: 4.25rem;
        }
    }
}