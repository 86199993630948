//
// Close icons
// --------------------------------------------------


.close {
    float: right;
    font-size: (@font-size-base * 1.5);
    font-weight: @close-font-weight;
    line-height: 1;
    color: @close-color;
    text-shadow: @close-text-shadow;
    .opacity(.2);

    &:hover,
    &:focus {
        color: @close-color;
        text-decoration: none;
        cursor: pointer;
        .opacity(.5);
    }

    &.close-information {
        float: none;
        margin-left: 40px;
        font-weight: @close-information-font-weight;
        color: @close-information-color;
        text-shadow: @close-information-text-shadow;
        .opacity(.5);

        &:hover,
        &:focus {
            color: @close-information-color;
            .opacity(.8);
        }
    }

    // Additional properties for button version
    // iOS requires the button element instead of an anchor tag.
    // If you want the anchor version, it requires `href="#"`.
    button& {
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}
