// Styles for Gigya ui components.
// This fucking and unreadable mess was written by Iaky: https://www.iaki.it/
#registration-template{
	
	@media only screen and (min-width: 768px){
	    .gigya-error{
		      padding: 5px;
			  border-radius: 5px;
			  border: 1px solid #DD4B39 !important;
			  -webkit-box-shadow: none !important;
			  -moz-box-shadow: none !important;
			  box-shadow: none !important;
	    }
	    .gigya-row-error{ 
		    
			background: transparent url('../img/alert.png') no-repeat scroll 232px 218px;
			background-size: 33px;
	    }
	    .gigya-cell-error{ 
		    background: transparent url('../img/alert.png') no-repeat scroll 38px 78px;
			background-size: 7%;
	    }
	    .sesso{
		    .gigya-cell-error:first-child{  
			    background: transparent url('../img/alert.png') no-repeat scroll 38px 110px;
				background-size: 7%;
		    }
		}
	}
	.gigya-screen input[type=checkbox]{
		width: auto !important;
	}
	
	#gigya-update-profile-screen {
		.gigya-input-submit{
			color: #e0007a !important;
		}
	}
	#gigya-forgot-password-screen h2{
		display: none;
	}
	#gigya-thank-you-screen{
		h1{
			font-family: 'Abril Fatface', cursive !important;
		}
		label a{
			color: #9e9e9f;
			text-decoration: underline;
		}
	}
	h1.gigya-screen-caption{
		display: none;
	}
	.trattamenti_row{
		img{
			padding-left: 50px;
		}
		.under_checkbox{			
			float: left;
			padding-top: 0;
			font-size: 12px;
			line-height: 14px;
			color: #929292;
		}
		.gigya-composite-control-checkbox label{			
			float: left;
			width: 90%;
			text-indent: inherit;
		}
	    .gigya-layout-cell{
		    padding-left: 20px;
		    background: none;
		    h2, p{
			    padding-left: 50px !important;
		    }
			.gigya-composite-control-checkbox{
				padding-left: 60px;
			}
			@media only screen and (min-width: 768px){
				.gigya-composite-control-checkbox.gigya-cell-error{
				    background: transparent url('../img/alert.png') no-repeat scroll 20px 2px;
					background-size: 7%;
					float: left;
					width: 100%;
				}
			}
	    }
	    h2{
		    line-height: 25px;
		    padding-left: 10px !important;
			font-size: 1.45rem;
	    }
	}
	.gigya-reset-password-form{
		.gigya-row-error{
			background: transparent url('../img/alert.png') no-repeat scroll 59px 164px;
			background-size: 33px;
		}
		.gigya-composite-control.gigya-composite-control-textbox{
			width: 80% !important;
		}
	}
	@media only screen and (max-width: 767px){	    h1{
	        line-height: 3.75rem;
	    }
		#registerform {
	        width: auto !important;
	        margin: 6em 1.2em !important;
	    }
	    
		.trattamenti_row{
			img{
				padding-left: 0 !important;
				max-width: 100%;
			}
		}
	    #reset-password-screen .gigya-layout-cell,
	    #gigya-complete-registration-screen .gigya-layout-cell,
	    #registerform .gigya-screen .gigya-layout-cell {
	        width: 100% !important;
	        padding: 30px 20px 40px !important;
	    }
	    
		 #reset_password .gigya-screen #reset-password-screen .gigya-composite-control{
			padding: 6px 13px 5px 13px !important;
			width: 100%;
		}
	
	    #registerform .gigya-screen .gigya-layout-cell .gigya-label span {
	        font-size: 0.85rem;
	    }
	    #gigya-change-password-screen .gigya-composite-control {
	        width: 100% !important;
	        margin: 30px 0 !important;
	    }
	
	    .gigya-reset-password-form .gigya-layout-row{
	   		.gigya-composite-control.gigya-composite-control-textbox {
		        width: 100% !important;
		        float: none;
		        margin: 30px auto 50px;
		        padding: 0 20px !important;
		        box-sizing: border-box;
		    }
		    .gigya-composite-control.gigya-composite-control-submit {
		        padding: 0 20px !important;
		        box-sizing: border-box;
			    input[type="submit"]{
				    width: 100% !important;
				    color: #000;
			    }
		    }
	    }
	
	    .gigya-profile-form{
		     .gigya-layout-cell {
		        padding: 5px !important;
		        width:100% !important;
		        
				input {
			        display: block;
			        border: 0;
// 			        height: 40px !important;
			        line-height: 40px;
			        color: #000000;
			        border-bottom: 1px solid #000000;
// 			        background: none;
			        font-family: @font-family-sans-serif;
			        font-size: 1.25rem;
			        width: 100%;
			        outline: none;
			        -moz-box-sizing: border-box;
			        -webkit-box-sizing: border-box;
			        box-sizing: border-box;
			    }
		    }
		}
	    .gigya-input-submit,
	    .gigya-screen{
		    input[type="submit"]{
			    color: #000;
		    }
		 	max-width: 100%;   
		}
		
		.trattamenti_row .gigya-layout-cell{
			.gigya-composite-control-checkbox label{
				width: 80% !important;	
			}
			.gigya-composite-control-checkbox, h2, p{
				padding-left: 10px !important;
				float: left;
				width: 100%;
			}
		}
	}
	
	
	h1{
	    text-align: center !important;
	    font-family:@font-family-cursive !important;
	    font-size: 3.36rem !important;
	    font-weight: 300 !important;
	    margin: 0 !important;
	}
	.subtitle_error{
		font-size: 20px;
		line-height: 16px;
		@media only screen and (min-width: 768px){
			background: transparent url('../img/alert.png') no-repeat scroll 734px 30px;
			background-size: 2.2%;
		}
		.list_field_error{
			font-size: 12px;
			color: red;
			font-weight: 100;
		}
	}
	#registerform{
	    margin: 1.5em auto;
	    width: 960px;
	    height: 100%;
	    
	    #gigya-thank-you-screen {
		    .gigya-composite-control{
			    max-width: 100%;
		    }
		    .gigya-composite-control-label{
			    width: 100%;
			    text-align: center;
		    }
		    .form_section{
			    padding: 0 20px;
			    width: 100%;
		    }
		}
		.opacity_bg{
		    display: none;
		}
	}
	
	#registerform_content_caption{
	    display: none;
	}
	
	#registerform #gigya-thank-you-screen .form_section{
	    border: 1px solid #d0d0d0;
	    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	    background: #fff;
	    float: left;
	    margin-bottom: 3em;
	}
	
	.lost-passw form .gigya-label{
	    padding-bottom: 0 !important;
	}
	
	.lost-passw .title_section{
	    margin: 5em auto 1em;
	}
	
	.confirm.register label,
	.lost-passw label{
	    font-family: @font-family-sans-serif;
	    line-height: 1.7rem;
	    font-size: 1.275rem !important;
	    font-weight: 300 !important;
	    width: 850px;
	    color: #adacac !important;
	    padding: 0 0 1em;
	    margin: 0 auto !important;
	}
	
	.lost-passw #componentDiv_content{
	    margin: 0 auto !important;
	}
	
	#reset_password{
	    margin: 3em 0;
		.subtitle {
		    text-align: center;
		    font-family: @font-family-sans-serif;
		    line-height: 1.7rem;
		    font-size: 1.445rem;
		    color: #adacac;
		    font-weight: 300;
		    padding: 1em !important;
		}
		#reset-password-screen{
			width: 98% !important;
			float: none !important;
			margin: 2em auto !important;
			
			.gigya-composite-control{
			    width: 560px;
			    float: none;
			    margin: 30px auto 50px;
			    padding: 0 !important;
			}
		
			.gigya-composite-control-label{
		    	font-weight: 500 !important;    
			}
		}
	}

	.gigya-error-msg-email,
	.gigya-error-msg-avatar{
		padding-top: 5px;
		color: red !important;
		width: 100%;
		float: left;
		a{
			color: red !important;
			text-decoration: underline;
		}
	}
    .gigya-status-msg{
        color: green;
        font-size: 0.95rem;
        text-align: center;
        margin: 0 0 15px;
        font-family: @font-family-serif;
    }
	figure {
		position: relative;
		overflow: hidden;
	
		#add-avatar {
			position: relative;
			background-size: 100%;
			width: 90px;
			height: 90px;
			background-color: @black;
			display: block;
			border-radius: 50%;
			text-align: center;
			line-height: 90px;
			color: @white;
			font-size: @font-size-h3;
			.icon{
				line-height: 90px;
			}
		}
	
		figcaption {
			position: absolute;
			display: block;
			height: 100%;
			top: 0;
			left: 110px;
			z-index: 0;
	
			.content {
				position: relative;
				display: table;
				height: 100%;
	
				.v-align {
					position: relative;
					display: table-cell;
					vertical-align: middle;
					h6{
						display: block;
						font-weight: 600;
						font-size: 0.85rem;
						font-family: 'Open Sans', Helvetica, sans-serif;
						color: #e56d79;
						text-transform: uppercase;
						margin-bottom: 0;
						margin-top: 23px;
					}
					p {
						font-size: 15px;
						font-family: 'Open Sans', Helvetica, sans-serif;
						color: #9e9e9f;
					}
				}
			}
		}
		::-webkit-file-upload-button { cursor:pointer; }
		input.avatarUpload {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100% !important;
			width: 100%;
			line-height: 23px;
			opacity: 0;
			z-index: 99;
			overflow: hidden;
			cursor: pointer;
		}
	}

}

#login-btn-wrapper, 
#login-modal {
	
	#gigya-email-verification-screen{
		width: 100% !important;
		vertical-align: middle;
		position: relative;
		.gigya-composite-control.gigya-composite-control-label br{
			display: none;
		}
		.opacity_bg{
			display: block !important;
		}
	}
	
	.gigya-screen input[type=checkbox]{
		width: auto !important;
	}
	.gigya-error-msg-avatar{
		padding-top: 5px;
		color: red !important;
	}
	.gigya-reset-password-form{
		input[type="submit"]{
			color: #e0007a !important;
		}
	}
	.trattamenti_row{
		img{
			padding-left: 50px;
		}
		.under_checkbox{			
			float: left;
			padding-top: 0;
			font-size: 12px;
			line-height: 14px;
			color: #929292;
		}
		.gigya-composite-control-checkbox label{			
			float: left;
			width: 90%;
			text-indent: inherit;
		}
		
	    .gigya-layout-cell{
		    padding-left: 20px;
		    background: none;
		    h2, p{
			    padding-left: 50px !important;
		    }
			.gigya-composite-control-checkbox{
				padding-left: 60px;
			}
			.gigya-composite-control-checkbox.gigya-cell-error{
				@media only screen and (min-width: 768px){
				    background: transparent url('../img/alert.png') no-repeat scroll 20px 2px;
					background-size: 7%;
				}
				float: left;
				width: 100%;
			}
	    }
	    h2{
		    line-height: 25px;
		    padding-left: 10px !important;
			font-size: 1.45rem;
	    }
	}
	
	@media only screen and (min-width: 768px){
	    .gigya-row-error{ 
		    
			background: transparent url('../img/alert.png') no-repeat scroll 172px 16px;
			background-size: 33px;
	    }
	    .gigya-cell-error{  
		    background: transparent url('../img/alert.png') no-repeat scroll 38px 78px;
			background-size: 7%;
			.gigya-composite-control-checkbox{
			    background: transparent url('../img/alert.png') no-repeat scroll 38px 72px;
				background-size: 7%;
			}
	    }
	    .sesso{
		    .gigya-cell-error:first-child{  
			    background: transparent url('../img/alert.png') no-repeat scroll 38px 110px;
				background-size: 7%;
		    }
		}
	}
	.subtitle_error{
		font-size: 20px;
		line-height: 16px;
		@media only screen and (min-width: 768px){
			background: transparent url('../img/alert.png') no-repeat scroll 674px 30px;
			background-size: 2.2%;
		}
		.list_field_error{
			font-size: 12px;
			color: red;
			font-weight: 100;
		}
	}
    h1{
        text-align: center !important;
        font-family:@font-family-cursive !important;
        font-size: 3.36rem !important;
        font-weight: 300 !important;
        margin: 0 !important;
    }
    
    div.gigya-screen-dialog-caption{
        display: none;
    }

    .gigya-required-display{
        display: none !important;
    }

    div.gigya-screen-dialog-top{
        background: none !important;
        position: absolute !important;
        right: 0;
    }
    
    div.gigya-screen-dialog{
        border: 0 !important;
    }

    div.gigya-screen-dialog-content{
        padding: 0 !important;
        width: 840px !important;
        min-height: 0 !important;
    }
    
    .gigya-composite-control-password{
        position: relative;
    }

    .gigya-status-msg{
        color: green;
        font-size: 0.95rem;
        text-align: center;
        margin: 0 0 15px;
        font-family: @font-family-serif;
    }


	@media only screen and (max-width: @screen-md){
		.gigya-reset-password-form{
			.gigya-composite-control.gigya-composite-control-textbox{
				padding: 0 0.5em !important;
				label,input{
					margin: 0;
					padding: 0;
					width: 100% !important;
				}
			}
			.gigya-composite-control-link,
			.gigya-composite-control-submit{
				box-sizing: border-box;
			}
		}


	    #change_pass_popup,
	    #login_content{
	        border: 0 !important;
	        border-radius: 0 !important;
	        left: 0 !important;
	        padding: 0 3% !important;
		}
	}
    #login_content{
        border: 0 !important;
        border-radius: 0 !important;
        left: 0 !important;
        padding: 0 3% !important;
        overflow: hidden !important;
		.transition(.35s, left);
	    #login_content_content{
			background: #fff;       
		}
    }

    #gigya-login-screen{
	     .close[type="button"]{
		    top: 0;
	        z-index: 9;
	        img{
		        float: left;
	        }
	    }
	    h2{
		    margin-top: 0.6em !important;
	    }
        .gigya-login-providers-list{
            width: 100%;
	        .gigya-login-provider-row{
               	width: 100%;   
			   	span[data-gigya-provider="facebook"],
		       	span[data-gigya-provider="twitter"],
		       	span[data-gigya-provider="googleplus"]{
			   		width: 33% !important;  
		       	} 
			}
        }
        .box-log{
	        .gigya-composite-control {
		        margin: 14px 23% 0 !important;
				width: 54%;
				& .gigya-composite-control-password {
					padding-bottom: 10px !important;
				}
				.gigya-composite-control-label {
					margin: 0 9% 10px 9% !important;
				}
			}
	        .gigya-login-providers-list{
	            width: 100%;
		        .gigya-login-provider-row{
					width: 62% !important;
					margin: 0 19% !important; 
				   	span[data-gigya-provider="facebook"],
			       	span[data-gigya-provider="twitter"],
			       	span[data-gigya-provider="googleplus"]{
				   		padding: 0.2em 0;
			       	} 
				}
	        }
        }
        .gigya-composite-control-social-login{	        
        	.gigya-social-login{
		        .gigya-login-providers-container{
	            	margin-left: 0 !important;
					margin-right: 0 !important;
				}
	       }
        }
        
    }
    #gigya-login-screen .tab-head ul.tab-nav li {
        position: relative;
        background-color: #f0f0f0;
        font-family: @font-family-sans-serif;
        text-transform: uppercase;
        color: #000000;
        font-size: 1.25rem;
        font-weight: 600;
        text-align: center;
        width: 50%;
        float: left;
        box-sizing: border-box;
        margin: 0;
        padding: 1em;
        line-height: 1.25;
        border-left: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0;
        cursor: pointer;
        -webkit-transition: 0.25s;
        -o-transition: 0.25s;
        transition: 0.25s;
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
    }
    #gigya-login-screen .tab-box .gigya-layout-cell  {
        background-color: #ffffff !important;
    }

    .tab-head {
        position: relative;
    }

    .tab-head ul.tab-nav {
        list-style: none;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .tab-box {
        position: relative;
        width: 200% !important;
        left: -100%;
        .transition(.35s, left);
    }
    .tab-box.toggle {
        left: 0;
    }
    div.gigya-screen-dialog-content{
        padding: 0 !important;
        max-width: 100% !important;
        min-height: 0 !important;
        overflow: hidden !important;
    }
    #gigya-login-screen .tab-head ul.tab-nav li.selected {
        background-color: #ffffff;
        border-bottom-color: #ffffff;
        color: #e0007a;
    }
	@media only screen and (max-width: 767px){
	    
		.trattamenti_row{
			img{
				padding-left: 0 !important;
				max-width: 100%;
			}
		}
	    .gigya-profile-form{
		    margin-bottom: 0 !important;
		    .gigya-layout-cell {
		        padding: 5px !important;
		        width:100% !important;
		    }
		}
	    #change_pass_popup{
	        border: 0 !important;
	        border-radius: 0 !important;
	        left: 0 !important;
	        padding: 0 3% !important;
	        overflow: hidden !important;
			.transition(.35s, left);		
			.gigya-composite-control {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}
	    }
	    h1{
	        line-height: 3.75rem;
	    }
	    .gigya-input-submit {
	        max-width:100% !important;
		    input[type="submit"]{
			    color: #000;
		    }
	    }
		
	    #gigya-email-verification-screen {
	        padding: 0 30px;
	    }

	    #gigya-thank-you-screen .gigya-composite-control,
	    #gigya-email-verification-screen .gigya-composite-control{
	        width: 100% !important;
	    }

	    div.gigya-screen-dialog-content{
	        padding: 0 !important;
	        max-width: 100% !important;
	        min-height: 0 !important;
	        overflow: hidden !important;
	    }
	    #gigya-login-screen .tab-head ul.tab-nav li.selected {
	        background-color: #ffffff;
	        border-bottom-color: #ffffff;
	        color: #e0007a;
	    }
	    #gigya-login-screen .tab-head ul.tab-nav li {
	        position: relative;
	        background-color: #f0f0f0;
	        font-family: @font-family-sans-serif;
	        text-transform: uppercase;
	        color: #000000;
	        font-size: 1.25rem;
	        font-weight: 600;
	        text-align: center;
	        width: 50%;
	        float: left;
	        box-sizing: border-box;
	        margin: 0;
	        padding: 1em;
	        line-height: 1.25;
	        border-left: 1px solid #d0d0d0;
	        border-bottom: 1px solid #d0d0d0;
	        cursor: pointer;
	        -webkit-transition: 0.25s;
	        -o-transition: 0.25s;
	        transition: 0.25s;
	        -webkit-transition: all 0.25s ease-in-out;
	        -moz-transition: all 0.25s ease-in-out;
	        -o-transition: all 0.25s ease-in-out;
	        transition: all 0.25s ease-in-out;
	    }
	    #gigya-login-screen .tab-box .gigya-layout-cell  {
	        background-color: #ffffff !important;
	        h3 br{
		        display: none;
	        }
	        &.box-new .gigya-login-provider-row{
		        width: 100% !important;
		        margin: 0 !important;
	        }
	        .gigya-composite-control{  
		        margin: 0 4% !important;
				padding: 6px 0 5px 0 !important;
				width: 92%;
				line-height: 44px;
	        }
	    }
	
	    .tab-head {
	        position: relative;
	    }
	
	    .tab-head ul.tab-nav {
	        list-style: none;
	        overflow: hidden;
	        margin: 0;
	        padding: 0;
	    }
	
	    .tab-box {
	        position: relative;
	        width: 200% !important;
	        left: -100%;
	        .transition(.35s, left);
	    }
	    .tab-box.toggle {
	        left: 0;
	    }
		.trattamenti_row .gigya-layout-cell{
			.gigya-composite-control-checkbox, h2, p{
				padding-left: 10px !important;
				float: left;
				width: 100%;
			}
		}
	}
	@media only screen and (min-width: 767px){
	    div.gigya-screen-dialog-content{
	        padding: 0 !important;
	        width: 840px !important;
	        min-height: 0 !important;
	    }
/*
	    #gigya-login-screen .tab-head {
	        display: none;
	    }
	    .tab-box {
	        margin: 0 auto;
	    }
*/
	}
	
	.site-footer {
	    background-color: #e8e5ce;
	    color: #686758;
	    font-size: 14px;
	    text-align: center;
	    float: left;
	    width: 100%;
	}
	
	@media only screen and (min-width: 992px) {
	    .tab-box {
	        width: 840px;
	    }
	}
	
	#login_content{
	    position: absolute;
	    display: none;
	    z-index: 999;
	    width: 100%;
	    height: 3000%;
		.tab-box .box-new .icon {
	        position: relative;
	        top: 3px;
	        left: 5px;
	   }
	}
	#change_pass_popup{
		
	    position: absolute;
	    display: none;
	    z-index: 999;
	    width: 100%;
	    height: 300%;
	}
	.opacity_bg{
	    background: rgba(0, 0, 0, 0.75) !important;
	    position: fixed;
	    width: 100% !important;
	    height: 100%;
	    top: 0;
	    left: 0;
	    z-index: -1;
	}
	
	#change_pass_popup_content_caption,
	#login_content_content_caption{
	    display: none;
	}
	
	#login_content_content{
// 	    max-width: 650px !important;
	    margin: 4% auto;	
	    .gigya-login-form{
		    overflow: hidden !important;	    
			float: left;
		}
	}
	
	#change_pass_popup_content{
// 		background: #fff;
//		max-width: 420px !important;
	    margin: 4% auto;
	    overflow: hidden !important;
/*
	    .gigya-reset-password-form{
		    border: 0;
		    box-shadow: none;
		    .gigya-composite-control.gigya-composite-control-textbox{
			    padding: 0 3em!important;
			    width: 100% !important;
			    box-sizing: border-box;
		    }
	    }
*/
	}
	
	#change_pass_popup_content{
		h2{
		    margin: 10px 0;
		    font-family: @font-family-cursive;
		    font-weight: 400;
		    text-align: center;
		    font-size: 2.15rem;
	    }
	    input, .gigya-label, .gigya-error-msg{
		    width: 50%;
		    margin-left: auto;
		    margin-right: auto;
	    }
	    .gigya-error{
		    border: 1px solid #DD4B39 !important;
	    }
	}
	
	.close[type="button"]{
	    position: absolute;		
		right: -30px;
		top: 4px;
	    opacity: 1;
	}
	
	.close[type="button"]:hover{
	    opacity: .5;
	}
	
	div.gigya-photo-upload-image{
	    border-radius: 32px;
	}
	
	#login_content_social_0_uiContainer{
	    width: 100% !important;
	}
	
    #gigya-login-screen{
	    h3{
		    font-size: 1.2rem;		    
			line-height: 24px;
			margin-top: 5px;
	    }
	    .box-new{
		    .foot_login p{
			    color: #a5a5a6;
				font-family: 'Open Sans', Helvetica, sans-serif;
			    text-align: center;
			    font-size: 14px;
			    margin: 52px 10% 0;
			    padding-top: 20px;
			    padding-bottom: 20px;
			    border-top: 1px solid #a5a5a6;
			    width: 80%;
			    a{
				    color: #a5a5a6 !important;
			    }
		    }
	        .gigya-login-providers-list{
		    	margin: 5.9em 0 3.4em !important;
		    }
	    }
        .gigya-login-providers-list{
			height: 100% !important;
			width: 100% !important;
			float: left !important;
            
            .gigya-login-provider-row{
				height: 100% !important;
				width: 50% !important;
				margin: 0 25%;
				float: left !important;
                
                span[data-gigya-provider="facebook"],
                span[data-gigya-provider="twitter"],
                span[data-gigya-provider="googleplus"] {
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    position: relative;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1; 
                    font-size: 2.8rem !important;
                    display: inline-block;
                    cursor: pointer;
                    -webkit-font-smoothing: antialiased;  
                    width: 33% !important;
                    float: left;
                    padding: 0.2em 0;
                    text-align: center;  
                }
                
                span[data-gigya-provider="facebook"]{

                    color: #2d4685 !important;
                }
                
                span[data-gigya-provider="facebook"]:before{
                    content: "\e629";
                }

                span[data-gigya-provider="twitter"]{
                    color: #239ece !important;
                }
                
                span[data-gigya-provider="twitter"]:before{
                    content: "\e639";

                }

                span[data-gigya-provider="googleplus"]{
                    color: #d33826 !important;
                }
                span[data-gigya-provider="googleplus"]:before{
                    content: "\e62c";

                }
            }
        }
        
        .box-log { 
            .gigya-composite-control-password,
            .gigya-composite-control-textbox {
				position: relative;
				float: left;
				padding-bottom: 15px !important;
			}
            .gigya-composite-control-social-login{
                .gigya-login-providers-list{
                    width: 100%;
                    
                    .gigya-login-provider-row{
                        width: 100%;   
                        span[data-gigya-provider="facebook"],
                        span[data-gigya-provider="twitter"],
                        span[data-gigya-provider="googleplus"] {
                            padding: 0.2em 0;
                        } 
                    }
                }
            }
        }
    }
	figure {
		position: relative;
		overflow: hidden;
	
		#add-avatar {
			position: relative;
			background-size: 100%;
			width: 90px;
			height: 90px;
			background-color: @black;
			display: block;
			border-radius: 50%;
			text-align: center;
			line-height: 90px;
			color: @white;
			font-size: @font-size-h3;
			.icon{
				line-height: 90px;
			}
		}
	
		figcaption {
			position: absolute;
			display: block;
			height: 100%;
			top: 0;
			left: 110px;
			z-index: 0;
	
			.content {
				position: relative;
				display: table;
				height: 100%;
	
				.v-align {
					position: relative;
					display: table-cell;
					vertical-align: middle;
					h6{
						display: block;
						font-weight: 600;
						font-size: 0.85rem;
						font-family: 'Open Sans', Helvetica, sans-serif;
						color: #e56d79;
						text-transform: uppercase;
						margin-bottom: 0;
						margin-top: 23px;
					}
					p {
						font-size: 15px;
						font-family: 'Open Sans', Helvetica, sans-serif;
						color: #9e9e9f;
					}
				}
			}
		}
		::-webkit-file-upload-button { cursor:pointer; }
		input.avatarUpload {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100% !important;
			width: 100%;
			line-height: 23px;
			opacity: 0;
			z-index: 99;
			overflow: hidden;
			cursor: pointer;
		}
	}
}

.nib {
    position: relative !important;
    width: 100% !important;
    float: left !important;
    text-align: center !important;
    font-size: 1.7rem !important;
    line-height: 1em !important;
    color: #d0d0d0 !important;
}

.show_password,
.modify_password{
    position: absolute;
    right: 13px;
    top: 39px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    background-color: #000000;
    padding: .3em 1em;
    z-index: 8;
    letter-spacing: 1px;
    text-decoration: none;
    color: #ffffff !important;
}

.disabled{
    cursor: default;
    color: #ccc !important;
    background: none !important;
    display: none !important;
}

#gigya-thank-you-screen .gigya-composite-control,
#gigya-email-verification-screen .gigya-composite-control {
    width: 560px;
    float: none;
    margin: 30px auto 50px;
    padding: 0 !important;
}

#gigya-thank-you-screen .gigya-composite-control-label,
#gigya-email-verification-screen .gigya-composite-control-label {
    font-weight: 500 !important;    
}

.gigya-screen .gigya-form-error-msg{
    visibility: visible !important;
    background: none !important;
}

.gigya-composite-control-password{
    position: relative;
}


.gigya-composite-control{
	.subfield {
		&.subscribe_on{
			display: none;
		}
		line-height: 2em;
		width: 100%;
		float: left;
		font-size: 0.65rem !important;
	}
	.small-choice{
		width: auto;
		float: left;
		padding-right: 15px;
		.text_icon{
			float: left;
			font-weight: 100;
			text-transform: lowercase;
		}
	}
}

.gigya-required-display{
    display: none !important;
}
@media only screen and (max-width: 767px){
    .gig-balloon{
	    display: none;
    }
    #login-btn .dropdown-toggle {
        width: auto !important;
    }
    #comments {
        max-width: 94%;
    }
    #componentDiv{      
        max-width: 96%;
        margin: 0 auto; 
    }
}

#registerform_bubble_content{
	max-width: 100%;
}


.noUi-target{
	margin: 20px 40px;
	background-color: #bbb;
	box-shadow: none;
	border-radius: 0;
	border: 0;
}

.noUi-horizontal {
	height: 7px;
	
	.noUi-handle {
		width: 14px;
		height: 19px;
		left: -7px;
		top: -6px;
		box-shadow: none;
		border-radius: 0;
		border: 0;
		background-color: @black;

		&:after, &:before{
			display: none;
		}
	}
}
/* Cropper box */
.cropper{
	position: relative;
	height: 280px;
	width: 280px;
	overflow: hidden;
	background-repeat: no-repeat;
	cursor:move;
	margin: 0 auto 1.5em;

	@media only screen and (min-width: @screen-xs) {
		& {
			height: 300px;
			width: 360px;
		}
	}

	.thumbBox{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 296px;
		height: 296px;
		margin-top: -148px;
		margin-left: -148px;
		box-sizing: border-box;
		box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
		background: none repeat scroll 0 0 transparent;
		border-radius: 50%;
	}

	.spinner{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		line-height: 400px;
		background: rgba(0,0,0,0.7);
	}
}

#avatarCropper {
	h3 {
		padding: .5em 0;
		text-align: center;
		font-family: 'Open Sans', Helvetica, sans-serif;
	}
}
.cropper-controller {
	position: relative;
	margin: 0 2em;
	height: 18px;

	input{
		position: absolute;
		top: -12px !important;
		border: 0 !important;
		outline: 0 !important;
		background-color: transparent !important;
		padding: 0 !important;
		max-height: 24px  !important;
		line-height: 24px  !important;
		width: 30px !important;
		color: #9e9e9f !important;

		font-size: @font-size-h2 !important;
		font-weight: @bold;

		&#btnZoomIn{
			right: 0;
		}
		&#btnZoomOut{
			left: 0;
		}
	}
}

.modal {
	//display: block;
	background-color: rgba(0,0,0,.75);
	padding: 0 !important;

	.form-modal {
		position: relative;
		display: table;
		width: 100%;
		height: 100%;

		.v-align {
			display: table-cell;
			vertical-align: middle;
			
			hr{
				margin-top: 14px;
				margin-bottom: 14px;
			}
			.box {
				background-color: @light-gray;
				margin: 0 20px;
				position: relative;
				overflow: hidden;
				width: auto;

				@media only screen and (min-width: @screen-xs) {
					& {
						width: 420px;
						margin: 1em auto;
					}
				}

				.nib {
					position: relative;
					width: 100%;
					text-align: center;
					font-size: @font-size-h3;
					line-height: 1em;
					color : @gray;
				}

				h3 {
					width: 100% !important;
				}

				.form-wrap {
					width: 100%;
					background: none;
					border: 0;
					margin: 0;
					box-shadow: none;

					.form-field {
						width: auto;
						margin: 30px 40px;

						@media only screen and (min-width: @screen-xs) {
							& {
								width: 340px;
							}
						}

					}
				}

				.btn {
					width: 180px;
				}

				#actions {
					position: relative;
					overflow: hidden;
					clear: both;
					width: auto;
					margin: 0 3em;

					@media only screen and (min-width: @screen-xs) {
						& {
							width: 360px;
							margin: 1em auto;
						}
					}

					.btn {
						position: relative;
						display: block;
						padding: 10px 20px;
						margin: .25em auto;
						color: #000000;

						&.btn-bordered {
							border-color: @pink;
						}
					}
				}

				.close {
					position: absolute;
					top: 0;
					right: 0;
					padding: .25em;
					opacity: 1;

					&:hover {
						opacity: .5;
					}
				}
			}	
		}

		h2 {
			margin: 10px 0;
			font-family: @font-family-cursive;
			font-weight: @lighter;
			text-align: center;
		}
	}
}

div.gigya-screen-dialog{
	border: 0 !important;
	border-radius: 0 !important;
	div.gigya-screen-dialog-inner{
		border: 0;
		div.gigya-screen-dialog-top{
			display: none;
		}
	}
	.gigya-screen-content{
		padding: 0 !important;
		min-height: 0 !important;
	}
}

#gigya-link-account-screen{
	margin: 0 !important;
	h1{
		text-align: center;
		font-family: 'Oswald',sans-serif;
	}
	.gigya-layout-cell{
		padding-bottom: 20px;
		padding-top: 0;
	}
	.gigya-input-submit{
		padding: 14px 20px!important;
		margin: 0 auto 0!important;
		color: #e56d79!important;
		position: relative;
		display: block;
		text-transform: uppercase;
		letter-spacing: 2px;
		height: auto!important;
		font-size: .85rem;
		line-height: 1.42857143;
		font-weight: 600;
		border: 2px solid #e56d79;
		width: 100%!important;
	}	
	.opacity_bg{
	    background: rgba(0, 0, 0, 0.75) !important;
	    position: fixed;
	    width: 100% !important;
	    height: 100%;
	    top: 0;
	    left: 0;
	    z-index: -1;
	}
}