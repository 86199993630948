.label {
    border-radius: 0;
    display: inline-block;
    font-family: @font-family-cursive;
    font-size: @font-size-small;
    font-weight: @light;
    line-height: inherit;
    text-transform: uppercase;

    &.label-black {
        background-color: @black;
    }
}