.breadcrumb {
    .reset-list;
    background-color: transparent;
    border-radius: 0;
    color: @middle-gray;
    
    > li {
        display: inline-block;
        font-family: @font-family-serif;
        font-size: @font-size-extra-small;
        font-style: italic;
        font-weight: @light;
        padding: 0 2px 0 0;
        vertical-align: top;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-small;
            padding: 0 4px 0 0;
        }

        &:before {
            border-left: 1px solid @gray;
            content: ' ';
            display: inline-block;
            height: 12px;
            padding: 0 2px;

            @media only screen and (min-width: @screen-sm) {
                padding: 0 4px;
            }
        }
        
        &:first-child {
            
        }

        &:first-child:before {
            border-left: none;
            padding: 0;
        }

        &.active {
            color: @middle-gray;
        }

        a {
            color: @middle-gray;
        	font-family: @font-family-serif;
        }
    }

    .time {
        font-style: italic;
    }
}