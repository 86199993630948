.select-box {
    background         : transparent url('https://static.glamour.it/static/img/icons/arrow-down.svg') no-repeat 95% center;
    background-size    : 20px;
    border             : none;
    border-bottom      : 1px solid @gray;
    border-radius      : 0;
    border-top         : 1px solid @gray;
    color              : black;
    height             : 60px;
    padding            : 10px;
    width              : 100%;
    -webkit-appearance : none;

    &:focus {
       box-shadow: none;
       outline: none;
    }
}