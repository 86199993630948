#header {
    @height : 55px;
    @line-height : 45px;
    @desktop-height : 77px;
    @desktop-line-height : 70px;
    .transition(all, 0.6s);
    background-color : @white;
    border-bottom    : 1px solid;
    color            : @gray;
    height           : @height;
    line-height      : @height;
    position         : fixed;
    width            : 100%;
    z-index          : 9;

    @media only screen and (min-width : @screen-sm) {
        height      : @desktop-height;
        line-height : @desktop-line-height;
    }

    #logo {
        height     : @height;
        margin     : 0;
        text-align : center;

        @media only screen and (min-width : @screen-sm) {
            height : @desktop-height;
        }
    }

    #nav-btn,
    #login-btn {
        font-size   : 32px;
        height      : @height;
        line-height : @line-height;
        overflow    : visible;
        position    : absolute;
        text-align  : center;
        top         : 0;
        width       : @height;

        .logged-out {
            height   : 53px;
            overflow : hidden;
        }

        @media only screen and (min-width : @screen-sm) {
            height      : @desktop-height;
            line-height : @desktop-line-height;
            width       : @desktop-height;

            .logged-out {
                height : 76px;
            }
        }

        .btn-text {
            display        : block;
            font-size      : 11px;
            font-weight    : @bold;
            position       : relative;
            text-transform : uppercase;
        }

        &:hover .btn-text {
            color  : @black;
            cursor : pointer;
        }
    }

    #nav-btn {
        border-right : 1px solid;
        left         : 0;

        .btn-text {
            top : -20px;
        }
    }

    #login-accedi {
        border-left  : 1px solid;
        position     : absolute;
        right        : 65px;
        top          : 0;
        padding-left : 15px;
        height: @height;
        .transition(all, 0.3s);

        @media only screen and (min-width : @screen-sm) {
            height      : @desktop-height;
        }

        a {
            font-family : @font-family-cursive;
            font-style  : normal;
            display : inline-block;

            small{
                font-style: normal;
                font-size: 11px;
                @media only screen and (min-width : @screen-md) {
                    font-size: 0.875rem;
                }
            }
        }
    }

    #login-btn {
        position : absolute;
        right    : 0;
        cursor: pointer;

        &.open {
            .dropdown-menu {
                display : block!important;
            }
        }

        .dropdown-toggle {
            cursor     : pointer;
            height     : 75px;
            position   : relative;
            text-align : center;
            width      : 75px;

            .avatar {
                border-radius : 50%;
                height        : 40px;
                position      : relative;
                width         : 40px;

                img {
                    border-radius : 50%;
                }
            }
        }

        .icon-human {
            color     : @gray;
            font-size : 23px;
        }

        .btn-text {
            color : @gray;
            top   : -25px;

            @media only screen and (min-width : @screen-sm) {
                top : -45px;
            }
        }
    }

    .dropdown-menu {
        background-color : @white;
        border           : 1px solid @gray;
        border-radius    : 0;
        box-shadow       : 0 6px 12px rgba(0, 0, 0, 0.175);
        display          : none;
        left             : -84px;
        margin           : 1px 0 0;
        min-width        : 160px;
        padding          : 5px 0;
        position         : absolute;
        z-index          : 1000;

        &:before {
            border-bottom : 6px solid @gray;
            border-left   : 6px solid transparent;
            border-right  : 6px solid transparent;
            content       : ' ';
            left          : 71%;
            position      : absolute;
            top           : -5px;
            z-index       : 1;
        }

        &:after {
            border-bottom : 4px solid @white;
            border-left   : 4px solid transparent;
            border-right  : 4px solid transparent;
            content       : ' ';
            left          : 72%;
            position      : absolute;
            top           : -3px;
            z-index       : 2;
        }
    }

    #icon-menu-wrapper {
        .rotateY(0);

        .icon-menu {
            display   : block;
            font-size : 32px;
            left      : -3px;
            position  : relative;
            top       : 10px;

            @media only screen and (min-width : @screen-sm) {
                font-size : 41px;
                left      : -7px;
            }
        }
        .transition(all, 0.6s);
    }

    body.menu-open & #icon-menu-wrapper {
        .rotateY(180deg);
        backface-visibility : visible;
    }

    .left {
        left : 65px;

        @media only screen and (min-width : @screen-sm) {
            left : 105px;
        }
    }

    .right {
        right : 65px;

        @media only screen and (min-width : @screen-sm) {
            right : 185px;
        }
        @media only screen and (min-width : @screen-md) {
            right : 215px;
        }
    }

    #nav-topic,
    #beauty-lovers {
        display  : inline-block;
        position : absolute;
        top      : 0;
        &:extend(.left);
        &:extend(.right);

        &.nologin{
            right : 85px!important;
            border-right: 1px solid;
            padding-right: 20px;
        }

        a {
            font-family : @font-family-cursive;
            font-style  : normal;
            small{
                font-size: 11px;
                @media only screen and (min-width : @screen-md) {
                    font-size: 0.875rem;
                }
            }

        }

        .topic-link {
            font-family : @font-family-serif;
            font-style  : italic;
            font-weight : @lighter;
            padding     : 0 2px 0 2px;
        }
    }

    #nav-topic {
        max-width     : 230px;
        overflow      : hidden;
        text-overflow : ellipsis;
        white-space   : nowrap;

        ul {
            list-style-type : none;
            margin          : 0;
            overflow        : hidden;
            padding         : 0;
            text-overflow   : ellipsis;
            white-space     : nowrap;
        }

        li {
            display : inline-block;
        }
    }

    &.international {
        margin-top : 35px;
    }
}
