.media {
    border-bottom: 1px solid @gray;
    display: table;
    margin: 25px 0 0;
    overflow: hidden;
    padding: 0 0 25px;
    position: relative;
    width: 100%;

    @media only screen and (min-width: @screen-sm) {
        margin: 35px 0 0;
        padding: 0 0 35px;
    }

    &:last-of-type {
        border: none;
    }

    &.media-loader {
        height: 200px;
        margin: 0;
        padding: 0;
    }

    &.media-topic{
        padding-bottom: 15px;
        .content a{
            color: #9e9e9f;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 650px !important;
            display: block;
        }
        .followTopic{
            position:absolute;
            top:13px;right: 0;
        }
    }

    &.media-brandmag{
        background: black;
        color: white;
        padding:15px 155px 15px 15px;
        margin-top:1px;
        .brandMag{
            margin-right:45px;
        }
        h3 {
            color: #ffffff;
            font-family: 'Oswald', sans-serif;
            margin: 0;
            font-size: 1.37rem;
            font-weight: 300;
            position: relative;
            text-transform: uppercase;
        }

        .media-body{
            vertical-align: middle;

            p{
                margin:0;
            }
        }

        .content a{
            color:#737375;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 75px;
        }

        .counter{
            position: absolute;
            top: 5px;
            right: 0;
            color: white;
            width: 130px;
            text-align: center;
            height: 75px;
            padding-top: 12px;
            margin-top: 20px;
            font-family: 'Oswald', sans-serif;
            font-style: normal;
            font-size:36px;
            line-height: 0.6;

            span{
                font-size:14px;
            }
        }
    }

    &.media-reporter figure a{
        width:145px;height: 145px;
        border-radius: 145px;
        margin:15px 75px;
    }

    &.media-reporter header h2{
        margin:0 0 25px 0 !important;
    }

    &.media-reporter header p{
        font-size:18px;
        color:#9e9e9f;
        font-style: italic;
        margin:0 0 25px 0;
    }

    .media-image {
        text-align: center;

        &,
        &-sm {
            max-width: 110px;
            width: 110px;

            > img {
                max-width: 110px;
            }
        }

        @media only screen and (min-width: @screen-sm) {
            &-md {
                max-width: 165px;
                width: 165px;

                > img {
                    max-width: 165px;
                }
            }

            &-lg {
                max-width: 220px;
                width: 220px;

                > img {
                    max-width: 220px;
                }
            }
        }

        > img.round {
            max-width: none;
        }
    }
    
    .media-icon-social > .rounded-social {
        border: 1px solid @black;
        font-size: 32px;
        color: @black;
        padding: 8px;
        line-height: 32px;
        text-align: center;
        
        @media only screen and (min-width: @screen-sm) {
            font-size: 42px;
            line-height: 62px;
        }
    }

    .media-image + .media-body {
        .media-footer {
            bottom: 25px;
            left: 0;
            padding: 0 0 0 120px;
            position: absolute;
            width: 100%;

            @media only screen and (min-width: @screen-sm) {
                bottom: 35px;
                height: 35px;
                padding: 0 0 0 230px;
            }

            .btn-preferred.visible-xs {
                position: absolute;
                right: 0;
            }
        }
    }

    .media-date {
        color: @black;
        padding: 0 10px 0 0;
        text-align: center;
        width: 50px;

        @media only screen and (min-width: @screen-sm) {
            padding: 0 40px 0 0;
            width: 100px;
        }

        .media-date-wrapper {
            .vertical-align;
        }

        .day {
            font-size: @font-size-large;
            font-weight: @lighter;
            padding: 0 0 5px;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-h2;
                margin-top: 30px;
            }
        }

        .month {
            border-top: 1px solid;
            padding: 10px 0 0;
            text-transform: uppercase;
        }
    }

    .media-date + .media-image {
        max-width: 100px;
        width: 100px;

        @media only screen and (min-width: @screen-sm) {
            max-width: 120px;
            width: 120px;
        }

        > img {
            max-width: 100px;

            @media only screen and (min-width: @screen-sm) {
                max-width: 120px;
                width: 120px;
            }
        }
    }

    .media-heading {
        font-weight: @lighter;
        margin: 0 0 5px;
    }

    h2 {
        &:extend(.media-heading);
        font-size: @font-size-base;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-h2;
        }
    }

    h3, h4, h5, h6 {
        &:extend(.media-heading);
    }

    .media-body {
        color: @black;
        padding-left: 10px;

        .breadcrumb {
            display: inline-block;
            font-size: @font-size-small;
            vertical-align: top;

            li {
                display: none;

                &:first-of-type,
                &:nth-of-type(2) {
                    display: inline-block;
                }

                @media only screen and (min-width: @screen-sm) {
                    display: inline-block;
                }
            }
        }

        header .btn-preferred {
            position: absolute;
            right: 25px;
            top: -8px;
        }

        p {
            &.bg-white {
                font-size: @font-size-base;
                margin: 10px 0 0;
                padding: 15px;

                @media only screen and (min-width: @screen-sm) {
                    padding: 25px;
                }
            }
        }
    }

    .media-time {
        color: @middle-gray;
        display: inline-block;
        font-family: @font-family-serif;
        font-style: italic;
        vertical-align: top;
    }

    .media-more {
        text-align: right;
        text-transform: uppercase;
        vertical-align: middle;

        a {
            font-size: @font-size-extra-small;
            letter-spacing: 2px;
        }
    }
}

.media-list-connect-social {
    padding-top: 25px;
    
    .media {
        min-height: 80px;
    }
    
    .media-icon-social { 
        width: 70px; 
        @media only screen and (min-width: @screen-sm) {
            width: 100px; 
        }
    }
    
    .media-body {
        p {
            font-size: @font-size-base;
            margin: 0;
            padding: 0;
            
            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-large;
            }
        }
    }
    
    .media-btn-connect { 
        @media only screen and (min-width: @screen-sm) {
            width: 265px; 
        }
    }
}

.media-list-followers & {
    margin: 0 0 0;
    padding: 15px 0;

    .media {
        margin: 10px 0 0;
        padding: 10px 0;
    }

    .media-image-small {
        max-width: 100px;
        width: 100px;
    }

    .media-heading {
        font-family: @font-family-sans-serif;
        font-size: @font-size-small;
        font-weight: @bolder;
        margin-bottom: 5px;

        small {
            color: @middle-gray;
        }
    }

    .media-links {
        .reset-list;

        li {
            display: inline;
        }
    }

    p {
        font-family: @font-family-sans-serif;
        font-size: @font-size-small;
        font-weight: @lighter;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-base;
        }
    }
}