.switcher {
    
    /* background-color: lime; */
    font-weight: @light;
    
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
    }
    input[type="checkbox"].switch + div {
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
        width: 96px;
        height: 40px;
        border: 1px solid @profile-light-gray;
        border-radius: 999px;
        margin: 0;
        background: @white;
        overflow: hidden;
        transition-duration: 0.4s;
        transition-property: padding, width, background-position, text-indent;
        font-size: 150%;
        /* change this and see how they adjust! */
    }
    input[type="checkbox"].switch:checked + div {
        padding-left: 60px;
        width: 96px;
        background-position: 0 0;
    }
    input[type="checkbox"].switch:checked + div:before {
        background-color: @pink;
    }
    input[type="checkbox"].switch + div:before {
        content: 'Si';
        float: left;
        width: 32px;
        height: 32px;
        margin: -.1em;
        border-radius: inherit;
        background: @profile-light-gray;
        color: @pink;
        text-indent: -2.2em;
    }
    input[type="checkbox"].switch:active + div:before {
        background-color: @pink;
    }
    input[type="checkbox"].switch + div:before,
    input[type="checkbox"].switch + div:after {
        /* font: bold 60%/1.9 sans-serif; */
        font-size: 16px;
        line-height: 35px;
        text-transform: uppercase;
    }
    input[type="checkbox"].switch + div:after {
        content: 'No';
        float: left;
        line-height: 30px;
        text-indent: 0.9em;
        color: @profile-light-gray;
    }
    /* Switch code ends here, from now on it’s just bling for the demo page */
    label {
        display: inline-block;
        font: bold 110% sans-serif;
        position: relative;
        /*
        padding: 0.8em;
        border-top-width: 0;
        */
    }
}
