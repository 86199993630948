#phonebook-scroll {
    overflow: hidden;
    
    a:active, *:focus {
        outline: none;
    }
    
    .phonebook-scroll-wrap {
        position: relative;
        display: table;
        width: auto;
        white-space: nowrap;
        box-sizing: border-box;
        left: 0px;
        .transition(left, 0.5s);
    }
    
    .phonebook-column {
        display: table-cell;
        box-sizing: border-box;
        border-right: 1px solid @gray;
        height: 100%;
        /* background-color: white; */
        
        &:first-child { border-left: 1px solid @gray; }
    }
    
    .phonebook-item {
        width: 320px;
        padding: 0px 15px 0px 25px;

        h3 {
            &.character {
                margin-top: 0px;
                margin-bottom: 15px;
                font-weight: @lighter;
                font-size: 2.6rem;
                text-indent: -2px;
            }
            
            &.selected{
                color: #e56d79;
            }
        }
    }
}

#phonebook-scroll.sticker-scroll {
    .phonebook-column:first-child {
        /* .phonebook-item {width: 305px;} */
    }
    
    .phonebook-item {
        width: 306px;
        min-height: 315px;
    }
}

.topic-slider-pagination{
    text-align: center;
    margin-bottom: 50px;
    
    a:active, *:focus {
        outline: none;
    }
    
    a {
        color: #b8b8b8;
        padding: 0px 2px;
        font-weight: @bold;
        margin: 0px 8px;

        &:hover{
            color: #000;
        }

        &.topic-slider-arrow{
            font-size: 10px;
        }

        &.selected{
            color: #000;
            border-bottom: 2px solid #000;
        }
    }
}
