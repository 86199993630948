.people {
    .people-item {
        display: none;
        float: left;
        list-style-type: none;
        margin: 0 7px;
        position: relative;
        outline: none;

        &:first-child {
            display: block;
        }

        @media only screen and (min-width: @screen-md) {
            display: block;
        }

        .icon-star {
            bottom: 0;
            background-color: white;
            border-radius: 10px;
            color: @black;
            font-size: @font-size-small;
            position: absolute;
            right: -4px;
        }
    }
    
    .people-more {
        border: 1px solid @gray;
        color: @black;
        display: block;
        line-height: 1.5rem;
        text-align: center;
    }

    .popover {
        margin-left: 7px;
    }

    #specialPopover{
        left:90px !important;
    }
}


[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.popover.ng-scope.am-fade.left{
    top: -5px !important;
    left: 33px !important;
    width: 160px;
    background-color: #f9f9f9;

    .popover-content{
      padding: 3px 4px;
      max-height:160px;
      overflow: scroll;
    }

    .block{
        position: relative;
        display: inline-block;
        width: 36px;height:41px;
        margin-right: 12px;
        margin-left: 1px;
        outline: none;

        a{
            outline: none;

            .icon-star {
                bottom: 0px;
                background-color: white;
                border-radius: 10px;
                color: @black;
                font-size: @font-size-small;
                position: absolute;
                right: -3px;
            }
        }
    }



    .arrow{
        top:25px !important;
    }
}
