.popover {
	border-radius: 0;
	box-shadow: 1px 1px 5px @gray;
	color: @black;
	font-size: @font-size-extra-small;
	display: none!important;
	visibility: hidden!important;

	.popover-content {
		padding: 4px;
	}

	@media only screen and (min-width: @screen-md) {
	    & {
	        display: block!important;
			visibility: visible!important;
	    }
	}
}