#profile-topic-stickers {
}

#topic-maybe-interest {
    background-color: @pink;
    color: @white;
    padding: 15px 0 20px;
    margin-top: 40px;
    
    h2 {
        color: inherit;
        text-align: center;
        margin: 0 0 20px;
        padding: 0 15px;
        
        @media only screen and (min-width: @screen-sm) {
            font-size: 1.8rem;
        }
    }
    
    ul {
        padding: 0 25px;
    }
    
    @media only screen and (min-width: @screen-sm) {
        & div:first-child {
            > ul { padding-right: 0; }
        }
        & div:last-child {
            > ul { padding-left: 0; }
        }
    }
    
}
