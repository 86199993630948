.bb-thumbnail {
    height: auto;
    margin: 100px auto 0;
    padding-bottom: 40px;
    position: relative;
    width: 320px;

    @media only screen and (min-width: @screen-sm) {
        width: 420px;
    }

    .bb-thumbnail-box {
        background-color: @light-gray;
        border: 1px solid @gray;
        position: relative;
        .transition(all, 0.25s);

        .bb-thumbnail-wrap {
            padding-bottom: 3em;
            position: relative;

            .visual {
                height: auto;
                position: relative;
                width: 100%;

                &:hover {
                    cursor: pointer;
                }

                img {
                    display: block;
                    height: auto;
                    position: relative;
                    width: 100%;
                }

                .gradient {
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    visibility: visible;
                    width: 100%;
                    .transition(all, 0.25s);
                    .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .6));
                }

                .time {
                    bottom: 0;
                    color: @white;
                    font-size: @font-size-h1;
                    font-weight: @lighter;
                    left: 0;
                    opacity: 1;
                    position: absolute;
                    text-align: center;
                    visibility: visible;
                    width: 100%;
                    .transition(all, 0.25s);
 
                    .icon {
                        bottom: 0;
                        display: inline;
                        font-size: 20px;
                        margin: 0 10px;
                        width: auto;
                    }
                }

                .line {
                    background-color: @gray;
                    height: 60px;
                    left: 50%;
                    position: absolute;
                    top: -100px;
                    width: 1px;
                    z-index: 1;

                    @media only screen and (min-width: @screen-md) {
                        height: 1px;
                        left: auto;
                        top: 50%;
                        width: 20px;
                    }
                }
            }

            h3 { 
                font-family: @font-family-sans-serif;
                font-weight: @lighter;
                padding: 0 10px;
                text-align: center;
            }

            .article-footer {
                padding: 0 0 0 15px;
            }
        }

        &:hover {
            background-color: @dirty-white;
            border: 1px solid @gray;

            .visual {
                .gradient {
                    opacity: 0;
                    visibility: hidden;
                }

                .time {
                    bottom: -50px;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }   
    }   
}
