#comments {
    margin-bottom: 65px;

	.gig-comments-container,
	.gig-comments-container * {
		font-family: @font-family-serif;
	}

	.gig-composebox-textarea {
	    font-size: @font-size-small;
	}

	.gig-comments-count {
	    font-weight: @default;
	}
        
    .gig-comment-providerLogo {
        display: none;
    }

	.gig-composebox-post {
	    background: none repeat scroll 0 0;
	    border: 2px solid @pink;
	    border-radius: 0;
	    color: @pink;
	    font-size: 0.94rem;
	    letter-spacing: 5px;
	    padding: 4px 32px 4px 36px;
	}

	.gig-composebox-editor,  
	.gig-composebox-summary {
	    border-radius: 0;
	}

	.gig-comment-img {
	    border-radius: 20px;
	}

	.gig-composebox-login {
	    margin-left: 46px;
	}

	.gig-composebox-site-login {
	    background: none repeat scroll 0 0 @black;
	    border: none;
	    border-radius: 0;
	    color: @white;
	    line-height: 1.32rem;
	    padding: 0 12px;
	}

	.gig-comments-header, #comments .gig-comment-footer {
	    border-bottom: 1px solid @black;
	}

	.gig-comment-content {
	    border: 1px solid @gray;
	    padding-left: 0;
	}

	.gig-comment-body {
	    border-top: 1px solid @gray;
	    padding-left: 3px;
	}

	.gig-comment-mediaItem {
	    padding-left: 3px;
	}
	.gig-comment-username {
	    padding-left: 3px;
	}

	.gig-comment-userBadge {
	    margin-top: 3px;
	}

	.gig-comment-badge-pick .gig-comment-userBadge {
	    margin-top: 0;
	}

	.gig-composebox-sidebar-tag {
	    background-color: @white;
	    border-bottom: 1px solid @gray;
	    border-left: 1px solid @gray;
	    border-radius: 0;
	}
	.gig-comment-time {
	    margin-right: 3px;
	}

	.gig-comment-repliesArrow-img {
		background      : url('https://static.glamour.it/static/img/icons/arrow-down.svg') top;
		background-size : 8px;
	    height          : 8px;
	    width           : 8px;
	}
}

#livefyre-comments .fyre-mobile .fyre-stream-stats {
    left: 0;
    right: auto;
    top: 37px;
    text-align: left !important;
}