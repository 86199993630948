.list-elements {
    .reset-list;
    
    *:focus,
    a:active {
        outline: none;
    }
    
    li {
        vertical-align: middle;
        position: relative;
        margin: 8px 0;
        
        &.is-removed {
            color: #bebebe;
            a {
                color: #bebebe;
            }
            
            .item-wrap:after {
                content: "";
            }
        }
    }
    
    .item-wrap {
        position: relative;
        
        &:after {
            content: none;
            border-top: 1px solid @gray;
            position: absolute;
            width: 100%;
            top: 60%;
            left: 0;
        }
    }
    
    .item-category {
        display: inline-block;
        vertical-align: middle;
    }
    
    .item-name:extend(.list-elements .item-category) {
        &.maxwidth {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    
    .item-result-num:extend(.list-elements .item-category) {
    }
    
    .item-action-remove {
        color: @bb-gray;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        vertical-align: middle;
        
        .action-msg {
            font-size: 12px;
            margin-right: 5px;
            display: none;
        }
        
        .no-touch &:hover {
            color: @dark-gray;
            
            .action-msg {
                display: inline;
            }
        }
        
        &:after {
            content: "×";
            text-transform: uppercase;
            vertical-align: middle;
        }
    }
    
    .item-action-add {
        position: absolute;
        right: 10px;
        font-size: 1.3rem;
        font-weight: @lighter;
        color: #444;
        line-height: 1;
        
        &:before {
            content: "+";
        }
    }
    
    .list-btn-restore {
        color: @bb-gray;
        position: absolute;
        top: 2px;
        right: 0;
    }
}

.list-phonebook {
    li {
        margin: 12px 0;
        color: @bb-gray;
    }
    
    .item-wrap {
        font-weight: @lighter;
    }
}

.list-maybe-interest {
    li {
        border-bottom: 1px solid #ea8d97;
        font-weight: @light;
        font-size: @font-size-extra-small;
        padding: 0 0 7px;
        color: #fbefef;
        
        a {
            color: inherit;
        }
    }
}
