.topics {
    margin: 25px 0;

    @media only screen and (min-width: @screen-sm) {
        margin: 45px 0;
    }
    
    ul {
        .reset-list;
    }

    .topic-item {
        display: inline-block;
        padding: 0 6px;

        &.active a {
            color: @black;
        }

        a {
            color: @middle-gray;
            font-size: @font-size-extra-small;
            font-weight: @bold;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-base;
            }

            &:hover {
                color: @black;
            }
        }
    }
}