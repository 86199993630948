.rounded {
    @diameter: 35px;

    border-radius: 35px;
    display: inline-block;
    height: @diameter;
    overflow: hidden;
    width: @diameter;

    img {
        // width: @diameter;
        // height: @diameter;

        height: auto;
        max-width: 100%;
        vertical-align: baseline;
    }

    &-xxs {
        @diameter: 25px;
        border-radius: @diameter;
        height: @diameter;
        width: @diameter;
    }

    // One time 
    // and half 35px
    &-sm {
        @diameter: 52.5px;

        border-radius: @diameter;
        height: @diameter;
        width: @diameter;

        // img {
        //     width: @diameter;
        //     height: @diameter;
        // }
    }

    // Two times 
    // and quarter 35px
    &-md {
        @diameter: 78.5px;

        border-radius: @diameter;
        height: @diameter;
        width:  @diameter;

        // img {
        //     width: @diameter;
        //     height:  @diameter;
        // }
    }

    // Four times
    // 35 px
    &-lg {
        @diameter: 140px;

        border-radius: @diameter;
        height: @diameter;
        width: @diameter;

        // img {
        //     width: @diameter;
        //     height: @diameter;
        // }
    }
    
    &-social {
        @diameter: 62px;

        border-radius: @diameter;
        height: @diameter;
        width: @diameter;

        // img {
        //     width: @diameter;
        //     height: @diameter;
        // }
    }
}
