#profile {
    padding-top: 25px;

    @media only screen and (min-width: @screen-sm) {
        padding-top: 50px;
    }

    #profile-avatar {
        @value: 135px;
        height: auto;
        margin: 0 0 25px;

        @media only screen and (min-width: @screen-sm) {
            height: 170px;
            margin: 0 0 35px;
        }

        figure {
            height: auto;

            @media only screen and (min-width: @screen-sm) {
                height: @value;
            }
        }

        .rounded-xlg {
            border-radius: @value;
            float: none;
            height: @value;
            margin: 0 auto;
            width: @value;

            img {
                width: @value;
            }

            @media only screen and (min-width: @screen-sm) {
                float: left;
            }
        }

        figcaption {
            float: none;
            height: auto;
            text-align: center;
            width: 100%;

            @media only screen and (min-width: @screen-sm) {
                float: left;
                width: auto;
            }
        }

        h1 {
            font-family: @font-family-cursive;
            font-weight: @light;
            margin: 10px 0 0;

            &:after {
                color: @gray;
                content: "\e62f";
                display: block;
                font-family: 'icomoon';
                font-size: 40px;
                margin: 0;
                text-align: center;

                @media only screen and (min-width: @screen-sm) {
                    margin: -15px auto 0 auto;
                }
            }
        }

        .profile-social-badge {
            position: relative;
            top: -10px;
            .reset-list;

            li {
                color: @black;
                display: inline-block;
                font-size: @font-size-medium;
                height: 25px;
                line-height: 25px;
                margin: 0 2px;
                vertical-align: middle;
            }

            .rounded {
                border: 1px solid @black;
                margin: 3px 0 0;
                text-align: center;
            }
        }

    }
    
    .notify-row {
        border-bottom: 1px solid @profile-light-gray;
        padding-top: 35px;
        padding-bottom: 30px;
        
        &:first-child {
            padding-top: 20px;
        }
    }
    
    h6.notify-title {
        font-size: 1.2rem;
        margin: 0;
        padding: 0;
    }
    
    .notify-description {
        font-size: 1.1rem;
        
        @media (max-width: @screen-xs-max) {
            font-size: 0.7rem;
        }
    }

    #profile-badges {
        border-left: 1px solid @gray;
        height: 165px;
        text-align: center;

        .badges {
            .reset-list;
            margin-bottom: 10px;

            li {
                display: inline-block;
                margin: 0 3px;
            }

            .rounded {
                border-radius: 60px;
                height: 60px;
                width: 60px;
            }
        }
    }

    .profile-header {
        text-align: center;
        margin: 0 0 35px;

        @media only screen and (min-width: @screen-sm) {
            position: static;
            text-align: center;
        }

        h2 {
            font-family: @font-family-cursive;
            font-weight: @light;
            margin-bottom: 0;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-h2;
            }
            
            &.title-page {
                margin-top: 45px;
                margin-bottom: 10px;

                @media only screen and (min-width: @screen-sm) {
                    margin-bottom: 0px;
                }
            }
        }

        h5 {
            font-weight: @lighter;
        }
    }

    .btn-remove {
        height: 40px;
        padding: 0;
        width: 40px;

        @media only screen and (min-width: @screen-sm) {
            height: auto;
            padding: 0;
            width: auto;
        }
    }

    .profile-tabs {
        margin: 20px auto 0;

        @media only screen and (min-width: @screen-sm) {
            margin: 35px auto;
        }
    }

    .profile-tabs-content {
        padding-top: 0;
    }
    
    #profile-email-notify {
        margin-top: 35px;
        
        .update-email {
            margin: 0;
            margin-bottom: 12px;
            padding: 22px 0px;
            border: 1px solid @gray;
            background-color: #e6e6e6;
            .box-shadow(inset 0 0 3px rgba(0,0,0,.075));
        }

        .information {
            margin-bottom: 20px;
            font-size: 20px;
        }

        label {
            font-weight: @light;
            font-size: 1.6rem;
            color: @black;
            
            @media only screen and (min-width: @screen-sm) {
                line-height: 48px;
                margin-bottom: 0;
            }
        }
        
        .form-control {
            border-radius: 0;
            color: @black;
            height: 48px;
            
            .box-shadow(inset 0 0px 5px rgba(0,0,0,0.3));
        }

        .profile-email-notify-button {
            text-align: center;
            @media only screen and (min-width: @screen-sm) {
                text-align: right;
            }
        }
    }
}