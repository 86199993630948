.message {
    height: 35px;
    line-height: 35px;
    font-size: @font-size-extra-small;
    margin: 25px auto 35px;
    max-width: 150px;
    text-align: center;

    &:after {
        .triangle-content;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid;
    }

    &.md {
        font-size: @font-size-medium;
        max-width: 200px;
    }

    &.lg {
        font-size: @font-size-large;
        height: 50px;
        line-height: 50px;
        max-width: 250px;
    }

    .text {
        text-transform: uppercase;
    }
}