#load-more {
    margin: 20px 0;
    padding: 18px 28px;

    .btn {
        &:hover {
            background-color: @pink;
            border-color: @pink;

            .border {
                border: 1px solid @white;
            }

            .icon {
                color: @white;
            }

            .text {
                color: @white;
            }
        }

        .border {
            border: 1px solid transparent;
            border-radius: 30px;
            display: inline-block;
            height: 30px;
            left: -10px;
            position: relative;
            text-align: center;
            vertical-align: middle;
            width: 30px;
        }

        .icon {
            left: 1px;
            position: relative;
            top: 5px;
        }

        .text {
            color: @black;
            font-size: @font-size-small;
            opacity: 1;
        }
        //.transition(0.2s);
    }
}

.spinner-wrapper {
    .bouncing-spinner {
        background-color: @dirty-white;
        border-radius: 15px;
        display: inline-block;
        padding: 0 5px;

        .bouncing {
            .animation(change-colors, 1.4s);
            .animation-fill-mode(both);

            background-color: @gray;
            border-radius: 100%;
            display: inline-block;
            height: 10px;
            width: 10px;
        }

        .bounce-1 {
            .animation-delay(-0.30s);
        }

        .bounce-2 {
            .animation-delay(-0.15s);
        }
    }

    .text {
        font-family: @font-family-cursive;
        font-size: @font-size-small;
        letter-spacing: 1px;
        margin-left: 10px;
        text-transform: uppercase;
    }

    @-moz-keyframes change-colors {
        0% {
            background-color: @dark-gray;
        }

        40%, 80%, 100% {
            background-color: @gray;
        }
    }

    @-o-keyframes change-colors {
        0% {
            background-color: @dark-gray;
        }

        40%, 80%, 100% {
            background-color: @gray;
        }
    }

    @-webkit-keyframes change-colors {
        0% {
            background-color: @dark-gray;
        }

        40%, 80%, 100% {
            background-color: @gray;
        }
    }

    @keyframes change-colors {
        0% {
            background-color: @dark-gray;
        }

        40%, 80%, 100% {
            background-color: @gray;
        }
    }
}