#lovers-map {
	position: relative;
	display: block;
	margin: 5% auto;
	opacity: 1;

	.back {
		position: absolute;
		font-family: @font-family-sans-serif;
		color: @black;
		font-weight: @bold;
		font-size: @font-size-extra-small;
		text-align: center;
		top: -10px;
		left: -100px;
		width: 70px;
		height: 80px;
		cursor: pointer;
		z-index: 5;
		background: url('https://static.glamour.it/static/img/icons/mini-map.png') no-repeat center;
		background-size: 100%;
		text-transform: uppercase;
		line-height: 80px;
	}

	#lovers-wrap {
		position: absolute;
		top: 0;
		left: 0;

		#map {
			position: relative;
			width: 100%;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;

			svg {
				-webkit-transform-origin: 0 0;
				transform-origin: 0 0;

				g {
					path {
						fill: #e6e6e8;
						stroke: #fff;
						stroke-width: 2px;
						cursor: pointer;

						-webkit-transition: fill .35s ease-out;
						transition: fill .35s ease-out;

						&:hover {
							fill: #bbbbbc;
						}
					}
				}
			}
		}

		#markers {
			position: absolute;
			top: 0;
			left: 0;

			.map-marker {
				position: absolute;
				font-family: @font-family-serif;
				cursor: pointer;
				z-index: 1;
				opacity: 1;
                transform: translateX(-22px) translateY(-75px) matrix(1,0,0,1,0,0) !important;
                -webkit-transform: translateX(-22px) translateY(-75px) matrix(1,0,0,1,0,0) !important;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 45px;
					height: 45px;
					background-color: rgba(0,0,0,.2);
					border-radius: 50%;
					z-index: 1;
					-webkit-filter: blur(1px);
					-moz-filter: blur(1px);
					-ms-filter: blur(1px);
					filter: blur(1px);
				}

				&::after {
					content: "";
					position: absolute;
					top: 42px;
					left: 9px;
					border-left: 14px solid transparent;
					border-right: 14px solid transparent;
					border-top: 35px solid rgba(0,0,0,.15);
					z-index: 1;
					-webkit-filter: blur(1px);
					-moz-filter: blur(1px);
					-ms-filter: blur(1px);
					filter: blur(1px);
				}

				.map-marker-wrap {
					position: absolute;
					width: 45px;
					height: 75px;

					.icon-group {
						position: absolute;
						left: 0;
						top: 8px;
						z-index: 2;
						color: #fff;
						font-size: 1.6em;
						width: 100%;
						text-align: center;
						display: block;
					}

					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 45px;
						height: 45px;
						background-color: #faf8f9;
						border-radius: 50%;
						z-index: 2;
						-webkit-transition: all .35s ease-in-out;
						transition: all .35s ease-in-out;
					}

					&:after {
						content: "";
						position: absolute;
						top: 40px;
						left: 9px;
						border-left: 14px solid transparent;
						border-right: 14px solid transparent;
						border-top: 35px solid #faf8f9;
						z-index: 2;
						-webkit-transition: all .35s ease-in-out;
						transition: all .35s ease-in-out;
					}

					img {
						position: absolute;
						width: 25px;
						height: 25px;
						border-radius: 50%;
						top: 10px;
						left: 10px;
						z-index: 3;	
						-webkit-transition: all .35s ease-in-out;
						transition: all .35s ease-in-out;
					}
					.count {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						text-align: center;
						color: @white;
						z-index: 2;
						font-size: 17px;
  						line-height: 42px;
  						font-family: @font-family-sans-serif;
  						display: none;
					}
				}

				.name-label {
					position: relative;
					color: #fff;
					background-color: #17c1b4;
					display: block;
					white-space: nowrap;
					margin-left: 50px;
					pointer-events: none;
					-webkit-transition: -webkit-transform .25s ease-out;
					transition: transform .25s ease-out;
					opacity: 0;
					text-indent: -999em;
					padding: 0;
					-webkit-transform: translate3d(0,10px,0) scale(.95);
					transform: translate3d(0,10px,0) scale(.95);
				}

				.city-label {
					position: relative;
					color: #fff;
					background-color: #000;
					display: inline-block;
					margin-left: 50px;
					margin-top: 1px;
					pointer-events: none;
					-webkit-transition: -webkit-transform .25s ease-out;
					transition: transform .25s ease-out;
					opacity: 0;
					text-indent: -999em;
					padding: 0;
					-webkit-transform: translate3d(0,20px,0) scale(.95);
					transform: translate3d(0,20px,0) scale(.95);
				}

				&.marker {
					.map-marker-wrap {
						&::before {
							background-color: @map-marker-color;
						}
						&:after {
							border-top: 35px solid @map-marker-color;
						}
					}
					.name-label {
						padding: 2px 21px !important;
						background-color: @black
					}
					.city-label {
						background-color: @map-marker-color;
						font-size: 11px !important;
 						padding: 5px 8px !important;
					}
				}

				&:hover {
					z-index: 2;

					.map-marker-wrap {

						&:before {
							background-color: #000;
						}
						
						&:after {
							border-top: 35px solid #000;
						}

						img {
							-webkit-transform: scale(1.5);
							transform: scale(1.5);
						}
						.icon-group {
					
						}
					}

					.name-label {
						opacity: 1;
						text-indent: 0;
						padding: 2px 6px;
						-webkit-transform: translate3d(0,0,0) scale(1);
						transform: translate3d(0,0,0) scale(1);
					}

					.city-label {
						opacity: 1;
						text-indent: 0;
						padding: 2px 6px;
						-webkit-transform: translate3d(0,0,0) scale(1);
						transform: translate3d(0,0,0) scale(1);
					}
				}
			}
		}

		&.zoom {

			#map {

				svg {

					path {
						cursor: default;

						&:hover {
							fill: #e6e6e8;
						}
					}
				}
			}
		}
	}
}

#lovers-list-overlay {
	position: absolute;
	display: none;
	top: 15%;
	left: 0;
	width: 100%;
	height: 630px;
	margin: 0;
	background-color: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
	z-index: 8;

	@media only screen and (min-width: 1100px) {
	    & {
			width: 1100px;
			left: 50%;
			margin-left: -550px; 
	    }
	}
	.list-head {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 85px;
		z-index: 1;
		background-color: #fff;
		border-bottom: 1px solid #ccc;
		font-family: @font-family-serif;
		color: @black;

		p {
			font-size: 18px;
			line-height: 85px;
			float: left;
			margin: 0;
		}

		span.num {
			font-family: @font-family-sans-serif;
			font-weight : @lighter;
			font-size: 48px;
			line-height: 85px;
			float: left;
		  	padding: 0 .5em;
		  	margin: 0;
		}

		.btn-close {
			position: absolute;
			top: 50%;
			margin-top: -26px;
			right: 1px;
			background: none;
		}
	}
	.list-wrap {
		position: relative;
		width: 100%;
		height: 545px;
		overflow: hidden;

		ul#list {
			position: relative;
			overflow: hidden;
			padding: 1em 2em 3em 2em;

			li {
				position: relative;
				cursor: pointer;
				width: 33.3334%;
				height: 75px;
				overflow: hidden;
			  	padding: 1em 0;
				display: block;
				float: left;

				img {
					position: absolute;
					display: block;
					top: 50%;
					left: 0;
					margin-top: -21px;
					float: left;
					width: 42px;
					height: 42px;
					border-radius: 50%;
				}

				.name-label {
					position: relative;
					height: 100%;
					font-family: @font-family-serif;
					font-size: 17px;
					line-height: 19px;
					padding-left: 60px;
					padding-right: 10px;

					.label-table {

						display: table;
						height: 100%;
						
						.label-valign {

							display: table-cell;
							vertical-align: middle;
						}
					}

					span {

					}
				}
			}
		}
	}

	.iScrollVerticalScrollbar {
        position: absolute;
        z-index: 9999;
        width: 7px;
        right: 25px;
        top: 25px;
        bottom: 25px;
        border-radius: 5px;
        overflow: hidden;
        background: #d5d5d5;

        .iScrollIndicator {
            box-sizing: border-box;
            position: absolute;
            border-radius: 5px;
            width: 7px;
            right: 0;
            display: block;
            background: #5c5c5c;
        }
    }
}