#video {
	background-color: @light-gray;
	position: relative;
	text-align: center;

	.row {
		clear: both;
		margin: 0;
		overflow: hidden;
		padding: 0 15px;
		position: relative;

		@media only screen and (min-width: @screen-sm) {
			margin: 0 15px;
			padding: 0;
		}

		h2 {
			border: 0;
			color: @black;
			font-size: @font-size-small;
			font-weight: @bolder;
			letter-spacing: 2px;
			margin: 0;
			padding: 25px 0;
			text-transform: uppercase;

			@media only screen and (min-width: @screen-sm) {
				padding: 50px 0;
			}
		}
	}

	.main {
		background-color: @white;
		border-bottom: 1px solid @gray;

		@media only screen and (min-width: @screen-sm) {
			background-color: transparent;
			margin: 0;
			padding: 0;
		}

		.mobile-slider {
			.video-thumbnail {
                .thumbnail-cover {
                    height: 355px;
                }

				.play-overlay {
					background-color: rgba(0, 0, 0, .6); 
					opacity: 1; 
					vertical-align: middle;
					
					span.text { 
						padding: 10px 20px;
						top:0;
					} 
				}
					
				.informations { 
					bottom: -20px; 
				}
			}
		}
	}

	#like {
		ul.video-list {
			.reset-list;
			border-bottom: 1px solid @gray;
			position: relative;
			width: 100%;
			
			> li {
				display: inline-block;
				margin: 0 30px 0;
				overflow: hidden;
				position: relative;

				@media only screen and (min-width: @screen-sm) {
					margin: 0 20px 30px;
				}
			}
		}

		.mobile-slider {
			border-bottom: 1px solid @gray;
		}
	}
	
	.mobile-slider {
		margin: 0 auto;
		max-width: 480px;
		padding: 0 0 10px;
		position: relative;

		ul {
			.reset-list;
			overflow: hidden;
			position: relative;

			li {

				&.slide-animation {
					position: relative;
					width: 100%;
				}

				&.ng-hide {
					position: absolute;
					top: 0;
				}
			}
		}

		.video-thumbnail,
		.thumbnail-cover {
			width: 100%;
		}

		.loading-spinner {
			height: 320px;
			width: 100%;

			.loader {
				border-bottom: 1.1rem solid @light-gray; 
				border-left: 1.1rem solid @gray;
				border-right: 1.1rem solid @light-gray;
				border-top: 1.1rem solid @light-gray;
			}
		}

		.slider-pagination {			
			li {
				color: @gray;

				&.prev {
					color: @black;
				}

				&.next {
					color: @black;
				}

				&.active {
					color: @black;
				}
			}
		}
	}

	.thumbnails-row {
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		width: 100%;

		@media only screen and (min-width: @screen-sm) {
			max-width: 100%;
			width: 750px;
		}

		
		.video-thumbnail {
			margin: 0 auto 45px;

			@media only screen and (min-width: @screen-sm) {
				float: left;
				margin: 0 15px 40px;
				width: 220px;
			}
		}
	}
}


/* video html5 brightcove */
.video-js.cnlive-light.videoMobile {
	.vjs-volume-menu-button {
		display: block;
	}
	.vjs-custom-control-spacer {
		display: none;
	}
	.vjs-control {
		&:before {
			width: 36px;
		}
	}
	#next-video-data {
		font-size: 10px;
	}
	#next-video-thumb-wrapper {
		height: 61px;
		width: 49px;
	}
	#cnlive-play-btn {
		height: 49px;
		margin: -25px 0 0 -25px;
		width: 49px;
	}
	.countdown-loader {
		height: 49px;
		margin: -25px 0 0 -25px;
		width: 49px;
	}
	.countdown-loader-bg {
		height: 49px;
		margin: -25px 0 0 -25px;
		width: 49px;
	}
	#cnlive-countdown {
		font-size: 21px;
		right: 0;
		text-align: center;
		top: 20px;
		width: 100%;
	}
	#next-video-title {
		font-size: 13px !important;
	}
	#next-video-title-wrapper {
		width: 217px;
	}
}
.video-js.cnlive-light.videoMobile.vjs-has-started.vjs-user-inactive {
	.vjs-progress-control {
		-webkit-transform: translateY(-19px) !important;
		-moz-transform: translateY(-19px) !important;
		-ms-transform: translateY(-19px) !important;
		-o-transform: translateY(-19px) !important;
	}
}
.video-js.vjs-native.videoMobile {
	.vjs-big-play-button {
		display: block;
	}
}
.video-js.vjs-native.vjs-has-started.videoMobile {
	.vjs-big-play-button {
		display: none;
	}
}
.size-m.videoDesktop {
	.cnlive-endscreen {
		max-height: unset;
		max-width: unset;
	}
	#cnlive-endscreen-grid {
		li {
			height: auto;
			width: 33%;
			padding: 5px;
			margin: 0;
			img {
				-webkit-transition: all 0s ease 0s;
				transition: all 0s ease 0s;
			}
		}
		padding: 30px 80px 0px 80px;
	}
	#cnlive-endscreen-next {
		max-height: 315px;
		max-width: 560px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 10%;
	}
	#back-btn {
		-webkit-transition: all 0s ease 0s;
		transition: all 0s ease 0s;
	}
}