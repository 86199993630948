.big-header {
    @height: 275px;
    @desktop-height: 515px;

    height: @height;
    position: relative;

    &.big-header-small {
        height: 415px;
    }

    &:hover {
        .background-cover {
            opacity: 1;
        }
    }

    @media only screen and (min-width: @screen-sm) {
        height: @desktop-height;
    }

    > article {
        height: 100%;
        position: relative;
    }

    .background-cover {
        .transition(opacity, 0.3);
        background-position: top;
        background-size: cover;
        height: 100%;
        left: 0;
        opacity: 0.8;
        position: absolute;
        top: 0;
        width: 100%;
    }

    header {
        position: relative;
        width: 100%;
        z-index: 2;

        .bordered-title {
            border-bottom: 1px solid;
            display: inline-block;
            font-family: @font-family-serif;
            font-size: @font-size-h2;
            font-weight: @default;
            line-height: 2.38rem;
            margin: 0;
            padding: 5px 0 20px;
            text-transform: initial;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-extra-large;
                line-height: initial;
                padding: 5px 0 30px;
            }
        }

        p {
            font-family: @font-family-sans-serif;
            font-size: @font-size-text;
            font-weight: @lighter;
            line-height: 1.25rem;
            margin-top: 15px;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-large;
                line-height: 1.87rem;
                margin-top: 25px;
            }
        }

        .sticker {
            margin-top: 40px;
            
            .icon {
                display: block;
            }
        }
    }

    footer {
        bottom: 22px;
        position: absolute;
        width: 100%;
        z-index: 2;

        @media only screen and (min-width: @screen-sm) {
            bottom: 44px;
        }
    }
}