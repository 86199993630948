#footer {
    color: @middle-gray;
    margin: 25px auto;
        
    @media only screen and (min-width: @screen-md) {
        margin: 40px auto 0 auto;
        max-width: 80%;
    }

    #condenast {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .icon-logo-condenast {
        color: @black;
    }

    ul {
        margin:0;
        padding: 0;

        li {
            display: inline-block;
            font-size: @font-size-small;
            font-family: @font-family-serif;
            height: @font-size-small;
            line-height: 0.85;
            padding: 2px 6px 0;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-base;
                height: @font-size-base;
            }
        }
    }

    .conde-nast-sites {
        border-bottom: 1px solid @gray;
        margin-top: 5px;
        padding: 10px 0;
        clear: both;

        li {
            border-left: 1px solid;

            &:first-child {
                border-left: none;
            }
        }
    }

    a:hover {
        color: @gray;
    }
}
