.authors {
    height: 35px;
    width : auto;

    figure {
        height: 35px;
        overflow: hidden;
        padding: 0 10px 0 0;
        position: relative;

        & > .rounded {
            display: block;
            float: left;
        }

        @media only screen and (max-width: @screen-sm) {
            height: 45px;

            h6 {
                max-width: 110px;
            }
        }
    }

    figcaption {
        float: left;
        height: 35px;
        left: 10px;
        position: relative;
        text-align: left;
    }

    h5, h6 {
        font-family: @font-family-serif;
        font-size: @font-size-extra-small;
        font-weight: normal;
        margin: 3px 0 0;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-small;
        }
    }

    h5 {
        & + h6 {
            margin: 0;
        }
    }

    h6 {
        & + h6 {
            margin: 0;
        }
    }

    &.brandmag {
        height: auto;

        figure {
            height: auto;
        }

        figcaption {
            float: left;
            width: 100%;
            height: 20px;
            left: 3px;
        }

        a {
            float: left;
        }

    }
}

.list-elements{
    li.author-item{
        text-align: center;
        margin-bottom: 60px;

        h3.author-name {
            font-size: 1.4rem;
            line-height: normal;
            margin-top: 15px;
            margin-bottom: 0px;
        }

        img{
            display: block;
            margin: 0 auto;
        }

        p{
            font-size: 12px;
            margin-bottom: 15px;
        }
    }
}

.author-preferred{
    .breadcrumb{
        > li {
            &:first-child{
                padding-left: 15px;
            }

            &:first-child:before {
                border-left: 1px solid @gray;
                padding-left: 15px;
            }

            a{
                &:hover{
                    color: #000;
                }
            }
        }
    }

    h2.media-heading{
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2em;
        max-height: 34px;
        
        overflow: hidden;
        //white-space: nowrap;

        @media only screen and (min-width: @screen-sm) {
            max-height: 110px;    
            font-size: 30px;
        }
        

        a{
            &:hover{
                color: rgba(0,0,0,1);
                text-decoration: underline;
            }
            text-overflow: ellipsis;
        }
    }

    .content p{
        font-size: 18px;
        line-height: 24px;
    }

    .media-image:hover + article h2 a{
        text-decoration: underline;
    }

    .preferred-author-image{
        float: left;
        margin-right: 10px;

        &:hover + p a{
            text-decoration: underline;
        }
    }

    .preferred-author-info {
        position: absolute;
        z-index: 100;
        left: 120px;
        bottom: 25px;
        &:extend(.clearfix all);
        
        @media only screen and (min-width: @screen-sm) {
            bottom:35px;
            left: 225px;
        }

        p {
            float: left;
            font-size: 13px;
            margin: 0px;
        }

        span{
            color: #9e9e9f;
            font-family: 'PT Serif', Georgia, serif;
            font-style: italic;
        }

        a:hover{
            color: rgba(0,0,0,1);
            text-decoration: underline;
        }
    }

    .media-footer{
        .text{
            display: none;
        }

    }

    .related-users-container{
        position: absolute;
        bottom: 0px;
        right: 25px;
    }
}

.follower-author{
    padding-bottom: 0px;
    display: table;
    width: 100%;

    .follower-image{
        width: 100px;
        text-align: center;

        img{
            width: 100%;
        }
    }

    .follower-image:hover + .media-body .author-name a{
        text-decoration: underline;
    }
    
    .author-name{
        font-size: 18px;
        line-height: 25px;
        font-weight: bold;
        
        a{
            &:hover{
                color: #000;
                text-decoration: underline;
            }
        }

        span{
            color: #9e9e9f;
            font-family: 'PT Serif', Georgia, serif;
            font-weight: normal;
            font-size: 14px;
        }
    }

    .content{
        p{
            font-family: @font-family-sans-serif;
            font-weight: @lighter;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .follow-btn{
        text-align: right;
    }  
}

.follower-guest{
    padding-bottom: 0px;
    display: table;
    width: 100%;

    .follower-image{
        width: 100px;
        text-align: center;
    }

    .follower-image:hover + .media-body .guest-name a{
        text-decoration: underline;
    }

    .guest-name {
        font-family: @font-family-sans-serif;
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;

        a{
            &:hover{
                color: #000;
                text-decoration: underline;
            }
        }
    }

    .profile-link{
        text-align: right;
        a{
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 2px;
            color: #ababac;
            text-transform: uppercase;

            &:hover{
                color: #000;
            }
        }
    }

    .guest-content{
        font-family: @font-family-sans-serif;
        font-size: 13px;
        line-height: 18px;
        color: #9e9e9f;
        a{
            color: #9e9e9f;

            &:hover{
                color: #000;
            }
        }
    }

}
