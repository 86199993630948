#country-nav {
    display: none;
    width: 100%;
    height: 35px;
    z-index: 10001;
    text-align: center;
    position: relative;
    font-size: 12px;
    font-family: 'Droid Serif', serif;
}

#country-nav.white {
    background-color: #FFF;
    color: #a6a6a6;
    border-bottom: 1px solid #a6a6a6;
}

#country-nav.black {
    background-color: #000;
    color: #a6a6a6;
    border-bottom: 1px solid #404040;
}

#country-nav p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 35px;
}

#country-nav a#show {
    text-decoration: underline;
    cursor: pointer;
}

#country-nav.white a {
    color: #a6a6a6;
    text-decoration: none;
    cursor: pointer;
}

#country-nav.black a {
    color: #a6a6a6;
    text-decoration: none;
    cursor: pointer;
}

#country-nav #choose {
    display: none;
}

#country-nav #choose ul {
    margin: 0;
    padding: 0;
    height: 35px;
}

#country-nav #choose ul li {
    display: inline-block;
    margin: 0 2px;
    height: 34px;
    line-height: 35px;
    list-style-type: disc;
}

#country-nav #choose ul li:before {
    content: '•';
    margin: 0 2px;
}

#country-nav #choose ul li:first-of-type:before,
#country-nav #choose ul li:last-of-type:before,
#country-nav #choose ul li.more-country li:before {
    content: ' ';
}

#country-nav #choose ul li a {
    text-decoration: none;
    text-transform: uppercase;
}

#country-nav #choose ul li a:hover {
    text-decoration: none;
}

#country-nav #choose ul li.more-country {
    margin-left: 5px;
    width: 98px;
    position: relative;
    text-transform: uppercase;
}

#country-nav.white #choose ul li.more-country {
    border-right: 1px solid #a6a6a6;
    border-left: 1px solid #a6a6a6;
}

#country-nav.black #choose ul li.more-country {
    border-right: 1px solid #404040;
    border-left: 1px solid #404040;
}

#country-nav #choose ul li.more-country:hover {
    cursor: pointer;
}

#country-nav #choose ul li.more-country > ul {
    display: none;
    border: 1px solid #404040;
    width: 98px;
    height: auto;
    position: absolute;
    top: 33px;
    left: -1px;
    z-index: 2;
}

#country-nav.black #choose ul li.more-country > ul {
    display: none;
    width: 98px;
    height: auto;
    position: absolute;
    top: 33px;
    left: -1px;
    z-index: 2;
}

#country-nav.white #choose ul li.more-country > ul {
    background-color: #FFF;
    border: 1px solid #a6a6a6;
}

#country-nav.black #choose ul li.more-country > ul {
    background-color: #000;
    border: 1px solid #404040;
}

#country-nav #choose ul li.more-country > ul li {
    display: block;
}

#country-nav #close {
    width: 28px;
    height: 28px;
    margin: 3px 5px 3px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 28px;
}

#country-nav.white #close {
    border: 1px solid #a6a6a6;
}

#country-nav.black #close {
    border: 1px solid #404040;
}

#country-nav #close:hover {
    cursor: pointer;
}