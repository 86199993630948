.loading-spinner {
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 4;

    &.light {
        background-color: @dirty-white;

        .loader {
            @border-color: rgba(0, 0, 0, 0.1);

            border-bottom-color:@border-color;
            border-left-color: @white;
            border-right-color:@border-color;
            border-top-color: @border-color;
        }
    }

    &.dark {
        .loader {
            @border-color: rgba(208, 208, 208, 0.2);

            border-bottom-color:@border-color;
            border-left-color: @white;
            border-right-color:@border-color;
            border-top-color: @border-color;
        }
    }

    .loader {
        border-style: solid;
        border-width: 1rem;
        font-size: @font-size-text;
        left: 50%;
        margin-left: -3rem;
        margin-top: -3rem;
        position: absolute;
        text-indent: -9999rem;
        top: 50%;
        .animation(loader, 1.1s);

        &, &:after {
            border-radius: 50%;
            height: 6rem;
            width: 6rem;
        }

        @media only screen and (min-width: @screen-sm) {
            border-width: 1.1rem;
            margin-left: -5rem;
            margin-top: -5rem;

            &, &:after {
                border-radius: 50%;
                height: 10rem;
                width: 10rem;
            }
        }
    }

    h6 {
        position: absolute;
        text-transform: uppercase;
        width: 100%;
    }
}

@-moz-keyframes loader {
    0% {
        .rotate(0deg);
    }

    100% {
        .rotate(360deg);
    }
}

@-o-keyframes loader {
    0% {
        .rotate(0deg);
    }

    100% {
        .rotate(360deg);
    }
}

@-webkit-keyframes loader {
    0% {
        .rotate(0deg);
    }

    100% {
        .rotate(360deg);
    }
}

@keyframes loader {
    0% {
        .rotate(0deg);
    }

    100% {
        .rotate(360deg);
    }
}