#bb-header {
    border-bottom: 1px solid @darkpink;
    height: 100px;
    width: 100%;

    @media only screen and (min-width: @screen-sm) {
        height: 170px;
    }

    .middle-container {
        overflow: hidden;
        padding: 0;
    }

    #clock {
        background: none;
        color: @black;
        border-right: none;
        display: inline-block;
        height: 169px;
        padding: 1em;
        position: relative;
        text-align: left;
        width: 100%;
        .vertical-align;

        #clock-wrapper {
            height: auto;
            padding: 0 0 0 5px;
            position: static;
            .vertical-align-content;

            svg {
                display: none;
            }

            #title {
                .rotate(0deg);
                color: @black;
                font-size: @font-size-h2;
                height: auto;
                left: 0;
                line-height: 1rem;
                margin: 0;
                padding: 0;
                position: relative; 
                top: 0;
                width: auto;

                @media only screen and (min-width: @screen-md) {
                    font-size: @font-size-h2 * 1.2;
                }
            }

            #msg {
                color: @darkpink;
                font-size: @font-size-h6;
                font-weight: @bold;
                height: auto;
                left: 0;
                line-height: 1rem;
                margin: 0;
                padding: .5em 0;
                position: relative; 
                top: 0;
                width: auto;
            }

            #count {
                color: @black;
                font-size: @font-size-h2;
                font-weight: @lighter;
                height: auto;
                left: 0;
                line-height: 1rem;
                margin: 0;
                padding: 0;
                position: relative;
                top: 0;
                width: auto;
            }

            .icon {
                display: none;
            }
        }
    }

    .nav {
        height: 98px;
        width: 100%;

        @media only screen and (min-width: @screen-sm) {
            height: 169px;
        }

        ul {
            height: 100%;
            position: relative;
            width: 100%;
            .reset-list;

            li {
                color: @gray;
                display: block;
                float: left;
                font-size: @font-size-extra-small;
                height: 100%;
                position: relative;
                text-align: center;
                text-transform: uppercase;
                width: 20%;
                .transition(background-color, 0.25s);

                .nav-tab {
                    display: block;
                    height: 100%;
                    position: relative;
                    width: 100%;
                    .vertical-align;
                }

                .icon {
                    font-size: @font-size-h2;
                    vertical-align: middle;
                }

                &.selected {
                    background-color: @dirty-white;
                    
                    &:after {
                        border-bottom: 6px solid @darkpink;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        bottom: 0;
                        content : ' ';
                        height: 0;
                        left: 50%;
                        margin-left: -2px;
                        position: absolute;
                        width: 0;
                        z-index: 2;
                    }

                    a {
                        color: @darkpink;
                    }
                }

                &:hover {
                    background-color: @dirty-white;
                }
            }
        }
    }
}

#bb-list {
    padding: 3em 0;
    position: relative;

    .bb-day-wrap {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media only screen and (min-width: @screen-md) {
            width: 920px;
        }

        &.first {
            h2 {
                color: @gray;
            }
        }

        .day-head {
            margin: 0 auto 32px;
            position: relative;
            text-align: center;

            h2 {
                border-bottom: 1px solid @gray;
                display: inline-block;
                font-size: @font-size-extra-large;
                font-weight: @lighter;
                line-height: 4.1rem;
                margin: 0 auto 6.6px;
                padding: 0 0 15px 0;
            }

            h3 {
                font-size: @font-size-medium;
                font-weight: @bold;
                margin: 15px 0 0;
                text-transform: uppercase;
            }

            .bb-nav {
                color: @black;
                cursor: pointer;
                font-size: @font-size-extra-small;
                opacity: .5;
                overflow: hidden;
                position: absolute;
                text-transform: uppercase;
                top: 25%;

                span.label {
                    color: @black;
                    position: relative;
                    top: 25px;
                    .transition(all, 0.25s);
                }

                &:hover {
                    opacity: 1;

                    span.label {
                        top: 0px;
                    }
                }
            }

            .prev-bb {
                left: 50%;
                margin-left: -130px;
            }

            .next-bb {
                margin-right: -130px;
                right: 50%;
            }
        }

        .day-cols {
            margin-bottom: 32px;
            overflow: hidden;
            padding-bottom: 32px;
            position: relative;

            .bb-col {
                margin: auto;
                overflow: hidden;
                position: relative;

                &.col-left {
                    .visual {
                        .line {
                            @media only screen and (min-width: @screen-md) {
                                display: block;
                                right: -20px;
                            }
                        }
                    }
                }

                &.col-right {
                    .visual {
                        .line {
                            @media only screen and (min-width: @screen-md) {
                                display: block;
                                left: -20px;
                            }
                        }
                    }
                }

                @media only screen and (min-width: @screen-md) {
                    &.col-left {
                        float: left;
                    }

                    &.col-right {
                        float: right;
                        margin-top: 4em;
                    }
                }
            }

            .col-line {
                background-color: @gray;
                bottom: 0;
                height: 60px;
                left: 50%;
                position: absolute;
                top: auto;
                width: 1px;

                @media only screen and (min-width: @screen-md) {
                    bottom: auto;
                    height: 100%;
                    top: 0;
                }
            }
        }
    }
}