#author {
    #author-carousel {
        @media only screen and (min-width: @screen-sm) {
            margin-bottom: 35px;
        }
    }

    #tape {
        left: 50%;
        margin-left: -70px;
        position: absolute;
        top: -20px;
        z-index: 2;
    }

    .middle-container {
        position: relative;
    }
    
    .carousel-wrapper {
        height: 330px;
        margin: 20px auto;
        overflow: hidden;
        position: relative;
        width: 300px;

        @media only screen and (min-width: @screen-sm) {
            height: 550px;
            width: 460px;
        }
    }

    .author-picture {
        background-color: @white;
        border: 1px solid @gray;
        box-shadow: 1px 1px 5px @light-gray;
        height: 100%;
        left: 0;
        margin: 0 auto 40px;
        max-width: 300px;
        padding: 10px 10px;
        position: absolute;
        top: 0;
        width: 100%;

        @media only screen and (min-width: @screen-sm) {
            max-width: 460px;
        }

        .author-image {
            max-height: 250px;
            overflow: hidden;

            @media only screen and (min-width: @screen-sm) {
                max-height: 450px;
            }

            img {
                width: 100%;
            }
        }

        h3 {
            font-weight: @bold;
            margin: 10px auto 0;
        }
    }

    .slider-pagination {
        li {
            color: @gray;

            &.active,
            &.next,
            &.prev {
                color: @black;
            }
        }
    }

    .author-description {
        font-family: @font-family-sans-serif;
        
        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-large;
            margin-top: 25px;
        }
    }

    .author-blog {
        font-weight: @bold;
    }

    .social-share.inline {
        background-color: transparent;
        border: none;
        width: auto;

        .social-share-item:before {
            border: none;
            content: none;
        }
    }

    .author-tabs {
        margin: 0 auto;

        @media only screen and (min-width: @screen-sm) {
            margin: 35px auto;
        }
    }

    .author-tabs-content {
        padding-top: 45px;

        p {
            &.author_tab {
                @media only screen and (min-width: 768px) {
                    font-size: @font-size-large;
                }
            }
        }
    }

    .media-list-followers {
        @media only screen and (min-width: @screen-sm) {
            padding: 15px 60px;
        }
    }
}