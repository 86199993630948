.magazine-subscription {
    background-color: @light-gray;
    border-top: 1px solid @gray;
    bottom  : 0;
    height: 220px;
    padding: 20px 0 0;
    position: relative;
    width   :100%;

    &#magazine-cover-desktop {
        bottom  : 75px;
        position: absolute;
    }

    .small-cover {
        background-image: url('../img/ipad.png');
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        height: 100px;
        padding: 7px;
        position: relative;
        right: 10px;
        top: 26px;
        width: 70px;

        img {
            width: 55px;
        }
    }

    .big-cover {
        left: 20px;
        position: relative;
    }

    .magazine-bill {
        background-color: @black;
        bottom: 59px;
        color: @white;
        display: inline-block;
        font-size: @font-size-small;
        left: 15px;
        padding: 2px 12px;
        position: absolute;
        text-transform: uppercase;
        z-index: 3;
    }

    figcaption {
        line-height: 1;
        padding: 10px 15px 0;
    }

    a {
        font-family: @font-family-serif;
        font-size: @font-size-small;
        text-decoration: none;
    }
}