.controls {
    bottom: 30px;
    left: 0;
    padding: 0 20px;
    position: fixed;
    z-index: 15;
    width: 100%;
    .transition(all, 0.6s);

    &.expanded {
        background-color: rgba(0, 0, 0, 0.6);
        bottom: 0;
        height: 100%;
        padding-top: 200px;
    }

    .btn {
        position: relative;
        .transition(transform, 0.6s);
    }

    // Specific controls
    #back-to-top {
        font-size: @font-size-large;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-h2;
        }
    }

    #scroll-to {
        .text {
            font-weight: @bold;
            position: relative;
            top: -2px;
        }
    }
}