/* 
 *
 * Created on  : 17-nov-2014, 10.08.32
 * Author      : Gabriele D'Arrigo - @acirdesign - Condè Nast Italy
 * Description : Glamour site's styles. 
 *
*/

// Variables, 
// components and other.
@import "bs/bootstrap.less";
@import "variables.less";
@import "mixins.less";
@import "typography.less";
@import "components.less";

// Site
// structure.
@import "header.less";
@import "footer.less";
@import "side-menu.less";

// All 
// sites pages.
@import "author.less"; 
@import "beauty-break-archive.less";
@import "beauty-break.less";
@import "beauty-reporters.less";
@import "brand-mag.less";
@import "daily-push";
@import "leaf.less";
@import "event-countdown.less";
@import "profile.less";
@import "profile-badge.less";
@import "profile-subscription.less";
@import "lovers-map.less";
@import "profile-topic-stickers.less";
@import "topics.less";
@import "video.less";

html {
    font-size: 16px;
}

button{
    border:0;
    background: transparent;
}
button:focus {outline:0;}

body {
    background-color: @light-gray;
    color: @middle-gray;
    font-family: @font-family-sans-serif;
}

/* fix ios device */
body.modal-open {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%; //prevent hidden header on modal-open
}

#main-wrapper {
    overflow-x: hidden;
    &.international{
        padding-top: 35px;
        .transition(padding, 0.6s);
    }
    #main {
        overflow: hidden;
        padding-top: 55px;
        padding-bottom: 35px;
        position: relative;
        .transition(all, 0.6s);

        @media only screen and (min-width: @screen-sm) {
            padding-top: 77px;
            padding-bottom: 57px;
        }

        @media only screen and (min-width: 1025px) {
            body.menu-open & {
                padding-left:  220px;
            }
        }

        &.infinite-post {
            #footer {
                .conde-nast-sites {
                    &.first-row {
                        @media only screen and (max-width: @screen-sm) {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }

        &.infinite-post {
            #footer {
                .conde-nast-sites {
                    &.second-row {
                        @media only screen and (max-width: @screen-sm) {
                            display: none;
                        }
                    }
                }
            }
        }

        &.infinite-post {
            #footer {
                #condenast {
                    @media only screen and (max-width: @screen-sm) {
                        display: none;
                    }
                }
            }
        }

        &.brand-mag {
            position: absolute;
        }

        .main-header {
            margin: 15px 0 20px;
            
            @media only screen and (min-width: @screen-sm) {
                margin: 20px 0 40px;
            }

            h1 {
                font-family: @font-family-serif;
                font-size: @font-size-h2;
                font-weight: @default;
                margin: 5px 0 10px;

                @media only screen and (min-width: @screen-sm) {
                    font-size: @font-size-channel;
                }
            }

            h5 {
                font-family: @font-family-serif;
                font-weight: @light;
            }
        }
    }
}

.g-before-after{position:relative;overflow:hidden}.g-before-after img{display:block}.g-before-after .g-img-before{float:left}.g-before-after .g-img-after{left:50%;bottom:0;overflow:hidden}.g-before-after .g-img-after,.g-before-after .g-img-after img{position:absolute;right:0;top:0}.g-before-after .g-img-divider{position:absolute;left:50%;top:0;bottom:0;width:0;border-left:1px solid #fff;border-right:1px solid #fff}.g-before-after .g-img-divider>span{position:absolute;top:50%;display:block;background-color:#fff;padding:5px 10px;line-height:1;text-align:center;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}

.leaf .leaf-body .content .g-before-after img {max-width: none !important;}

.instagram-esi{
    text-align: center;
    background-color: #f0f0f0;
    padding:25px 15px;
    margin-bottom:50px;

    .section-title{
        font-family: @font-family-sans-serif;
        text-align: center;
        font-size: 20px;
        color: black;
    }

    iframe{
        max-width : 500px !important;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        width: calc(100% - 2px);
        height: 530px;
        box-shadow: none;
        border: 1px solid rgb(219, 219, 219);
        display: block;
        padding: 0;
        margin:0 auto 30px auto !important;
    }

    @media(min-width: @screen-md-min){
        //margin-top: -60px;

        iframe{
            height: 660px;
        }
    }
}
