#reporters {
	.main-header {
		h1 {
			font-size: @font-size-channel;
			font-weight: @default;
		}

		.divider,
		.topics {
			margin: 0 auto;
			padding: 0;

			h5 {
				margin-bottom: 0;
			}
		}
	}

	#reporters-controls {
		margin: 30px 0 10px;

		.btn {
			height: 42px;
			margin: 0 10px;
			padding: 0 5px;

			// Can't
			// align the fucking icons
			// in an elegant way.
			.text {
				left: -10px;
				padding: 0;
				position: relative;
				top: -12px
			}

			.icon {
				font-size: @font-size-h2;
				position: relative;
				left: -4px;
			}
		}

		#reporters-map {
            //display: none;

			.icon {
				font-size: @font-size-h3;
                top: 4px;
			}

			.text {
				top: -7px
			}
		}
	}

	.stripes {
		margin: 30px 0 0;
	}
}