.article-group {
    margin-bottom: 40px;
    margin-top: 40px;
    max-width: 925px;

    @media only screen and (min-width: @screen-sm) {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    &:first-of-type {
        margin-top: 0;
    }

    .main-article {
        margin-bottom: 35px;

        @media only screen and (min-width: @screen-sm) {
            margin-bottom: 45px;
        }
    }

    .child-article {
        margin-bottom: 35px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media only screen and (min-width: @screen-sm) {
            margin-bottom: 0;
        }
    }

    .article-figure {
        margin: 0;
        position: relative;

        img {
            width: 100%;
        }

        .article-type {
            bottom: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
        }

        .label {
            margin: 0 2px 0 0;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    .article-header {
        position: relative;

        &.with-sticker {
            h2 {
                max-width: 65%;

                @media only screen and (min-width: @screen-sm) {
                    max-width: 70%;
                }
            }
        }

        h2 {
            float:left;
            font-size: 1.75rem;
            font-weight: @lighter;
            line-height: 1.93rem;
            margin-bottom: 10px;
            margin-top: 10px;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-h1;
                line-height: 3.44rem;
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }

        .sticker {
            float:right;
            margin-top:5px;

            @media only screen and (min-width: @screen-sm) {
                margin-top:20px;
            }
        }
    }

    .content {
        clear:both;
        
        @media only screen and (min-width: @screen-sm) {
            max-width: 91.66666667%;
        }
        
        p {
            color: @black;
            margin-bottom: 15px;

            @media only screen and (min-width: @screen-sm) {
                margin-bottom: 25px;
            }
        }
    }

    .article-footer {
        position: relative;
        margin-top: 20px;

        &:after {
            border-top: 1px solid @black;
            content: ' ';
            display: block;
            margin-top: 15px;
            width: 32px;

            @media only screen and (min-width: @screen-sm) {
                margin-top: 20px;
                width: 65px;
            }
        }

        .article-information {
            .reset-list;
            overflow: hidden;
            width: 100%;

            > li {
                float: left;
                height: 35px;
                margin: 2px 0;
            }
        }

        .authors {
            h6 {
                color: @gray;
                margin-top: 10px;
                padding: 0 10px 0 0;
            }
        }

        .time {
            display: block;
            font-family: @font-family-serif;
            font-size: @font-size-extra-small;
            height: 35px;
            line-height: 2.19rem;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-small;
            }
            
            &:before {
                border-left: 1px solid @gray;
                content: ' ';
                display: inline-block;
                height: 12px;
                margin-right: 6px; 
            }
        }

        .article-share {
            .btn {
                font-size: @font-size-small;
                padding-left: 8px;
                padding-right: 8px;

                &:active,
                &:focus {
                    box-shadow: none;
                }
            }

            .social-share {
                background-color: @white;
                box-shadow: 1px 1px 5px @gray;
                margin-left: -120px;
                position: absolute;
                top: -65px;
            }
        }
    }
}