#topics {
    #topic-navigation {
        .reset-list;
        margin: 20px 0;

        @media only screen and (min-width: @screen-sm) {
            margin: 60px 0;
        }

        li {
            display: inline-block;
            padding: 0 10px;
            
            a {
                font-weight: @bolder;
            }
        }
    }

    .topic-list {
        .reset-list;

        li {
            display: inline-block;
            padding: 0;
            
            &:after {
                content: ', ';
            }

            &:last-child:after {
                content: ' ';
            }

            a {
                font-family: @font-family-serif;
                font-size: @font-size-text;
                font-weight: @lighter;

                @media only screen and (min-width: @screen-sm) {
                    font-size: @font-size-large;
                }
            }
        }
    }

    .topics {
        margin-top: 20px;

        .divider {
            margin-top: 0;
        }
    }

    .stripes {
        margin-bottom: 45px;
    }

    .btn {
        &.btn-bordered {
            @media only screen and (min-width: @screen-sm) {
                &.btn-lg {
                    &:hover {
                        color: @pink;
                    }

                    &.saved {
                        color: @pink;
                    }
                }
            }
        }
    }

}