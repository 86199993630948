.myModalRelative .modal {
    position : relative !important;
}

.modal-full {
    width      : 100%;
    margin     : 0 auto;
    min-height : 100%;
    background : white;
    position   : relative;

    .modal-content {
        border-radius  : 0;
        border         : 0 none;
        box-shadow     : 0 0 0;
        padding-bottom : 0;
        position       : relative;
    }
}

#galleryWrapper {
    background : white;
    position   : relative;
    z-index    : 10;
    width      : 100%;
    min-width  : 320px;

    h2 {
        font-family   : 'Open Sans', Helvetica, sans-serif;
        font-size     : 2.38rem;
        margin-bottom : 9px;
        font-weight   : 300;
        margin-top    : 11px;
        color         : #9e9e9f;
        line-height   : 2.5rem;
    }

    .btn-close {
        position      : absolute;
        top           : 0;
        right         : 0;
        width         : 40px;
        height        : 40px;
        padding       : 0;
        background    : transparent;
        border-radius : 0;
        font-size     : 24px;
        line-height   : 40px;
        color         : black;
        z-index       : 101;
    }
    .social-share {
        li {
            .inline-me();
            line-height : 50px;
            width       : 35px;
        }
    }
    .overlay {
        position : absolute;
        z-index  : 20;
        top      : 0;
        bottom   : 0;
        left     : 0;
        right    : 0;
        .social-share {
            position   : absolute;
            top        : 50%;
            height     : 124px;
            margin-top : -62px;
            width      : 100%;
            z-index    : 2;
            display    : block;

            h3 {
                color          : #fff;
                font-size      : 22px;
                line-height    : 1em;
                margin         : 0 0 42px;
                text-transform : uppercase;
            }
            ul {
                font-size : 0;
                padding   : 0;
                margin    : 0;

                li {
                    padding        : 0 5px;
                    .inline-me();
                    vertical-align : top;
                    width          : auto;

                    a {
                        @soch : 60px;
                        line-height     : @soch;
                        width           : @soch;
                        height          : @soch;
                        text-decoration : none !important;
                        display         : block;
                        border          : 1px solid #fff;
                        color           : #fff;
                        background      : #000;
                        font-size       : 25px;
                        span {
                            display     : block;
                            height      : @soch;
                            line-height : @soch;
                            &.icon-gplus {
                                font-size : 21px;
                            }
                        }
                    }
                }
            }
            text-align : center;
        }

        &.ng-animate {
            .transition(opacity .3s ease-in-out);
        }
        &.ng-enter {
            .opacity(0);
            &.ng-enter-active {
                .opacity(1);
            }
        }
        &.ng-leave {
            .opacity(1);
            &.ng-leave-active {
                .opacity(0);
            }
        }
        .text {
            padding    : 0 40px;
            position   : absolute;
            top        : 50%;
            left       : 0;
            right      : 0;
            .translateY(-50%);
            text-align : center;
            .counter {
                display   : block;
                font-size : 66px;
            }
            .title {
                margin-top  : -10px;
                font-size   : 45px;
                line-height : 45px;
                margin-left : 380px;
                color       : white;
                font-family : 'Open Sans', Helvetica, sans-serif;
                font-weight : 300;

                @media (max-width : 991px) and (min-width : 768px),
                (max-width : 767px) {
                    margin-left : 0;
                }

            }
            .author {
                font-size : 18px;
                .label {
                    color : @black;
                }
            }
        }

        &.cover {
            background : fade(@black, 70%);
        }
    }
}

.gallery-article-related {
    position : absolute;
    top      : 25px;
    left     : 0;
    right    : 0;
    z-index  : 0;

    .section-title {
        text-align : center;
    }
    .article-related {
        padding-left  : 0;
        padding-right : 0;
        @media (max-width : @stepSkin) {
            width : 1024px;
        }
    }
}

@gallerySidebarWd : 380px;
@galleryMinHeight : 910px; //make absolute positioned adv always visible
.row-gallery {
    //.rowEqualHeight();
    //set min height to prevent scroll top during loading (carousel removed)
    //min-height: @galleryMinHeight;
    /*
    * Gallery layout
    */
    > .col-content {
        position       : relative;
        vertical-align : top;
        width          : calc(100% - 380px);
        margin-left    : 380px;

        @media (max-width : 991px) and (min-width : 768px),
        (max-width : 767px) {
            width       : 100%;
            margin-left : 0;
        }

        > .carousel {
            position              : relative;
            overflow              : visible;
            -webkit-touch-callout : none;
            -webkit-user-select   : none;
            -khtml-user-select    : none;
            -moz-user-select      : none;
            -ms-user-select       : none;
            user-select           : none;

            &.ng-animate {
                .transition(opacity .3s ease-in-out);
            }
            &.ng-enter {
                .opacity(0);
                &.ng-enter-active {
                    .opacity(1);
                }
            }
            &.ng-leave {
                .opacity(1);
                &.ng-leave-active {
                    .opacity(0);
                }
            }
        }
    }
    .grid-on & {
        .carousel, .col-content {
            height : auto !important;
        }
        .slide-title, .slide-description {
            visibility : hidden;
        }
    }

    > .col-sidebar {
        position       : absolute;
        top            : 0;
        left           : 0;
        vertical-align : top;
        width          : @gallerySidebarWd;
        height         : 100%;
        padding        : 40px 40px 20px 20px;

        > .sidebox {
            position : absolute;
            top      : 40%;
            padding  : 0 40px 0 0;

            .counter {
                @height : 50px;
                display     : block;
                position    : relative;
                height      : @height;
                line-height : @height;
                padding-top : 3px;
                font-size   : 32px;
                font-weight : normal;
                font-family : 'PT Serif', Georgia, serif;
                color       : #000;
            }

            .slide-title {
                font-size   : 24px;
                margin-top  : 11px;
                line-height : 2.5rem;
                font-family : 'PT Serif', Georgia, serif;
                font-weight : normal;
            }

            .slide-description {
                font-size   : 15px;
                margin-top  : 11px;
                color       : #9e9e9f;
                line-height : 1.1rem;
                font-family : 'PT Serif', Georgia, serif;

                p {
                    font-size : 15px;
                }
            }
        }
    }

    /*
    * Carousel slide layout
    */
    .carousel-inner {

        > .item {
            overflow : hidden;
            height   : 100%;
            width    : 100%;

            > div,
            > .image,
            > .video {
                position   : absolute;
                width      : auto !important;
                top        : 50%;
                left       : 50%;
                .translate(-50%; -50%);
                max-height : 100%;
                max-width  : 100%;
                &.portrait {
                    max-height : 100%;
                    max-width  : 100%;
                }
                &.squared {
                    max-height : 100%;
                    max-width  : 100%;
                }
            }
            > .video {
                width          : 100% !important;
                padding-bottom : 0 !important;
            }
        }

    }

    .overlay {
        position : absolute;
        z-index  : 10;
        top      : 0;
        bottom   : 0;
        left     : 0;
        right    : 0;

        &.ng-animate {
            .transition(opacity .3s ease-in-out);
        }
        &.ng-enter {
            .opacity(0);
            &.ng-enter-active {
                .opacity(1);
            }
        }
        &.ng-leave {
            .opacity(1);
            &.ng-leave-active {
                .opacity(0);
            }
        }

        .instructions {
            display   : block;
            position  : relative;
            left      : 0;
            right     : 0;
            top       : 90px;
            font-size : 12px;

            .icon-scroll-down {
                display    : block;
                font-size  : 22px;
                margin-top : 5px;
            }
        }
        .text {
            position   : absolute;
            top        : 50%;
            left       : 0;
            right      : 0;
            .translateY(-50%);
            text-align : center;
            padding    : 0 60px;

            .counter {
                display    : block;
                font-size  : 133px;
                margin-top : -90px;
            }
            .title {
                font-size  : 72px;
                margin-top : -36px;
            }
            .author {
                font-size : 26px;
                .label {
                    color : @black;
                }
            }
        }

        &.cover {
            background : fade(white, 40%);
        }
        &.scroll-down {
            .instructions {
                color : white;
                top   : 0;
            }
        }

    }

    .click-to-shop {
        @size : 80px;
        display        : block;
        width          : @size;
        height         : @size;
        padding-top    : 26px;
        position       : absolute;
        z-index        : 10;
        right          : 15px;
        bottom         : 30px;
        border         : 1px solid @black;
        border-radius  : 50%;
        background     : white;
        letter-spacing : 0;
        text-align     : center;
        font-size      : 12px;
        line-height    : 14px;
        span {
            letter-spacing : .1em;
            font-size      : 17px;
        }

        &:hover {
            padding-top : 24px;
            border      : 3px solid red;
        }

        &.ng-animate {
            .transition(opacity .6s ease-in-out);
        }
        &.ng-enter {
            .opacity(0);
            &.ng-enter-active {
                .opacity(1);
            }
        }
        &.ng-leave {
            .opacity(1);
            &.ng-leave-active {
                .opacity(0);
            }
        }
    }

    /*
    * Carousel content / Carousel grid
    */
    .carousel-content {
        padding : 50px 0 51px 0;
        &.ng-hide-animate {
            .transition(opacity .3s ease-in-out);
        }
        &.ng-hide-add {
            .opacity(1);
            &.ng-hide-add-active {
                .opacity(0);
            }
        }
        &.ng-hide-remove {
            .opacity(0);
            &.ng-hide-remove-active {
                .opacity(1);
            }
        }
    }

    .carousel-grid {
        position         : relative;
        z-index          : 10;
        background-color : white;
        padding          : 40px 25px;

        &.ng-animate {
            .transition(opacity .6s ease-in-out);
        }
        &.ng-enter {
            .opacity(0);
            &.ng-enter-active {
                .opacity(1);
            }
        }
        &.ng-leave {
            .opacity(1);
            &.ng-leave-active {
                .opacity(0);
            }
        }

        .carousel-thumbnails {
            overflow : hidden;
            li {
                cursor        : pointer;
                height        : 155px;
                float         : left;
                margin-bottom : 20px;
                padding-right : 25px;
                img {
                    height : 100% !important;
                    width  : auto !important;
                }
                &.active {
                    .opacity(.5);
                }
            }
        }
    }

    .slide-description {
        letter-spacing : normal;
        text-transform : none;

        .photo-credits {
            margin : 14px 0;
        }
    }

    .gallery-title {
        font-size     : 24px;
        margin-bottom : 10px;
    }
    .slide-title {
        font-size     : 12px;
        margin-bottom : 10px;
    }

    .slide-description {
        a {
            text-decoration : underline;
        }
        .expand-toggle {
            cursor          : pointer;
            display         : block;
            text-decoration : underline;
            margin-top      : 14px;
        }
    }
}

.gallery-carousel-abs {
    position : absolute;
    top      : 0;
    left     : 0;
    right    : 0;
    bottom   : 0;
    display  : block;
    z-index  : 8;
    cursor   : pointer;

    .video-mode & {
        display : none;
    }
}

.gallery-carousel-control {
    @size : 60px;
    position   : absolute;
    top        : (@galleryMinHeight / 2);
    left       : 0;
    right      : 0;
    .translateY(-50%);
    height     : @size;
    width      : @size;
    z-index    : 9;
    background: rgba(255, 255, 255, 0.5);

    .icon {
        display     : block;
        height      : @size;
        width       : @size;
        line-height : @size;
        color       : black;
        text-align  : center;
    }
    &.right {
        left  : auto;
        right : 0;
    }
    &.left {
        left  : 0;
        right : auto;
    }

    &:hover {
        .opacity(1);
    }

    &.gallery-carousel-control-next-gallery,
    &.gallery-carousel-control-prev-gallery {
        .opacity(1);
        padding    : 10px 18px;
        width      : 200px;
        text-align : left;
        .icon {
            position : absolute;
            right    : 0;
            top      : 0;
        }
        .counter {
            display : block;
            color   : @gray;
        }
        .action-name {
            display     : block;
            color       : black;
            line-height : 40px;
        }

        &.ng-hide-animate {
            .transition(opacity .3s ease-in-out);
        }
        &.ng-hide-add {
            .opacity(1);
            &.ng-hide-add-active {
                .opacity(0);
            }
        }
        &.ng-hide-remove {
            .opacity(0);
            &.ng-hide-remove-active {
                .opacity(1);
            }
        }
    }
    &.gallery-carousel-control-prev-gallery {
        text-align : right;

        .icon {
            position : absolute;
            right    : auto;
            left     : 0;
            top      : 0;
        }
    }
}

.gallery-header {
    @height : 50px;
    @btnWidth : 50px;
    position   : absolute;
    z-index    : 18;
    top        : 0;
    left       : 0;
    right      : 0;
    height     : @height;
    background : white;

    .landscape & {
        width : @height;
        right : 0;
        left  : auto;
    }

    .btn, .counter {
        display     : block;
        height      : @height;
        line-height : @height;
        padding     : 0 10px;
        font-size   : 24px;
        background  : transparent;
    }
    .counter {
        padding : 0 14px;

        &.hidden-md{
            font-family      : 'PT Serif', Georgia, serif;
            font-size :20px;
        }
    }



    .btn {
        width : @btnWidth;
    }

    h1 {
        font-family : 'Oswald', sans-serif;
        font-size   : 18px;
        line-height : 50px;
        margin      : 0;
        left        : 22px !important;
    }

    .abs-left-0 {
        position : absolute;
        left     : 0;
        color    : black;
    }
    .abs-left-1 {
        position : absolute;
        left     : @btnWidth;
    }
    .abs-left-2 {
        position : absolute;
        left     : (@btnWidth * 2);
    }
    .abs-right-0 {
        position : absolute;
        right    : 0;
        color    : black;
    }
    .abs-right-1 {
        position : absolute;
        right    : @btnWidth;
        color    : black;
    }
    .abs-right-2 {
        position    : absolute;
        width       : calc(100% - 380px);
        margin-left : 380px;
        text-align  : center;
    }

    .transition(opacity .3s ease-in-out);
    .nav-hidden & {
        .opacity(0);
    }
}

.gallery-footer {
    @height : 50px;
    position         : absolute;
    z-index          : 7;
    bottom           : 0;
    left             : 0;
    right            : 0;
    height           : @height;
    color            : black;
    font-family      : 'PT Serif', Georgia, serif;
    background-color : rgba(255,255,255,0.9);

    .expand-toggle {
        display     : block;
        position    : absolute;
        height      : @height;
        top         : 0;
        right       : 0;
        cursor      : pointer;
        padding     : 0 10px;
        line-height : 50px;
        font-size   : 16px;
        color       : @black;

        .landscape & {
            left  : 0;
            right : auto;
        }
    }

    .slide-title {
        width          : 100%;
        display        : table-cell;
        height         : @height;
        vertical-align : middle;
        padding        : 0 12px;
        font-size      : 18px;
        line-height    : 50px;
        font-family    : 'PT Serif', Georgia, serif;
        font-weight    : normal;

        .landscape & {
            padding : 0 20px 0 40px
        }
    }
    &.expanded {
        @height : 30px;
        height : 70px;
        .slide-title {
            height      : 30px;
            line-height : 1;
        }
        .expand-toggle {
            height      : @height;
            line-height : @height;
        }

        ul.tools {
            @toolHeight : 36px;
            height      : @toolHeight;
            line-height : @toolHeight;
        }
    }

    ul.tools {
        @toolHeight : 50px;
        display     : block;
        position    : absolute;
        top         : 0;
        right       : 0;
        text-align  : left;
        height      : @toolHeight;
        line-height : @toolHeight;

        li {
            .inline-me();
            font-size : 0;
            a, .btn {
                color         : white;
                padding       : 0 14px;
                background    : transparent;
                line-height   : inherit;
                border-radius : 0;
                .inline-me();
                font-size     : 20px;
            }

            &.counter {
                font-size : 18px;
                color     : @gray75;
                padding   : 0 10px;
            }
            &.carousel-controls {
                a {
                    font-size : 20px;
                    padding   : 4px 16px 0;
                    position  : relative;
                    &.next {
                        &:before {
                            display          : block;
                            content          : "";
                            position         : absolute;
                            top              : 8px;
                            bottom           : 12px;
                            left             : -1px;
                            width            : 1px;
                            background-color : @gray44;
                        }
                    }
                }
            }
        }
    }

    .slide-description {
        letter-spacing : normal;
        text-transform : none;
        color          : @gray88;
        font-size      : 12px;
        padding        : 0 12px 12px 12px;

        height: 35px;
        overflow: auto;

        .photo-credits {
            margin : 14px 0;
        }

        a {
            color : white;
        }
    }

    .transition(opacity .3s ease-in-out);
    .nav-hidden & {
        .opacity(0);
    }
}

.gallery {
    @height : 270px;
    @desktop-height : 508px;
    height   : auto;
    //margin-bottom : 25px;
    // make it full window size
    margin   : 0 -10px 25px -10px;
    position : relative;
    z-index  : 1;

    @media only screen and (min-width : @screen-sm) {
        margin   : 0 0 25px 0;
    }
    .gallery-wrapper {
        position : relative;
        height   : @height;
        border   : 1px solid #d0d0d0;
        //overflow : hidden;

        @media only screen and (min-width : @screen-sm) {
            height : @desktop-height;
        }
    }

    .action, .action-video {
        font-family      : 'Open Sans', Helvetica, sans-serif;
        display          : none;
        height:40px;
        position         : absolute;
        z-index          : 3;
        left             : 50%;
        top              : 50%;
        //.transition(all .6s ease-in-out);
        .translate(-50%, -50%);
        color            : black;
        background-color : fade(white, 50%);

        @media only screen and (min-width : @screen-sm) {
            height: 50px;
            top : 50%;
        }

        &.ready {
            display : block;
        }
    }

    .action {
        border     : 0;
        width      : 220px;
        box-shadow : none;

        span.info {
            text-transform : uppercase;
            font-size      : 13px;
            letter-spacing : 1.5px;
            line-height    : 1;
            display        : block;
            font-weight    : bold;
            text-align     : left;
            padding        : 5px 0 0 10px;
            @media only screen and (min-width : @screen-sm) {
                padding : 9px 0 5px 10px;
            }
            &.counter {
                .opacity(.80);
                font-weight    : normal;
                letter-spacing : normal;
                padding-top    : 2px;
            }
        }

        .icon {
            position     : absolute;
            top          : 0; right : 0;
            border-width : 0 0 0 1px;
            border-color : #d0d0d0;
            background   : none;
            box-shadow   : none;
        }
        .btn.btn-square {
            height : 39px;
            @media only screen and (min-width : @screen-sm) {
                height : 49px;
            }
        }
    }

    .fullscreen {
        position   : absolute;
        right      : 0;
        top        : 0;
        z-index    : 3;
        transform  : none;
        box-shadow : none;
        background : rgba(255, 255, 255, 0.5);
        color      : #000;

        @media only screen and (min-width : 768px) {
            height      : 50px;
            line-height : 50px;
            width       : 50px;
        }

    }

    .gallery-item {
        width      : 100%;
        height     : 100%;
        position   : absolute;
        top        : 0;
        left       : 0;
        text-align : center;

        //@media only screen and (min-width : @screen-sm) {
        //background-image : none !important;
        //}

        &:hover {
            cursor : pointer;
        }

        img.gallery-item-img {

            position: absolute;
            width: auto !important;
            top: 50%;
            left: 50%;
            -webkit-transform: translate3d(-50%, -50%, 0);
            -moz-transform: translate3d(-50%, -50%, 0);
            -ms-transform: translate3d(-50%, -50%, 0);
            -o-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-height: 100%;
            max-width: 100%;


            //position: absolute;
            //top: 0;
            //left: 50%;
            //display: block;
            //-webkit-transform: translate(-50%,0);
            //-moz-transform: translate(-50%,0);
            //-ms-transform: translate(-50%,0);
            //-o-transform: translate(-50%,0);
            //transform: translate(-50%,0);
            //width: 100%!important;
            //height: 100%!important;
            //object-fit: cover;

            //display        : inline;
            //max-width      : none;
            //height         : calc(@height - 2px);
            //max-height     : calc(@height - 2px);
            //vertical-align : middle;
            //
            //@media only screen and (min-width : @screen-sm) {
            //    height     : auto;
            //    max-height : calc(@desktop-height - 2px);
            //    max-width  : 100%;
            //}
        }

    }

    &.vertical {
        .gallery-wrapper {
            height : 485px;

            @media only screen and (min-width : @screen-sm) {
                height : 652px;
            }

            img {
                display        : inline;
                max-width      : 100% !important;
                max-height     : 100% !important;
                height         : auto !important;
                vertical-align : middle;
                position       : absolute;
                left           : 50%;
                top            : 50%;
                transform      : translate(-50%, -50%);

                //@media only screen and (min-width : @screen-sm) {
                //    height     : 652px !important;
                //    max-height : 652px !important;
                //    max-width  : 100%;
                //}
            }

        }
    }

    .pagination-wrapper {
        .arrows-wrapper {
            position : absolute;
            top      : calc(50% - 75px);
            width    : 100%;
            z-index  : 2;

            .btn {
                box-shadow : none;
            }
            .next {
                position : absolute;
                right    : 0;
            }
        }
    }
    .gallery-information {
        color       : @black;
        font-family : @font-family-serif;
        font-size   : @font-size-extra-small;
        margin      : 10px 15px;
        min-height  : 45px;

        @media only screen and (min-width : @screen-sm) {
            margin      : 10px 0 0;
            font-size : @font-size-small;
        }

        .caption {
            padding : 0 0 0 5px;
        }
    }

}