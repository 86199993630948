/* VARIABILI */
@pink-gs:#ea5198;


@white:                     #FFF;
@black:                     #000;
@dark-gray:                 #737375;
@gray:                      #d0d0d0;
@middle-gray:               #9e9e9f;
@middle-light-gray:         #ededed;
@gray44:                    #444444;
@gray88:                    #888888;
@gray75:                    #757575;
@light-gray:                #f9f9f9;
@dirty-white:               #f0f0f0;
@bb-gray:                   #5c5c5c;
@dark-white:                #A5A5A6;
@light-black:               #333;
@profile-light-gray:        #d2d2d2;
@green:                     #53d9a5;
@azure:                     #8fdceb;

@orange:                    #df6452;
@pink:                      #e56d79;
@violet:                    #c7516e;
@magenta:                   #d783a5;
@light-pink:                #ec959f;
@darkpink:                  #e16d78;
@flesh:                     #d982a5;
@btn-red:                   #e0007a;

@facebook:                  #3b5998;
@twitter:                   #55acee;
@googleplus:                #dd4b39;
@pinterest:                 #cb2028;
@youtube:                   #cc181e;
@instagram:                 #7a5441;

@font-family-sans-serif:    'Open Sans', Helvetica, sans-serif;
@font-family-serif:         'PT Serif', Georgia, serif;
@font-family-fantasy:       'Abril Fatface', cursive;
@font-family-cursive:       'Oswald', sans-serif;
@font-family-monospace:     Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:          @font-family-sans-serif;

@headings-font-weight:      600;
@headings-color:            @black;

@baseDim:                   20px;

@bolder:                    700;
@bold:                      600;
@default:                   initial;
@light:                     400;
@lighter:                   300;

@screen-xlg:                1600px;
@stepSkin:                  1366px;
@iphone6:                   768px;


@font-size-extra-large:     4.1rem;    //  ~65px
@font-size-large:           1.37rem;   //  ~22px
@font-size-medium:          1.25rem;   //  ~20px
@font-size-base:            16px;
@font-size-small:           0.875rem;  //  ~14px
@font-size-extra-small:     0.75rem;   //  ~12px

@font-size-channel:         3.44rem;   //  ~55px
@font-size-h1:              3.12rem;   //  ~50px
@font-size-h2:              2.38rem;   //  ~38px
@font-size-h3:              1.87rem;   //  ~30px
@font-size-h4:              1.63rem;   //  ~26px
@font-size-h5:              1rem;
@font-size-h6:              0.85rem;   //  ~14px
@font-size-text:            18px;      //  ~18px

@line-height-base:          1.5;
@line-height-text:          30px;      //  ~30px
@line-height-media:         18px;      //  ~18px
@line-height-h2:            2.5rem;
@line-height-h3:            2.19rem;
@line-height-button:        1.42857;