body {
    &.daily-push {
        background-color: #EEEEEE;
    }
}

#daily-push {
    #main {
        margin: 0;
        padding: 0;
    }

    #leaf {
        .box-shadow(2px 0 6px rgba(0, 0, 0, 0.2));

        background-color: @white;
        padding: 5px;
        margin: 15px auto 90px auto;
        width: 90%;

        h1#title {
            font-size: @font-size-large;
            margin: 10px 0 5px;
        }

        h2#subtitle {
            margin-top: 0;
        }

        .date {
            color: @middle-gray;
            font-size: 2.5rem;
            font-weight: @lighter;
            margin: 10px 0;
            text-align: center;

            > .month {
                display: block;
                font-size: @font-size-small;
                margin-top: 10px;
                text-transform: uppercase;
            }
        }

        header .authors {
            height: auto;
            width : auto;
            text-align: center;

            figure {
                height: auto;
                padding: 0;

                & > .rounded {
                    display: inline-block;
                    float: none;
                }
            }

            figcaption {
                float: none;
                height: auto;
                left: 0;
                text-align: center;
            }
        }

        .gallery {
            .gallery-information {
                display: none;
            }

            .pagination-wrapper  {
                .arrows-wrapper {
                    margin-top: -20px;
                    top: 50%;
                    width: 100%;
                }

                .prev {
                    border-top: 1px solid @gray;
                    left: 0;
                    right: auto;
                    top: 0;
                }
            }
        }

        #back-to-top {
            box-shadow: none;
            float: none!important;
            margin: 10px 0;
        }

        .content {
            p {
                img {
                    width: 100%;
                    height: auto;
                    margin-top: 20px;
                }
            }
        }
    }

    .media {
        .media-heading {
            font-size: @font-size-small;
            line-height: 1rem;
        }

        footer {
            bottom: 20px;
        }

        .rounded {
            border-radius: 30px;
            height: 30px;
            width: 30px;
        }
    }
}