@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,700,400italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700);
@import url(https://fonts.googleapis.com/css?family=Abril+Fatface);

h1,
h2,
h3,
h4,
h5,
h6 {
    small {
        color: inherit;
        font-family: @font-family-serif;
        font-size: @font-size-small;
    }
}

a {
    color: @black;
    text-decoration: none;

    &:hover,
    &:focus {
        color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
        text-decoration: none;
    }

    &.color-black {
        &:hover {
            color: rgba(0, 0, 0, 0.7);
        }
    }

    &.color-white {
        &:hover,
        &:focus {
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

// Font sizes from mobile to desktop
h1 {
    font-size: 1.75rem;   //  ~28px
    line-height: 1.93rem; //  ~31px
}

h2 {
    font-size: 1.25rem;   //  ~20px
    line-height: 1.5rem;  //  ~24px
}

h3 {
    font-size: 1.125rem;  //  ~18px
    line-height: 1.37rem; //  ~22px
}

h4 {
    font-size: 1.3rem;    //  ~18px
}

h5 {
    font-size: 1rem;      //  ~16px
}

h6 {
    font-size: 0.82rem;   //  ~13px
}

p {
    font-size: 1rem;      //  ~16px
    font-family: @font-family-serif;
    margin: 0 0 15px;
}


b, strong {
    font-weight: @bold;
}


@media only screen and (min-width: @screen-sm) {
    h1 {
        font-size:   3.12rem; //  ~50px
        line-height: 3.44rem; //  ~55px
    }

    h2 {
        font-size: 2.38rem;   //  ~38px
        line-height: 2.5rem;  //  ~40px
    }

    h3 {
        font-size: 1.87rem;   //  ~30px
        line-height: 2.19rem; //  ~35px
    }

    h4 {
        font-size: 1.63rem;   //  ~26px
    }

    h5 {
        font-size: 0.85rem;   //  ~14px
    }

    h6 {
        font-size: 0.85rem;   //  ~14px
    }

    p {
        font-size: 1.12rem;   //  ~18px
        margin: 0 0 25px;
    }
}  

small {
    font-size: @font-size-small;
}
