// Gradient
.gradient(@start-color, @end-color) {
    background-color: @start-color;
    background: -webkit-gradient(linear, left top, left bottom, from(@start-color), to(@end-color));
    background: -webkit-linear-gradient(top, @start-color 0%, @end-color, 98%);
    background: -moz-linear-gradient(top, @start-color 0%, @end-color, 98%);
    background: -ms-linear-gradient(top, @start-color 0%, @end-color, 98%);
    background: -o-linear-gradient(top, @start-color 0%, @end-color, 98%);
    background: linear-gradient(to bottom,  @start-color 0%, @end-color 98%); 
}

.content() {
    content: ' ';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.triangle-content {
    content: ' ';
    display: block;
    height: 0; 
    margin: 0 auto;
    width: 0;
}

.color-black {
    color: @black;

    & > a {
        color: @black;

        &:hover {
            color: @black;
        }
    }
}

.color-dark-gray {
    color: @dark-gray;
}

.color-gray {
    color: @gray;
}

.color-middle-gray {
    color: @middle-gray;
}

.color-light-gray {
    color: @light-gray;
}

.color-white {
    color: @white;

    & > a {
        color: @white;

        &:hover {
            color: @white;
        }
    }
}

.color-azure {
    color: @azure;
}

.color-pink {
    color: @pink;
}

.color-violet {
    color: @violet;
}

.color-magenta {
    color: @magenta;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.cell-align-middle {
    vertical-align: middle;
}

// Gradient
// with all colors
.gradient-orange {
    &:after {
        .content;
        .gradient(rgba(255, 255, 255, 0), rgba(223, 100, 82, 0.8));
    }

    &:hover:after {
        .gradient(rgba(255, 255, 255, 0), rgba(223, 100, 82, 0.4));
    }
}

.gradient-pink {
    &:after {
        .content;
        .gradient(rgba(255, 255, 255, 0), rgba(229, 109, 121, 0.8));
    }

    &:hover:after {
        .gradient(rgba(255, 255, 255, 0), rgba(229, 109, 121, 0.4));
    }
}

.gradient-light-pink {
    &:after {
        .content;
        .gradient(rgba(255, 255, 255, 0), rgba(236, 149, 158, 0.8));
    }

    &:hover:after {
        .gradient(rgba(255, 255, 255, 0), rgba(236, 149, 158, 0.4));
    }
}

.gradient-violet {
    &:after {
        .content;
        .gradient(rgba(255, 255, 255, 0), rgba(199, 81, 110, 0.8));
    }
    
    &:hover:after {
        .gradient(rgba(255, 255, 255, 0), rgba(199, 81, 110, 0.4));
    }
}

.gradient-magenta {
    &:after {
        .content;
        .gradient(rgba(255, 255, 255, 0), rgba(215, 131, 165, 0.8));
    }    

    &:hover:after {
        .gradient(rgba(255, 255, 255, 0), rgba(215, 131, 165, 0.4));
    }
}

.gradient-black {
    &:after {
        .content;
        .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }    

    &:hover:after {
        .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    }
}

// Gradient that start with colors and end to black
.gradient-orange-black {
    &:after {
        .content;
        .gradient(rgba(223, 100, 82, 0.6), rgba(0, 0, 0, 0.6));
    }

    &:hover:after {
        .gradient(rgba(223, 100, 82, 0.4), rgba(0, 0, 0, 0.4));
    }
}

.gradient-pink-black {
    &:after {
        .content;
        .gradient(rgba(229, 109, 121, 0.6), rgba(0, 0, 0, 0.6));
    }

    &:hover:after {
        .gradient(rgba(229, 109, 121, 0.4), rgba(0, 0, 0, 0.4));
    }
}

.gradient-light-pink-black {
    &:after {
        .content;
        .gradient(rgba(236, 149, 158, 0.6), rgba(0, 0, 0, 0.6));
    }

    &:hover:after {
        .gradient(rgba(236, 149, 158, 0.4), rgba(0, 0, 0, 0.4));
    }
}

.gradient-violet-black {
    &:after {
        .content;
        .gradient(rgba(199, 81, 110, 0.6), rgba(0, 0, 0, 0.6));
    }
    
    &:hover:after {
        .gradient(rgba(199, 81, 110, 0.4), rgba(0, 0, 0, 0.4));
    }
}

.gradient-magenta-black {
    &:after {
        .content;
        .gradient(rgba(215, 131, 165, 0.6) ,rgba(0, 0, 0, 0.6));
    }    

    &:hover:after {
        .gradient(rgba(215, 131, 165, 0.4) ,rgba(0, 0, 0, 0.4));
    }
}

// Background colors
.bg-white {
    background-color: rgba(255, 255, 255, 1);
}

.bg-black {
    background-color: rgba(0, 0, 0, 1);
}

.bg-orange {
    background-color: rgba(223, 100, 82, 1);
}

.bg-pink {
    background-color: rgba(229, 109, 121, 1);
}

.bg-light-pink {
    background-color: rgba(236, 149, 158, 1);
}

.bg-violet {
    background-color: rgba(199, 81, 110, 1);
}

.bg-magenta {
    background-color: rgba(215, 131, 165, 1);
}

.bg-black {
    background-color: rgba(0, 0, 0, 1);
}

// Overlay with opacity
.overlay-orange {
    &:after {
        .content;
        background-color: rgba(223, 100, 82, 0.6);
    }

    &:hover:after {
        background-color: rgba(223, 100, 82, 0.4);
    }
}

.overlay-pink {
    &:after {
        .content;
        background-color: rgba(229, 109, 221, 0.6);
    }

    &:hover:after {
        background-color: rgba(229, 109, 221, 0.4);
    }
}

.overlay-light-pink {
    &:after {
        .content;
        background-color: rgba(236, 149, 158, 0.6);
    }

    &:hover:after {
        background-color: rgba(236, 149, 158, 0.4);
    }
}

.overlay-violet {
    &:after {
        .content;
        background-color: rgba(199, 81, 110, 0.6);
    }
    
    &:hover:after {
        background-color: rgba(199, 81, 110, 0.4);
    }
}

.overlay-magenta {
    &:after {
        .content;
        background-color: rgba(215, 131, 165, 0.6);
    }    

    &:hover:after {
        background-color: rgba(215, 131, 165, 0.4);
    }
}

.overlay-black {
    &:after {
        .content;
        background-color: rgba(0, 0, 0, 0.6);
    }    

    &:hover:after {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.appearence(@value) {
    -webkit-appearance: @value;
       -moz-appearance: @value;
            appearance: @value;
}

// Placehodlers and appearence
.placeholder-color(@color: #000000) {
    &::-webkit-input-placeholder {
        color: @color;
    }

    &:-moz-placeholder {
        color: @color;
    }

    &::-moz-placeholder {
        color: @color;
    }

    &:-ms-input-placeholder {
        color: @color;
    }
}

// CSS3 Animation mixins
.animation (@name, @time) {
    -webkit-animation: @name @time infinite linear;
    -moz-animation:    @name @time infinite linear;
    -o-animation:      @name @time infinite linear;
    animation:         @name @time infinite linear;
}

.animation-delay(@value) {
    -webkit-animation-delay: @value;
       -moz-animation-delay: @value;
        -ms-animation-delay: @value;
         -o-animation-delay: @value;
            animation-delay: @value;
}

.animation-fill-mode(@value) {
    -webkit-animation-fill-mode: @value;
       -moz-animation-fill-mode: @value;
        -ms-animation-fill-mode: @value;
         -o-animation-fill-mode: @value;
            animation-fill-mode: @value;
}

// Various 
// CSS3 transformation and filters
.filter(@filter) {
    -webkit-filter: @filter;
       -moz-filter: @filter;
         -o-filter: @filter;
        -ms-filter: @filter;
            filter: @filter;
}

.rotate2d(@deg) {
    -webkit-transform: rotate(@deg);
       -moz-transform: rotate(@deg);
        -ms-transform: rotate(@deg);
         -o-transform: rotate(@deg);
            transform: rotate(@deg);
}

.rotateX(@deg) {
    -webkit-transform: rotateX(@deg);
       -moz-transform: rotateX(@deg);
        -ms-transform: rotateX(@deg);
         -o-transform: rotateX(@deg);
            transform: rotateX(@deg);
}

.rotateY(@deg) {
    -webkit-transform: rotateY(@deg);
       -moz-transform: rotateY(@deg);
        -ms-transform: rotateY(@deg);
         -o-transform: rotateY(@deg);
            transform: rotateY(@deg);
}

.scale(@value) {
    -webkit-transform:  scale(@value);
        -moz-transform: scale(@value);
         -ms-transform: scale(@value);
          -o-transform: scale(@value);
             transform: scale(@value);
}

.scale3d(@xvalue, @yvalue, @zvalue) {
    -webkit-transform:  scale3d(@xvalue, @yvalue, @zvalue);
        -moz-transform: scale3d(@xvalue, @yvalue, @zvalue);
         -ms-transform: scale3d(@xvalue, @yvalue, @zvalue);
          -o-transform: scale3d(@xvalue, @yvalue, @zvalue);
             transform: scale3d(@xvalue, @yvalue, @zvalue);
}

.translate(@xvalue: 0, @yvalue: 0, @zvalue: 0) {
    -webkit-transform: translate3d(@xvalue, @yvalue, @zvalue);
       -moz-transform: translate3d(@xvalue, @yvalue, @zvalue);
        -ms-transform: translate3d(@xvalue, @yvalue, @zvalue);
         -o-transform: translate3d(@xvalue, @yvalue, @zvalue);
            transform: translate3d(@xvalue, @yvalue, @zvalue);
}

.transition(@prop: all, @sec: 0.6s, @ease: ease-in) {
    -webkit-transition: @prop @sec @ease;
       -moz-transition: @prop @sec @ease;
         -o-transition: @prop @sec @ease;
            transition: @prop @sec @ease;
}

.transform-origin(@xvalue: 0, @yvalue: 0) {
    -webkit-transform-origin: @xvalue @yvalue;
       -moz-transform-origin: @xvalue @yvalue;
        -ms-transform-origin: @xvalue @yvalue;
         -o-transform-origin: @xvalue @yvalue;
            transform-origin: @xvalue @yvalue;
}

.no-transition() {
    -webkit-transition: none;
       -moz-transition: none;
         -o-transition: none;
            transition: none;
}

.reset-list() {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.vertical-align {
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -10px;
    }

    .vertical-align-content {
        display: inline-block;
        vertical-align: middle;
    }
}

.translate(@x; @y) {
    -webkit-transform: translate(@x, @y);
    -moz-transform:    translate(@x, @y);
    -ms-transform: translate(@x, @y); // IE9 only
    -o-transform: translate(@x, @y);
    transform: translate(@x, @y);
}
.translateY(@v) {
    -webkit-transform: translateY(@v);
    -moz-transform: translateY(@v);
    -ms-transform: translateY(@v);
    -o-transform: translateY(@v);
    transform: translateY(@v);
}
.translateX(@v) {
    -webkit-transform: translateX(@v);
    -moz-transform: translateX(@v);
    -ms-transform: translateX(@v);
    -o-transform: translateX(@v);
    transform: translateX(@v);
}

.inline-me(){
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    *display: inline;
}
.img-responsive {
    .inline-me();
}



.row--equal-height{
  display: table;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  [class^="col-"], [class*=" col-"] {
    padding-left: 0;
    padding-right: 0;
    display: table-cell;
    float: none;
  }
}