.article-related{
	margin-bottom: @baseDim;
	padding-left: 25px;
	padding-right: 25px;
	
	.article-preview{
		margin-bottom: @baseDim;
		padding-right: 26%;
		max-height: 119px;
		overflow: hidden;
		.media-body{
			position: relative;
		}
    .media-left > a{
      display: block;
      height: 119px;
      width: 79px;
      overflow: hidden;
      position: relative;
      .media-object{
        height: 119px !important;
        width: auto !important;
        position: absolute;
        top:0;
        left: 50%;
        .translateX(-50%);
      }
    }
		.meta{
			position: absolute;
			font-size: 10px;
			top: 0;
			left: 0;
			color: red;
		}
		.title{
			//.bsk_italic();
			font-size: 16px;
			line-height: 17px;
			margin-top: 16px;
			margin-bottom: 4px;
		}
		.author{
			font-size: 10px;
			line-height: 1;
			&, .label{
				color: #333;
			}
		}

		.brand{
			position: absolute;
			left: 0;
			bottom: 8px;
			//.gtw();
			color: @gray;

			font-size: 10px;

			.label{
				//.bsk_italic();
				text-transform: none;
			}

			@media (max-width: @screen-md) {
				& {
					line-height: 10px;
				}
			}

			@media (max-width: 1110px) {
				& {
					line-height: 10px;
				}
			}
		}

		&.gallery{
			.gallery-counter{
				display: block;
				position: absolute;
				top: 0; bottom: 0; left: 0; right: 0;
				background: fade(@white, 40%);
				text-align: center;

				.text{
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					.translateY(-50%);

					line-height: 24px;
					.counter{
						display: block;
						font-size: 31px;
					}
					.label{
						//.bsk_italic();
						font-size: 16px;
					}
				}
			}
		}

		&.icon-image{
			padding-right: 50px;
			.media-left{
				a{
					background-color: #e4e4e4;
					.media-object{
						top: 24px;
						height: 64px !important;
					}
				}
			}
		}
	}
}