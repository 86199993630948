.leaf {
    .leaf-header {
        border-bottom: 1px solid @gray;
        height: 60px;
        margin-bottom: 10px;
        position: relative;

        @media only screen and (min-width: @screen-sm) {
            border: none;
        }
    }

    #authors {
        left: 5px;
        position: absolute;
        top: 20px;

        @media only screen and (min-width: @screen-sm) {
            left: 30px;
        }
    }

    #people {
        position: absolute;
        right: 5px;
        top: 10px;

        @media only screen and (min-width: @screen-sm) {
            right: 30px;
            top: 20px;
        }
    }

    .leaf-breadcrumb {
        font-family: @font-family-serif;
        padding-top: 20px;
        padding-left: 5px;

        @media only screen and (min-width: @screen-sm) {
            padding-top: 15px;
            padding-left: 0;
            text-align: center;
        }

        .breadcrumb {
            li {
                &:first-of-type {
                    @media only screen and (min-width: @screen-sm) {
                        display: block;
                    }
                }

                &:nth-of-type(2) {
                    &:before {
                        padding: 0 2px;
                        @media only screen and (min-width: @screen-sm) {
                            content: none;
                        }  
                    }
                }
            }
        }
    }

    .leaf-body {
        position: relative;

        @media only screen and (min-width: @screen-lg) {
            padding: 0 60px;
        }

        header {
            margin-bottom: 10px;

            @media only screen and (min-width: @screen-lg) {
                margin-bottom: 20px;
            }
        }

        .title {
            font-weight: @lighter;
            margin: 10px 0;

            @media only screen and (min-width: @screen-sm) {
                margin: 15px 0;
            }
        }

        .subtitle {
            font-weight: @lighter;
            margin-top: 11px;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-h2;
            }
        }

        &.landingpage{
            .title{
                font-family: Oswald, sans-serif;
                margin-top: 35px;
                font-weight: normal;
                font-size: 38px;
                line-height: 2.8rem;
            }
            .subtitle {
                font-size: 30px;
                line-height: 2.2rem;
            }

        }

        .authors {
            display: inline-block;
        }

        .content {
            // we need full window size content
            //overflow: hidden;

            @media only screen and (min-width: @screen-sm) {
                overflow: hidden;
            }

            p {
                color: @black;
                line-height: 1.38rem;

                @media only screen and (min-width: @screen-sm) {
                    line-height: 1.875rem;
                }
            }

            a {
                color: @middle-gray;
                font-family: @font-family-serif;
            }

            blockquote {
                border: none;
                color: @black;
                font-family: @font-family-sans-serif;
                font-weight: @bold;
                padding: 0;
            }

            .alignright,
            .alignleft,
            .aligncenter,
            .alignnone {
                margin-top: 15px;
                margin-bottom: 15px;

                @media only screen and (min-width: @screen-sm) {
                    margin-top: 35px;
                    margin-bottom: 35px;
                }
            }

            .alignright {
                float: right;

            }

            .alignleft {
                float: left;
            }

            .aligncenter,
            .alignnone {
                display: block;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }

            img {
                max-width: 100%;
                height: auto;
            }
            //
            //// Overload
            //// gallery image styles
            //.gallery-item {
            //
            //
            //    .img-wrapper{
            //        display: inline-block;
            //        position:relative
            //    }
            //}

            .wp-caption {
                //padding: 5px;
                width: auto!important;
            }

            .wp-caption-text,
            .wp-caption-text a {
                font-family: @font-family-serif;
                font-size: @font-size-small;
                line-height: 1.3rem;
                margin-top: 10px;
                margin-bottom: 0;
            }

            &.landing{
                color: #9e9e9f;
                font-family: 'Open Sans', Helvetica, sans-serif;

                .registration{
                    border-color: #e56d79;
                    color: #e56d79;
                    margin : 20px auto;
                }

                p{
                    color: #9e9e9f;
                    font-family: 'Open Sans', Helvetica, sans-serif;
                    font-size: 20px !important;
                    line-height: 1.78rem;

                    strong{
                        color: #000;
                    }
                }

                .topics{
                    margin-top: 35px;
                    margin-bottom: 15px;
                }

                .openLoginDialog{
                    text-decoration : underline;
                    font-family: 'Open Sans', Helvetica, sans-serif;
                }
            }
        }

        .topic-middle-container {
            max-width: 925px;
            padding-left: 60px;
            padding-right: 60px;
            margin-left: auto;
            margin-right: auto;
            transition: all 0.6s ease-in 0s;
        }

        .topics {
            position: relative;
            margin-top: 0;
            margin-bottom: 0;

            &.topic-box {
                padding: 15px 10px;
                background-color: @white;
            }

            &.brandmag-box {
                padding: 15px 10px;
                border-bottom: 1px solid @gray;
                clear: both;

                .icon-brandmag {
                    font-size: 26px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &.grey-background {
                background-color: @dirty-white;
                min-height: 84px;
            }

            &.disclaimer {
                margin-bottom: 0 !important;

                .topics {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                span {
                    padding: 25px 10px;
                    display: block;
                    font-size: @font-size-large;
                    color: @light-black;
                    float: left;

                    a {
                        text-decoration: underline;
                    }
                }

                .arrow-box {
                    background: @white;
                    border: none;
                    width: 10px;
                    position: absolute;
                    top: 0;
                    left: 25px;

                    &:after, &:before {
                        top: 100%;
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-top-color: @white;
                        border-width: 5px;
                        margin-left: -5px;
                    }

                    &:before {
                        border-color: rgba(62, 62, 62, 0);
                        border-top-color: @white;
                        border-width: 8px;
                        margin-left: -8px;
                    }
                }

                #follow-form {
                    float: right;
                    padding: 25px 10px;

                    button {
                        background-color: @pink;
                        border-color: @pink;
                    }

                    @media only screen and (max-width: @screen-sm) {
                        float: none;
                    }
                }
            }

            ul {
                display: table-cell;
            }

            h5 {
                float: left;
                margin-top: 4px;
                margin-right: 30px;
                font-size: @font-size-base;
            }

            .icon-nib {
                position: absolute;
                top: -24px;
                left: 58px;
            }

            .topic-item {
                a {
                    font-size: @font-size-base;
                }
            }

            .brandmag-title {
                float: left;
                margin-right: 30px;
                margin-top: 5px;
                font-size: @font-size-base;
                font-weight: @bold;
                text-transform: uppercase;
                vertical-align: top;
                position: relative;
                padding-left: 30px;

                @media only screen and (max-width: @screen-sm) {
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }

        &.p-404 {
            margin-top: 30px;

            img {
                @media only screen and (max-width: @screen-sm) {
                    max-width: 100%;
                    height: auto;
                }
            }

            #title {
                font-weight: @bolder;
                font-size: @font-size-extra-large;
                margin: 10px 0;

                @media only screen and (min-width: @screen-sm) {
                    margin: 15px 0;
                }
            }

            .content {
                overflow: hidden;

                p {
                    color: @middle-gray;
                    font-size: @font-size-medium;
                    font-style: italic;
                    line-height: 1.38rem;

                    @media only screen and (min-width: @screen-sm) {
                        line-height: 1.875rem;
                    }

                    @media only screen and (max-width: @screen-sm) {
                        margin-top: 30px;
                    }
                }

                a {
                    color: @middle-gray;
                    font-family: @font-family-serif;

                    &:hover {
                        text-decoration: underline;
                        color: @black;
                    }
                }
            }
        }
    }

    .leaf-aside {
        position: absolute;
        left: -60px;
        width: 120px;
        text-align: center;
        top: 0;
        
        .sticker {
            margin-top: 10px;

            .icon {
                font-size: 100px;
            }
        }
        
        .sticky-aside {
            display: none;
            position: relative;
            top: 0;
            text-align: center;
            width: 120px;
        } 

        .btn-preferred {
            border-bottom: 1px solid @gray;
            margin-bottom: 15px;
            padding-bottom: 25px;

            .icon-star {
                display: block;
                font-size: @font-size-large;
                margin-bottom: 10px;
            }
        }

        .social-share {
            padding: 0;

            .social-share-item {
                font-size: @font-size-h4;
                margin: 3.5px auto;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .social-share-count {
                color: @black;
                display: block;
                font-size: @font-size-extra-small;
            }

            a {
                color: @gray;
            }
        }
    }

    .leaf-more {
        font-family: @font-family-serif;
        margin: 25px 0;

        @media only screen and (min-width: @screen-sm) {
            margin: 50px 0;
        }

        h4 {
            font-size: @font-size-small;
            line-height: 1.87rem;
            margin: 0;
            
            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-base;
                margin-top: 12px;
                margin-bottom: 12px;
            }
        }

        ul {
            .reset-list;   
        }

        .leaf-more-item {
            &:before {
                content: '-';
            }
        }

        a {
            font-size: @font-size-small;
            font-weight: @lighter;
            line-height: 1.5rem;

            @media only screen and (min-width: @screen-sm) {
                font-size: @font-size-base;
                line-height: 1.875rem;
            }

            &:hover {
                color: @black;
            }
        }
    }
}

.leaf-others {
    margin-top: 50px;
}

.leaf-informations {
    padding-bottom: 0; //15px;

    @media only screen and (min-width: @screen-sm) {
        padding-bottom: 0; //55px;
    }

    .leaf-separator {
        .separator {
            .divider {
                padding: 0;
            }

            h5.divider {
                background-color: @middle-light-gray;
                padding: 20px 0;
            }
        }
    }

    .topics {
        padding: 0 60px;
    }
}

#leaf-loading {
    height: 200px;
    position: relative;
    width: 100%;

    .loading-spinner {
        background-color: @middle-light-gray;
    }
}

#leaf-controls {
    @media only screen and (min-width: @screen-sm) {
        body.menu-open & {
            width: ~"calc(100% - 220px)";
            left: 220px;
        }
    }

    .btn {
        z-index: 5;
    }

    .btn-share {
        float: left;
    }

    #scroll-to {
        color: @black;
        height: 58px;
        line-height: 58px;
        padding: 0 16px;
        width: auto;
    }
}
