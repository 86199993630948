#profile-subscription {
    padding-top: 20px;
    position: relative;

    .magazine-subscription {
        background-color: transparent;
        border: none;
        bottom: auto;
        position: relative;
        text-align: left;
        top: 20px;

        @media only screen and (min-width: @screen-sm) {
            text-align: center;
        }

        .big-cover {
            left: 0;
        }

        .small-cover {
            right: 20px;
        }

        .magazine-bill {
            background-color: @pink;
            border-radius: 60px;
            color: @white;
            display: block;
            height: 60px;
            left: 90px;
            padding: 15px 0 0;
            text-align: center;
            top: -20px;
            z-index: -1;
            width: 60px;

            @media only screen and (min-width: @screen-sm) {
                left: 100px;
            }

            .text {
                display: block;
                font-size: @font-size-extra-small;
                line-height: 0.75rem;
            }
        }
    }

    #magazine-subscription-form {
        background-color: @pink;
        padding: 20px;

        @media only screen and (min-width: @screen-sm) {
            height: 45px;
            padding: 5px;
        }

        .information {
            height: 100%;
            position: relative;
            width: 100%;
            line-height: 34px;
            font-size: 20px;
            .vertical-align;
        }

        .form-inline {
            @media only screen and (min-width: @screen-sm) {
                padding-left: 115px;
            }
        }

        .form-group {
            position: relative;
        }

        .form-control {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid @white;
            border-radius: 0;
            box-shadow: none;
            color: rgba(255, 255, 255, 0.8);
            height: 35px;
            .placeholder-color(rgba(255, 255, 255, 0.8));
            .appearence(none);

            @media only screen and (min-width: @screen-sm) {
                background-color: @white;
                border: none;
                color: @black;
                margin: 0 40px 0 0;
                width: 220px;
                .placeholder-color(@black);
            }

            @media only screen and (min-width: @screen-md) {
                margin: 0 80px 0 0;
            }
        }

        label {
            color: @white;
            font-weight: lighter;
            text-transform: uppercase;
        }

        select {
            background-image: url('../img/icons/arrow-down.svg');
            background-position: 95% center;
            background-repeat: no-repeat;
            background-size: 12px;
        }

        .popover {
            left: 50%;
            margin-left: -150px;
            text-align: center;
            top: 35px;
            width: 220px;
        }

        #submit {
            display: block;
            margin: 35px auto 0 auto;
            width: 220px;

            @media only screen and (min-width: @screen-sm) {
                display: inline-block;
                margin: 0 auto;
                width: auto;
            }
        }
    }

    > .row {
        @media only screen and (min-width: @screen-sm) {
            margin-top: 30px;
        }
    }

    article {
        margin: 20px 0;

        @media only screen and (min-width: @screen-sm) {
            margin: 0;
            padding: 80px 130px 0 0;
        }

        p {
            font-family: @font-family-sans-serif;
            font-size: @font-size-base;
        }

        small {
            font-size: @font-size-extra-small;
        }
    }

    #lead {
        margin-top: 30px;

        p {
            font-family: @font-family-sans-serif;
            font-size: @font-size-base;
            margin-bottom: 5px;
        }

        ul {
            .reset-list;

            li {
                font-weight: bold;
                text-transform: uppercase;

                .check-mark {
                    background-color: @black;
                    border-radius: 2px;
                    color: @white;
                    display: inline-block;
                    font-size: 22px;
                    height: 16px;
                    line-height: 16px;
                    position: relative;
                    top: 7px;
                    width: 16px;
                }

                .icon-check-mark {
                    bottom: 3px;
                    font-size: 22px;
                    position: relative;
                    right: 3px;
                }
            }
        }

        .btn-bordered {
            display: block;
            margin-top: 35px;
            padding: 13px 0;
        }
    }
}