#beauty-break {
    background-color: @black;
    border-bottom: 1px solid @bb-gray;
    height: 70px;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: @screen-sm) {
        height: 195px;
    }

    #clock {
        float: left;
    }

    #beauty-break-header {
        h4 {
            color: @pink;
            font-family: @font-family-cursive;
            margin: 10px 0 5px;
        }

        h5 {
            color: @white;
            margin: 0 0 5px;
        }
    }

    #beauty-break-wrapper,#beauty-break-wrapper2{
        height: 70px;
        float: left;
        width: 65%;

        .goRight,
        .goLeft{
            background-color: @black;
            border:0;
            border-left: 1px solid @bb-gray;
            border-bottom: 1px solid @bb-gray;
            color: @dark-gray;

            width: 80px;
            height: 65px;
            padding-top: 26px;
            position: absolute;
            right: 240px;
            text-align: center;
            top: 0;
            z-index: 3;
            display:none;

            &:hover{
                color: @white;
            }

            @media only screen and (min-width: 768px) {
                display:block
            }

        }

        .goLeft{
            top: 65px;
        }
    
        @media only screen and (min-width: @screen-sm) {
            height: 195px;
            left: 240px;
            overflow-x: hidden;
            overflow-y: hidden;
            position: absolute;
            right: 125px;
            top: 0;
            white-space: nowrap;
            width: 100%;
        }

        .scroll-wrap {
            width: auto;
            height: 100%;
        }

        .iScrollHorizontalScrollbar {
            bottom: 10px;
            display: none;
            height: 7px;
            left: 2px;
            overflow: hidden;
            position: absolute;
            right: 2px;
            z-index: 8;

            @media only screen and (min-width: @screen-sm) {
                display: block;
            }

            .iScrollIndicator {
                background: @bb-gray ;
                border-radius: 3px;
                box-sizing: border-box;
                display: block;
                height: 100%;
                position: absolute;
            }
        }
    }

    .beauty-break-item {
        color: @white;
        display: none;
        height: 70px;
        position: relative;
        width: 100%;
    
        @media only screen and (min-width: @screen-sm) {
            border-left: 1px solid @bb-gray;
            display: inline-block;
            height: 195px;
            margin-right: -4px;
            width: 360px;
        }

        &:first-of-type {
            display: inline-block;

            @media only screen and (max-width: 320px) {
                margin-left:-5px;
                margin-top: 5px;
            }

        }

        &:after {
            content: ' ';
            display: block;
            height: 100%;
            left: 0;
            opacity: 1;
            position: absolute;
            top: 0;
            width: 100%;
            .gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
            .transition(all, 0.3s);
        }

        .background-cover {
            display: none;

            @media only screen and (min-width: @screen-sm) {
                .transition(opacity, 0.3s);
                background-position: top;
                background-size: cover;
                display: block;
                height: 100%;
                left: 0;
                opacity: 0.8;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .day-label {
            background-color: @black;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0; 
            width: 20px;
            z-index: 1;

            h4 {
                .transform-origin(0, 0);  
                border-bottom: 1px solid @bb-gray;
                bottom: -20px;
                color: @bb-gray;
                font-size: @font-size-extra-small;
                height: 21px;
                left: 0;
                line-height: 1.25rem;
                margin: 0;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                width: 195px;
                .rotate(-90deg);
           }
        }

        header {
            .transition(all, 0.3s);
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            width: 100%;
            z-index: 2;

            @media only screen and (min-width: @screen-sm) {
                bottom: 30px;
                height: auto;
                padding: 35px 5px 0 35px;
                white-space: normal;
            }
        }

        .time {
            font-size: @font-size-small;
            font-weight: @bold;
            left: 10px;
            position: relative;
            top: 1px;

            @media only screen and (min-width: @screen-sm) {
                display: block;
                font-size: @font-size-base;
                margin-bottom: 5px;
                position: static;
                vertical-align: top;
            }

            .text {
                @media only screen and (min-width: @screen-sm) {
                    vertical-align: top;
                }
            }
        }

        .icon-time {
            display: block;
            font-size: 1.69rem;
            left: 5px;
            position: absolute;
            top: 20px;

            @media only screen and (min-width: @screen-sm) {
                display: inline-block;
                float: none;
                position: static;
            }
        }

        .time + .icon-time {
            margin: 0 2px 0 0;
        }

        h5 {
            color: @light-gray;
            font-size: @font-size-extra-small;
            font-weight: @light;
            left: 55px;
            line-height: 0.875rem;
            margin: 0;
            position: absolute;
            top: 6px;

            @media only screen and (min-width: @screen-sm) {
                font-size: 0.94rem;
                line-height: 1.19rem;
                position: static;
            }
        }

        a {
            color: @light-gray;

            &:hover {
                text-decoration: none;
            }
        }

        .btn-preferred {
            color: @gray;
            font-size: @font-size-small;
            opacity: 0;
            padding-left: 0;

            &:hover {
                color: @white;
                text-decoration: none;
            }
        }

        &:hover {
            cursor: pointer;

            &:after {
                opacity: .7; 
            }

            @media only screen and (min-width: @screen-sm) {
                header {
                    //bottom: 20px
                }
            }

            .background-cover {
                opacity: 1;
            }

            .btn-preferred {
                opacity: 1;
            }
        }
    }

    #beauty-break-more {
        background-color: @black;
        border-left: 1px solid @bb-gray;
        color: @dark-gray;
        display: block;
        width: 80px;
        height: 66px;
        padding-top: 16px;
        position: absolute;
        right: 0;
        text-align: center;
        bottom: 0;
        z-index: 2;

        &:hover {
            color: @white;
            text-decoration: none;
        }

        .text,
        .icon {
            color: @white;
            display: block;
            margin-bottom: 5px;
        }

        .text {
            font-size: @font-size-extra-small;
            font-weight: @bold;
        }

        .icon {
            font-size: @font-size-large;
            margin-top: 80px;
        }
    }
}
