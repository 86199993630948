.video-thumbnail {
    font-size: @font-size-small;
    line-height: 1.12rem;
    margin: 0;
    max-width: 480px;
    position: relative;
    text-align: center;

    @media only screen and (min-width: @screen-sm) {
        width: 230px;
    }

    &.big {
        font-size: @font-size-text;
        line-height: 1.5rem;
        width: 320px;

        @media only screen and (min-width: @screen-md) {
            width: 410px;
        }

        footer {
            height: 120px;
            overflow: hidden;
        }

        h3 {
            font-size: @font-size-medium;
            line-height: 1.5rem;
        }

        p {
            padding: 10px;
        }

        .thumbnail-cover {
            height: 230px;
            width: 100%;
        }
    }

    &.small {
        .thumbnail-cover {
            @media only screen and (min-width: @screen-sm) {
                max-height: 140px;
                height: 140px;
            }
        }
    }

    .thumbnail-cover {
        background-position: center;
        background-size: cover;
        color: @white;
        cursor: pointer;
        height: 230px;
        overflow: hidden;
        position: relative;
        z-index: 1;

//        .thumbnail-image {
//            background-size: cover;
//            display: block;
//            height: auto;
//            position: relative;
//            width: 100%;
//        }

        .play-overlay {
            .vertical-align;
            background-color: rgba(0, 0, 0, 0);
            bottom: 0;
            display: block;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            text-transform: uppercase;
            top: 0;
            z-index: 1;

            span.text {
                .transition(all, 0.25s);
                border-bottom: 1px solid #fff;
                border-top: 1px solid #fff;
                font-size: @font-size-h6;
                font-weight: @bolder;
                padding: 10px 0px;
                position: relative;
                top: -10px;
            }

            .transition(all, 0.25s);
        }

        .informations {
            .transition(all, 0.25s);
            bottom: 0;
            font-size: 0.7rem;
            position: absolute;
            right: 0;

            .time {
                background-color: rgba(0, 0, 0, 1);
                display: block;
                float: left;
                height: 20px;
                line-height: 0.7rem;
                margin: 0 2px;
                padding: 4px 8px;
                position: relative;
            }

            .icon {
                background-color: rgba(0, 0, 0, 1);
                display: block;
                float: right;
                height: 20px;
                line-height: 1.25rem;
                opacity: 1;
                position: relative;
                width: 20px;
            }
        }
    }

    footer {
        overflow: hidden;
    }

    h3 {
        color: @black;
        font-size: @font-size-h6;
        font-weight: @bolder;
        line-height: 1.19rem;
        margin: 0;
        padding: 12px 15px 0;
        position: relative;

        @media only screen and (min-width: @screen-sm) {
            font-size: @font-size-small;
        }
    }

    p {
        color: @black;
        cursor: default;
        margin: 0;
        padding: 10px 0;
    }

    .controls {
        position: static;

        .btn {
            background-color: transparent;
            border: none;
            box-shadow: none;
            font-size: @font-size-small;
        }

        .share-mobile-wrapper {
            display: inline-block;
        }

        .social-share {
            background-color: @white;
            margin-left: -120px;
            position: absolute;
            top: -65px;
            z-index: 2;
        }
    }


    @media only screen and (min-width: @screen-sm) {
        &:hover {
            .play-overlay {
                background-color: rgba(0, 0, 0, .6); 
                opacity: 1; 
                vertical-align: middle;

                span.text { 
                    padding: 10px 20px;
                    top:0;
                } 
            }

            .informations { 
                bottom: -20px; 
            }
        }
    }
}
