.sticker {
    color: @black;
    text-align: center;
    
    @media only screen and (max-width: @screen-sm) {
        &.col-sm-4:nth-child(n+3) {
            margin-top: 50px;
        }
    }
    
    @media only screen and (min-width: @screen-sm) {
        &.col-sm-4:nth-child(n+4) {
            margin-top: 80px;
        }
    }

    a {
        color: @black;
        text-decoration: none;
    }
    
    p.desc {
        font-family: @font-family-sans-serif;
        font-weight: @bold;
        margin-bottom: 20px;
    }

    .animated-sticker {
        display: block;
        margin: 0 auto;
    }

    &-xs {
        .icon {
            font-size: 37.5px;
        }
    }

    // One time
    // and half 37.5px;
    &-sm {
        .icon {
            font-size: 56.2px;
        }

        figcaption {
            margin: 3px 0 0;
        }
    }

    // Two times 37.5px;
    &-md {
        .icon {
            font-size: 75px;
        }

        figcaption {
            margin: 7px 0 0;
        }
    }

    // Three times 37.5px;
    &-lg {
        .icon {
            font-size: 112.5px;
        }

        figcaption {
            margin: 10px 0 0;
        }
    }

    figcaption {
        font-family: @font-family-cursive;
        font-size: @font-size-h6;
        text-transform: uppercase;
    }
}
